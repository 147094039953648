import React from 'react';
import { Typography } from 'tfc-components';

import Icon from 'components/atoms/Icon';
import CustomModal from 'components/organisms/Modal';

interface ModalSystemErrorProps {
  isOpen: boolean;
  handleClose?: () => void;
}

const ModalSystemError: React.FC<ModalSystemErrorProps> = ({ isOpen, handleClose }) => (
  <CustomModal isOpen={isOpen} handleClose={handleClose}>
    <div className="t-modalSystemError">
      <div className="t-modalSystemError_icon">
        <Icon iconName="warning" size="60" />
      </div>
      <Typography.Text
        textStyle="center"
        extendClasses="color-white fs-16x20 t-modalSystemError_title"
        fontweight="900"
      >
        ĐƯỜNG TRUYỀN CỦA BẠN
        <br />
        KHÔNG ỔN ĐỊNH.
        <br />
        VUI LÒNG THỬ LẠI NHÉ
      </Typography.Text>
      {/* <Typography.Text
        textStyle="center"
        extendClasses="color-white fs-16x20 t-modalSystemError_message"
      >
        Đội kỹ thuật đang cố gắng
        <br />
        mang đường truyền quay lại,
        <br />
        Fan đích thực chờ chút nhé!
      </Typography.Text> */}
    </div>
  </CustomModal>
);

export default ModalSystemError;
