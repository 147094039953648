import { GiftDataType, GiftDataItemType } from './types';

import axiosInstance from 'services/common/instance';

export const getGiftsService = async (): Promise<GiftDataType[]> => {
  const res = await axiosInstance.get('gifts');
  return res.data.data;
};

export const getGiftByCodeService = async (code: string): Promise<GiftDataItemType> => {
  const res = await axiosInstance.get(`gifts/${code}`);
  return res.data.data;
};
