/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  Fragment,
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import {
  Controller, FormProvider, useForm,
  useWatch
} from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select/dist/declarations/src/Select';
import { Image, Link, Typography } from 'tfc-components';

import appstore from 'assets/images/appstore.png';
import googleplay from 'assets/images/googleplay.png';
import introSt2 from 'assets/images/intro-step2.png';
import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import Pulldown from 'components/molecules/Pulldown';
import Header from 'components/organisms/Header';
import CustomModal from 'components/organisms/Modal';
import ModalNotify from 'components/templates/ModalNotify';
import ModalSystemError from 'components/templates/ModalSystemError';
import useAddressOptions from 'hooks/useAddressOptions';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { UserInfoFormTypes } from 'pages/UserInfoUpdate';
import HelmetComponent from 'pages/helmet';
import { requestRedeemService, updateProfileService } from 'services/auth';
import returnValidateErrorMessages from 'services/common/errors';
import { getDetailRewardService } from 'services/rewards';
import { getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ROUTE_PATH } from 'utils/constants';
import mapModifiers, { formatDateDDMMYYYY2 } from 'utils/functions';
import useSchemas from 'utils/schemas';
import SEO_DATA from 'utils/seoData';
import {
  trackingButtonNextScanQRCodeEvent,
  trackingEvent,
  trackingLinkToHomePageEvent, trackingLinkToMyGiftEvent,
  trackingNewQrGiftEvent,
  trackingRegistGiftEvent,
  trackingSubmitRegistGiftEvent
} from 'utils/trackingGA';

type GotGiftFormData = {
  otherPhone: string;
};
const fieldToText = (field: string) => {
  switch (field) {
    case 'dob':
      return 'Ngày sinh';
    case 'name':
      return 'Họ và tên';
    case 'idNumber':
      return 'CMND/CCCD';
    case 'provinceCode':
      return 'Tỉnh/Thành phố';
    case 'districtCode':
      return 'Quận/Huyện';
    case 'address':
      return 'Địa chỉ';
    default:
      return '';
  }
};
const GotGift: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { redeemPhone, userInfo } = useSchemas();
  const { id } = useParams();
  const { height } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const open = searchParams.get('open');
  const { data: reward, isLoading } = useQuery('rewardDetail', () => (id && id !== 'none' ? getDetailRewardService(id) : undefined));
  const profile = useAppSelector((state) => state.auth.profile);
  const systems = useAppSelector((state) => state.systems);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalGrab, setIsOpenModalGrab] = useState(false);
  const [isOpenModalIntro, setIsOpenModalIntro] = useState(false);
  const [isOpenModalIntroGrab, setIsOpenModalIntroGrab] = useState(false);
  const [isOpenModalForgot, setIsOpenModalForgot] = useState(false);
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false);
  const [invalid, setInvalid] = React.useState<'noVoucher' | 'other' | ''>('');
  const [copied, setCopied] = React.useState(false);
  const [errSystem, setErrSystem] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [countdown, setCountdown] = React.useState(20);
  const [hasAction, setHasAction] = React.useState(false);

  /* Refs */
  const districtRef = useRef<Select>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const method = useForm<GotGiftFormData>({
    mode: 'onChange',
    resolver: yupResolver(redeemPhone),
    defaultValues: {
      otherPhone: ''
    },
  });
  const updateMethod = useForm<UserInfoFormTypes>({
    mode: 'onChange',
    resolver: yupResolver(userInfo),
    defaultValues: {
      name: '',
      idNumber: undefined,
      address: '',
    },
  });
  const provinceWatch = useWatch({ name: 'provinceCode', control: updateMethod.control });
  const districtWatch = useWatch({ name: 'districtCode', control: updateMethod.control });
  const {
    provinces, districts, isLoadingProvinces, isLoadingDistricts
  } = useAddressOptions(provinceWatch?.value);
  const { mutate: requestRedeemMutate, isLoading: redeemLoading } = useMutation(
    'requestRedeemService',
    requestRedeemService,
    {
      onSuccess: () => {
        setIsOpenModal(false);
        setIsOpenModalSuccess(true);
      },
      onError: (error: any) => {
        setIsOpenModal(false);
        if (Array.isArray(error) && error[0]?.code === 'noVoucher') {
          setInvalid('noVoucher');
        } else {
          setInvalid('other');
        }
      }
    }
  );

  const { mutate: updateInfo, isLoading: updateLoading } = useMutation('updateInfo', updateProfileService, {
    onSuccess: () => {
      navigate(ROUTE_PATH.MY_GIFT);
      dispatch(getProfileAction());
    },
    onError: (error: any) => {
      if (error.length > 0) {
        error.forEach((err: ValidateError) => {
          updateMethod.setError(
            err.field,
            { message: returnValidateErrorMessages(err, fieldToText(err.field)) }
          );
        });
      } else {
        setErrSystem(true);
      }
      setIsOpenModal(false);
    }
  });

  const showFormAction = () => {
    trackingRegistGiftEvent(reward?.giftData.trackingEventType);
    setShowForm(true);
    setTimeout(() => {
      if (bodyRef.current) {
        bodyRef.current.scroll({
          top: bodyRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }
    }, 150);
  };

  const gotoMyGift = useCallback(() => {
    if (reward?.rewardData.giftType === 'luckyDraw') {
      navigate(`${ROUTE_PATH.MY_GIFT}?luckyDraw=true`);
    } else {
      navigate(ROUTE_PATH.MY_GIFT);
    }
  }, [navigate, reward]);

  // const onHandleGiveGift = () => {
  //   trackingButtonReceiveEvent(reward?.giftData.trackingEventType);
  //   if (!open && reward?.rewardData.giftType === 'soccer') {
  //     navigate(`${ROUTE_PATH.UPDATE_USER_INFO}?fromGame=true`);
  //   } else {
  //     gotoMyGift();
  //   }
  // };

  const onSubmitUpdate = async (data: UserInfoFormTypes, isUpdate?: boolean) => {
    if (!isUpdate) {
      navigate(ROUTE_PATH.MY_GIFT);
    } else {
      const valid = await updateMethod.trigger();
      if (valid) {
        const {
          provinceCode, districtCode, ...rest
        } = data;

        updateInfo({
          ...rest,
          provinceCode: provinceCode?.value,
          districtCode: districtCode?.value,
          idNumber: data.idNumber?.toString()
        });
      }
    }
  };

  const missingInfo = useMemo(() => !profile?.profile?.idNumber
    || !profile.profile.province || !profile.profile.district
    || !profile.profile.address, [profile]);
  useEffect(() => {
    if (profile && profile?.profile) {
      updateMethod.reset({
        name: profile?.profile?.name || '',
        idNumber: String(profile?.profile?.idNumber || ''),
        address: profile?.profile?.address || '',
        provinceCode: profile?.profile?.province ? {
          value: profile?.profile?.province?.code,
          label: profile?.profile?.province?.name
        } : undefined,
        districtCode: profile?.profile?.district ? {
          value: profile?.profile?.district.code,
          label: profile?.profile?.district.name
        } : undefined,
      });
    }
  }, [updateMethod, profile]);

  const onSubmit = (data: GotGiftFormData) => {
    if (id) {
      requestRedeemMutate({
        phone: data.otherPhone,
        rewardId: Number(id)
      });
    }
  };

  useEffect(() => {
    if (reward?.rewardData.giftVendor === 'voucherFptPlay' && reward.rewardData.redeemStatus === 1) {
      let count = countdown;
      const timer = setInterval(() => {
        count -= 1;
        setCountdown(count);
      }, 1000);
      if (hasAction) {
        clearInterval(timer);
      }
      if (countdown === 0) {
        if (profile?.account.phone && id) {
          requestRedeemMutate({
            phone: profile?.account.phone,
            rewardId: Number(id)
          });
        }
        clearInterval(timer);
      }
      return () => {
        clearInterval(timer);
      };
    }

    return () => { };
  }, [countdown, hasAction, id, profile, requestRedeemMutate, reward]);

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.GOT_GIFT} />
      {isLoading && <LoadingFullScreen />}
      <div className="p-gotGift">
        <div className="p-gotGift_content">
          <Header isBack={!!open} isProfile />
          <div className="p-myGift_giftList p-gotGift_data" style={{ height: `${height - 126}px` }} ref={bodyRef}>
            {id !== 'none' && (
              <>
                <Typography.Heading
                  textStyle="center"
                  extendClasses="color-white fs-22x32 p-gotGift_heading"
                  fontweight="900"
                >
                  Chúc mừng
                  <br />
                  bạn đã trúng
                </Typography.Heading>
                <div className="p-gotGift_giftImg highlight-layer-gift">
                  <div className="p-gotGift_animation">
                    <div className="star one" />
                    <div className="star two" />
                    <div className="star three" />
                  </div>
                  <Image
                    imgSrc={reward?.giftData.image || ''}
                    ratio={328 / 284}
                    alt="gift"
                  />
                </div>
                <div className="p-gotGift_giftName fs-14x21 color-white" dangerouslySetInnerHTML={{ __html: reward?.giftData.name || '' }} />
                {reward?.rewardData.giftVendor === 'voucherFptPlay' && reward.rewardData.redeemStatus === 1 && (
                  <div className="p-gotGift_codeWrap">
                    <div className="p-gotGift_code">
                      <span>{reward?.rewardData.fptPlayCode}</span>
                      <div
                        className="p-gotGift_code_copy"
                        onClick={() => {
                          if (reward?.rewardData.fptPlayCode) {
                            trackingEvent({
                              label: 'copy-code-fpt',
                              action: 'click',
                              category: 'game'
                            });
                            setCopied(true);
                            navigator.clipboard.writeText(reward?.rewardData.fptPlayCode);
                          }
                        }}
                      >
                        Copy
                      </div>
                    </div>
                  </div>
                )}
                {reward?.rewardData.giftVendor === 'voucherGrab' && reward.rewardData.redeemStatus === 1 && (
                  <div className="p-gotGift_codeWrap">
                    <div className="p-gotGift_code">
                      <span>{reward?.rewardData.grabCode}</span>
                      <div
                        className="p-gotGift_code_copy"
                        onClick={() => {
                          if (reward?.rewardData.grabCode) {
                            setCopied(true);
                            navigator.clipboard.writeText(reward?.rewardData.grabCode);
                          }
                        }}
                      >
                        Copy
                      </div>
                    </div>
                  </div>
                )}
                {reward?.rewardData.giftVendor !== 'voucherFptPlay' && (
                  <div className="p-gotGift_updateGift">
                    <Typography.Text
                      type="span"
                      extendClasses="color-white fs-16x20 p-gotGift_updateGift_descript"
                    >
                      Quà của bạn đã được cập nhật trong
                      <br />
                      thư mục
                    </Typography.Text>
                    <Link
                      href={reward?.rewardData.giftType === 'luckyDraw' ? `${ROUTE_PATH.MY_GIFT}?luckyDraw=true` : ROUTE_PATH.MY_GIFT}
                      onClick={() => {
                        trackingLinkToMyGiftEvent(reward?.giftData.trackingEventType, reward?.rewardData.giftType === 'luckyDraw');
                      }}
                    >
                      <Typography.Text
                        type="span"
                        fontweight="600"
                        extendClasses="color-white fs-16x20 p-gotGift_updateGift_myGift"
                      >
                        Quà của tôi
                      </Typography.Text>
                    </Link>
                  </div>
                )}
                {reward?.rewardData.giftVendor === 'physical' && (
                  <div className="p-gotGift_expireText">
                    <Typography.Text
                      textStyle="center"
                      extendClasses="color-white fs-16x20 p-gotGift_updateGift_descript"
                    >
                      <Icon iconName="blueWarning" size="12" />
                      Quà sẽ chỉ được gửi đi khi Heineken có đủ
                      <br />
                      thông tin của bạn
                      {' '}
                      <span
                        style={{ color: '#33ff6c', fontWeight: '500' }}
                      >
                        trước
                        {' '}
                        {reward?.rewardData.giftType === 'luckyDraw'
                          ? formatDateDDMMYYYY2(systems.system?.luckyDrawConfig.physicalCompletedAt)
                          : formatDateDDMMYYYY2(systems.system?.soccerConfig.physicalCompletedAt)}
                      </span>
                      ,
                      <br />
                      cập nhật ngay để nhận quà nhé!
                    </Typography.Text>
                  </div>
                )}
              </>
            )}
            {reward?.rewardData.giftVendor === 'physical' ? (
              <div className="p-login_info_form p-gotGift_form">
                <div className="p-gotGift_fillInfo">
                  <div className="p-gotGift_fillInfo_left" />
                  <div className="p-gotGift_fillInfo_button">
                    <ButtonCustom onClick={showFormAction}>
                      Điền thông tin & Nhận quà ngay
                    </ButtonCustom>
                  </div>
                  <div className="p-gotGift_fillInfo_right" />
                </div>
                {showForm && (
                  <>
                    {!missingInfo ? (
                      <div className="p-userUpdate_infoTable">
                        <div className="p-userUpdate_infoTable_item">
                          <Typography.Text
                            fontweight="700"
                            extendClasses="p-userUpdate_infoTable_info"
                          >
                            CMND/CCCD:
                            {' '}
                          </Typography.Text>
                          <Typography.Text
                            fontweight="900"
                            extendClasses="color-white"
                          >
                            {profile?.profile?.idNumber || ''}
                          </Typography.Text>
                        </div>
                        <div className="p-userUpdate_infoTable_item">
                          <Typography.Text
                            fontweight="700"
                            extendClasses="p-userUpdate_infoTable_info"
                          >
                            Tỉnh/ Thành phố:
                            {' '}
                          </Typography.Text>
                          <Typography.Text
                            fontweight="900"
                            extendClasses="color-white"
                          >
                            {profile?.profile?.province?.name || ''}
                          </Typography.Text>
                        </div>
                        <div className="p-userUpdate_infoTable_item">
                          <Typography.Text
                            fontweight="700"
                            extendClasses="p-userUpdate_infoTable_info"
                          >
                            Quận/ Huyện:
                            {' '}
                          </Typography.Text>
                          <Typography.Text
                            fontweight="900"
                            extendClasses="color-white"
                          >
                            {profile?.profile?.district?.name || ''}
                          </Typography.Text>
                        </div>
                        <div className="p-userUpdate_infoTable_item">
                          <Typography.Text
                            fontweight="700"
                            extendClasses="p-userUpdate_infoTable_info"
                          >
                            Số nhà, đường:
                            {' '}
                          </Typography.Text>
                          <Typography.Text
                            fontweight="900"
                            extendClasses="color-white"
                          >
                            {profile?.profile?.address || ''}
                          </Typography.Text>
                        </div>
                      </div>
                    ) : (
                      <FormProvider {...updateMethod}>
                        <Controller
                          name="idNumber"
                          control={updateMethod.control}
                          render={({ field, fieldState: { error } }) => (
                            <div className="p-login_info_field" style={{ zIndex: 4 }}>
                              <Input
                                {...field}
                                required
                                placeholder="CMND/CCCD"
                                maxLength={12}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  if (value.length <= 12) {
                                    field.onChange(value);
                                  }
                                }}
                                onClick={() => {
                                  trackingEvent({
                                    action: 'click',
                                    category: 'game',
                                    label: 'delivery-ID'
                                  });
                                }}
                              />
                              {
                                error ? (
                                  <div className="o-otp_error">
                                    <Icon iconName="warning" size="12" />
                                    <Typography.Text
                                      extendClasses="fs-16x20 o-otp_error-text"
                                      textStyle="center"
                                      fontweight="600"
                                    >
                                      {error.message}
                                    </Typography.Text>
                                  </div>
                                ) : null
                              }
                            </div>
                          )}
                        />
                        <Controller
                          name="provinceCode"
                          control={updateMethod.control}
                          render={({ field, fieldState: { error } }) => (
                            <div className="p-login_info_field" style={{ zIndex: 3 }}>
                              <Pulldown
                                {...field}
                                required
                                placeholder="Tỉnh/Thành phố"
                                name="province"
                                loading={isLoadingProvinces}
                                options={provinces}
                                handleChange={(option) => {
                                  if (districtWatch) {
                                    districtRef.current?.clearValue();
                                  }
                                  field.onChange(option);
                                }}
                                onMenuOpen={() => {
                                  trackingEvent({
                                    action: 'click',
                                    category: 'game',
                                    label: 'delivery-city'
                                  });
                                }}
                              />
                              {
                                error ? (
                                  <div className="o-otp_error">
                                    <Icon iconName="warning" size="12" />
                                    <Typography.Text
                                      extendClasses="fs-16x20 o-otp_error-text"
                                      textStyle="center"
                                      fontweight="600"
                                    >
                                      {error.message}
                                    </Typography.Text>
                                  </div>
                                ) : null
                              }
                            </div>
                          )}
                        />
                        <Controller
                          name="districtCode"
                          control={updateMethod.control}
                          render={({ field, fieldState: { error } }) => (
                            <div className="p-login_info_field" style={{ zIndex: 2 }}>
                              <Pulldown
                                {...field}
                                required
                                ref={districtRef}
                                placeholder="Quận/Huyện"
                                name="district"
                                loading={isLoadingDistricts}
                                options={districts}
                                handleChange={field.onChange}
                                onMenuOpen={() => {
                                  trackingEvent({
                                    action: 'click',
                                    category: 'game',
                                    label: 'delivery-province'
                                  });
                                }}
                              />
                              {
                                error ? (
                                  <div className="o-otp_error">
                                    <Icon iconName="warning" size="12" />
                                    <Typography.Text
                                      extendClasses="fs-16x20 o-otp_error-text"
                                      textStyle="center"
                                      fontweight="600"
                                    >
                                      {error.message}
                                    </Typography.Text>
                                  </div>
                                ) : null
                              }
                            </div>
                          )}
                        />
                        <Controller
                          name="address"
                          control={updateMethod.control}
                          render={({ field, fieldState: { error } }) => (
                            <div className="p-login_info_field" style={{ zIndex: 1 }}>
                              <Input
                                {...field}
                                required
                                placeholder="Số nhà, đường"
                                onClick={() => {
                                  trackingEvent({
                                    action: 'click',
                                    category: 'game',
                                    label: 'delivery-address'
                                  });
                                }}
                              />
                              {
                                error ? (
                                  <div className="o-otp_error">
                                    <Icon iconName="warning" size="12" />
                                    <Typography.Text
                                      extendClasses="fs-16x20 o-otp_error-text"
                                      textStyle="center"
                                      fontweight="600"
                                    >
                                      {error.message}
                                    </Typography.Text>
                                  </div>
                                ) : null
                              }
                            </div>
                          )}
                        />
                      </FormProvider>
                    )}
                    <div className="p-gotGift_updateBtn">
                      <ButtonCustom
                        onClick={() => {
                          trackingSubmitRegistGiftEvent(reward?.giftData.trackingEventType);
                          onSubmitUpdate(updateMethod.getValues(), missingInfo);
                        }}
                        loading={updateLoading}
                      >
                        Cập nhật ngay
                      </ButtonCustom>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <>
                <div className="p-gotGift_btnGroup">
                  {id !== 'none' && reward?.rewardData.giftVendor === 'voucherGrab'
                    && (
                      <>
                        <div style={{ width: 160, marginLeft: 'auto', marginRight: 'auto' }}>
                          <ButtonCustom
                            onClick={() => setIsOpenModalGrab(true)}
                          >
                            Nhận quà ngay
                          </ButtonCustom>
                        </div>
                        <div className="p-gotGift_backHome u-mt-4">
                          <Link
                            href={ROUTE_PATH.HOME}
                            onClick={() => {
                              if (id === 'none') {
                                trackingEvent({
                                  label: 'luck-nxt-time-homepage',
                                  action: 'click',
                                  category: 'game'
                                });
                              } else {
                                trackingLinkToHomePageEvent(reward?.giftData.trackingEventType, reward?.rewardData.giftType === 'luckyDraw');
                              }
                            }}
                          >
                            <Typography.Text
                              type="span"
                              fontweight="600"
                              extendClasses="color-white fs-16x20 p-gotGift_backHome_text"
                            >
                              Về lại UCL trang chủ
                            </Typography.Text>
                          </Link>
                        </div>
                      </>
                    )}
                  {id !== 'none' && reward?.rewardData.giftVendor === 'voucherFptPlay' && (
                    <>
                      {reward.rewardData.redeemStatus === 1 && (
                        <>
                          <ButtonCustom
                            loading={redeemLoading}
                            onClick={() => {
                              if (profile?.account.phone && id) {
                                trackingEvent({
                                  label: 'use-fpt-to-my-phone',
                                  action: 'click',
                                  category: 'game'
                                });
                                requestRedeemMutate({
                                  phone: profile?.account.phone,
                                  rewardId: Number(id)
                                });
                                setHasAction(true);
                              }
                            }}
                          >
                            TÍCH HỢP VÀO SỐ ĐIỆN THOẠI BẠN
                          </ButtonCustom>
                          <ButtonCustom
                            onClick={() => {
                              trackingEvent({
                                label: 'send-fpt-to-others',
                                action: 'click',
                                category: 'game'
                              });
                              setIsOpenModal(true);
                              setHasAction(true);
                            }}
                          >
                            Tặng mã cho người khác
                          </ButtonCustom>
                          {countdown > 0 && !hasAction && (
                            <div className="p-gotGift_countdown">
                              <div className="p-gotGift_countdown_text">
                                <Icon iconName="blueWarning" size="12" />
                                <Typography.Text textStyle="center" extendClasses="color-white fs-16x20 3p-gotGift_updateGift_descript">
                                  Tự động kích hoạt nếu bạn không nhập SĐT trong 20s
                                </Typography.Text>
                              </div>
                              <Typography.Text extendClasses="p-gotGift_countdown_value">
                                00:
                                {countdown < 10 ? `0${countdown}` : countdown}
                              </Typography.Text>
                            </div>
                          )}
                        </>
                      )}
                      <div className="p-gotGift_updateGift" style={{ marginBottom: 32 }}>
                        <Typography.Text
                          type="span"
                          extendClasses="color-white fs-16x20 p-gotGift_updateGift_descript"
                        >
                          Quà của bạn đã được cập nhật trong
                          <br />
                          thư mục
                        </Typography.Text>
                        <Link
                          href={reward?.rewardData.giftType === 'luckyDraw' ? `${ROUTE_PATH.MY_GIFT}?luckyDraw=true` : ROUTE_PATH.MY_GIFT}
                          onClick={() => {
                            trackingLinkToMyGiftEvent(reward?.giftData.trackingEventType, reward?.rewardData.giftType === 'luckyDraw');
                          }}
                        >
                          <Typography.Text
                            type="span"
                            fontweight="600"
                            extendClasses="color-white fs-16x20 p-gotGift_updateGift_myGift"
                          >
                            Quà của tôi
                          </Typography.Text>
                        </Link>
                      </div>
                    </>
                  )}
                  {/* {reward?.rewardData.giftType === 'soccer' && (
                    <ButtonCustom
                      onClick={() => {
                        if (id === 'none') {
                          trackingEvent({
                            label: 'luck-nxt-time-scan-qr',
                            action: 'click',
                            category: 'game'
                          });
                        } else {
                          trackingButtonNextScanQRCodeEvent(
                            reward?.giftData.trackingEventType,
                            reward?.rewardData.giftType === 'luckyDraw'
                          );
                        }
                        navigate(ROUTE_PATH.CHALLENGE);
                      }}
                    >
                      Quét mã QR mới
                    </ButtonCustom>
                  )} */}
                </div>
              </>
            )}
            {reward?.rewardData.giftVendor !== 'voucherGrab' && (
              <div className="p-gotGift_footLink">
                <Link
                  href={ROUTE_PATH.CHALLENGE}
                  onClick={() => {
                    trackingNewQrGiftEvent(reward?.giftData.trackingEventType);
                  }}
                >
                  <Typography.Text
                    type="span"
                    fontweight="600"
                    extendClasses="color-white fs-16x20 p-gotGift_backHome_text"
                  >
                    Quét mã QR mới
                  </Typography.Text>
                </Link>
                <Link
                  href={ROUTE_PATH.HOME}
                  onClick={() => {
                    if (id === 'none') {
                      trackingEvent({
                        label: 'luck-nxt-time-homepage',
                        action: 'click',
                        category: 'game'
                      });
                    } else {
                      trackingLinkToHomePageEvent(reward?.giftData.trackingEventType, reward?.rewardData.giftType === 'luckyDraw');
                    }
                  }}
                >
                  <Typography.Text
                    type="span"
                    fontweight="600"
                    extendClasses="color-white fs-16x20 p-gotGift_backHome_text"
                  >
                    Về lại UCL trang chủ
                  </Typography.Text>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isOpenModal}
        handleClose={() => {
          setIsOpenModal(false);
          method.reset();
        }}
      >
        <Typography.Heading
          textStyle="center"
          extendClasses="color-white fs-20x26 p-info_headingModal"
          fontweight="900"
        >
          NHẬP SĐT SẼ ĐƯỢC
          <br />
          TÍCH HỢP GÓI SMAX 1
          <br />
          THÁNG TRÊN FPT PLAY
        </Typography.Heading>
        <FormProvider {...method}>
          <Controller
            name="otherPhone"
            control={method.control}
            render={({ field, fieldState: { error } }) => (
              <div className="p-info_fieldPhoneModal p-info_fieldModal">
                <Input placeholder="Nhập..." {...field} />
                {
                  error ? (
                    <div className="o-otp_error">
                      <Icon iconName="warning" size="12" />
                      <Typography.Text
                        extendClasses="fs-16x20 o-otp_error-text"
                        textStyle="center"
                        fontweight="600"
                      >
                        {error.message}
                      </Typography.Text>
                    </div>
                  ) : null
                }
              </div>
            )}
          />
        </FormProvider>
        <div className="p-info_button">
          <ButtonCustom
            onClick={() => {
              trackingEvent({
                action: 'click',
                category: 'redeem-vc-fpt',
                label: 'cf-new-phone'
              });
              onSubmit(method.getValues());
            }}
            loading={redeemLoading}
          >
            <Typography.Text
              extendClasses="p-info_btnText"
            >
              xác nhận
            </Typography.Text>
          </ButtonCustom>
        </div>
      </CustomModal>
      <CustomModal
        variant="successFpt"
        isOpen={isOpenModalSuccess}
        handleClose={() => {
          trackingEvent({
            action: 'click',
            category: 'redeem-vc-fpt',
            label: 'close-popup'
          });
          setIsOpenModalSuccess(false);
          gotoMyGift();
        }}
      >
        <div className="p-gotGift_success">
          <Typography.Heading
            textStyle="center"
            extendClasses="color-white fs-16x20 p-info_headingModalSuccess"
            fontweight="900"
          >
            CHÚC MỪNG BẠN ĐÃ TRÚNG
            <br />
            GÓI SMAX 1 THÁNG TRÊN
            <br />
            FPT PLAY
          </Typography.Heading>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Trường hợp 1
            </div>
            <div className="p-info_intro_step">
              <Typography.Text fontweight="700" extendClasses="p-info_intro_step_title">
                Nếu bạn chưa có tài khoản
                <br />
                FPT Play
              </Typography.Text>
              <ul>
                <li>
                  Đừng lo, chúng tôi sẽ hỗ trợ tạo tài khoản FPT Play bằng số điện thoại
                  {' '}
                  <span>{method.getValues('otherPhone') || profile?.account.phone}</span>
                  {' '}
                  của bạn. Tin nhắn hỗ trợ sẽ gửi đến bạn sau ít phút nữa, đừng quên kiểm tra nhé!
                </li>
                <li>
                  Bên cạnh đó, gói FPT Play 1 tháng đã được cộng
                  vào tài khoản của bạn, nhớ đăng nhập với số điện thoại
                  {' '}
                  <span>{method.getValues('otherPhone') || profile?.account.phone}</span>
                  {' '}
                  để sử dụng nhé!
                </li>
              </ul>
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Trường hợp 2
            </div>
            <div className="p-info_intro_step">
              <Typography.Text fontweight="700" extendClasses="p-info_intro_step_title">
                Nếu bạn đã có tài khoản
                <br />
                FPT Play:
              </Typography.Text>
              <ul>
                <li>
                  Gói FPT Play 1 tháng đã được cộng vào tài khoản của bạn,
                  bạn nhớ đăng nhập FPT Play với số điện thoại
                  {' '}
                  <span>{method.getValues('otherPhone') || profile?.account.phone}</span>
                  {' '}
                  để sử dụng nhé!
                </li>
              </ul>
            </div>
          </div>
          <Typography.Text extendClasses="color-white fs-16x20 p-info_intro_download" fontweight="400">
            Tải ứng dụng FPT Play tại đây để có
            <br />
            trải nghiệm tuyệt vời hơn nhé!
          </Typography.Text>
          <div className="p-info_intro_store">
            <Link href="https://apps.apple.com/vn/app/fpt-play-th%E1%BB%83-thao-phim-tv/id646297996?l=vi" target="_blank">
              <Image
                extendClasses="p-info_intro_store_img"
                imgSrc={appstore}
                alt="app-store"
                ratio={112 / 32.36}
              />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=net.fptplay.ottbox&hl=en&gl=US&pli=1" target="_blank">
              <Image
                extendClasses="p-info_intro_store_img"
                imgSrc={googleplay}
                alt="google-play"
                ratio={112 / 32.36}
              />
            </Link>
          </div>
          <div
            className="p-info_intro_forgot"
            onClick={() => {
              window.open('https://drive.google.com/file/d/1MKErPR2t_a_LU2aUZLA_VkMqgsiHsz0j/view', '_blank');
            }}
          >
            <Typography.Text
              fontweight="600"
              extendClasses="color-white fs-16x20"
            >
              Thông tin chi tiết gói SMAX 1 tháng
              {' '}
            </Typography.Text>
            <Icon iconName="right" size="12" />
          </div>
        </div>
      </CustomModal>
      <ModalNotify
        isOpen={invalid !== ''}
        handleClose={() => {
          setInvalid('');
          gotoMyGift();
        }}
      >
        <Typography.Text
          textStyle="center"
          extendClasses="color-white fs-20x26 t-modalNotify_message"
          fontweight="900"
        >
          {
            invalid === 'noVoucher' ? 'Không tìm thấy mã quà tặng' : ''
          }
          {
            invalid === 'other' ? (
              <>
                Đã có lỗi xảy ra
                <br />
                Vui lòng thử lại
              </>
            ) : ''
          }
        </Typography.Text>
      </ModalNotify>
      <CustomModal
        variant="introduction"
        isOpen={isOpenModalIntro}
        handleClose={() => {
          setIsOpenModalIntro(false);
          gotoMyGift();
        }}
      >
        <Typography.Text
          extendClasses="color-white p-info_intro_heading"
          fontweight="400"
        >
          Hướng dẫn gia nhập
          <br />
          đội fan đích thực
        </Typography.Text>
        <Typography.Text
          extendClasses="color-white p-info_intro_heading-large"
          fontweight="900"
          textStyle="uppercase"
        >
          Heineken x
          <br />
          FPT play
        </Typography.Text>
        <div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 1
            </div>
            <div className="p-info_intro_step">
              Mở ứng dụng FPT PLAY, tại màn
              <br />
              {' '}
              hình chính, chọn biểu tượng
              <br />
              để &quot;Đăng Nhập&quot;.
              <Icon iconName="app" size="30" />
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 2
            </div>
            <div className="p-info_intro_step">
              Chọn
              {' '}
              <span>&quot;Đăng ký tài khoản&quot;</span>
              <Image extendClasses="p-info_image" imgSrc={introSt2} alt="UCL" ratio={131 / 19} />
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 3
            </div>
            <div className="p-info_intro_step">
              Nhập số điện thoại và chọn
              {' '}
              <span>&quot;Tiếp tục&quot;</span>
              .
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 4
            </div>
            <div className="p-info_intro_step">
              Nhập mã OTP để xác thực, sau đó chọn
              {' '}
              <span>&quot;Tiếp tục&quot;</span>
              .
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 5
            </div>
            <div className="p-info_intro_step">
              Nhập mật khẩu và xác nhận lại mật khẩu.
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 6
            </div>
            <div className="p-info_intro_step">
              <span>Đăng ký/Đăng nhập</span>
              {' '}
              vào tài khoản FPT Play vừa tạo thành công.
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 7
            </div>
            <div className="p-info_intro_step">
              Chọn biểu tượng
              {' '}
              <Icon iconName="app" size="30" />
              {' '}
              trên màn hình chính để thấy vị trí nhập mã kích hoạt gói SMAX.
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 8
            </div>
            <div className="p-info_intro_step">
              Chọn
              {' '}
              <span>&quot;Nhập mã kích hoạt&quot;</span>
              .
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 9
            </div>
            <div className="p-info_intro_step">
              Nhập mã kích hoạt và chọn
              {' '}
              <span>&quot;Xác nhận&quot;</span>
              .
            </div>
          </div>
          <div
            className="p-info_intro_forgot"
            onClick={() => {
              setIsOpenModalForgot(true);
              setIsOpenModalIntro(false);
            }}
          >
            <Typography.Text
              fontweight="600"
              extendClasses="color-white fs-16x20"
            >
              Hướng dẫn lấy lại mật khẩu
              {' '}
            </Typography.Text>
            <Icon iconName="right" size="12" />
          </div>
        </div>
      </CustomModal>
      <CustomModal
        variant="introduction"
        isOpen={isOpenModalForgot}
        handleClose={() => {
          setIsOpenModalForgot(false);
          gotoMyGift();
        }}
      >
        <Typography.Text
          extendClasses="color-white p-info_intro_heading"
          fontweight="400"
        >
          Hướng dẫn
          <br />
          lấy lại phong độ
        </Typography.Text>
        <Typography.Text
          extendClasses="color-white p-info_intro_heading-large"
          fontweight="900"
          textStyle="uppercase"
        >
          KHI FAN
          <br />
          “QUÊN MẬT KHẨU”
        </Typography.Text>
        <div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 1
            </div>
            <div className="p-info_intro_step">
              Mở ứng dụng FPT PLAY, tại màn
              <br />
              {' '}
              hình chính, chọn biểu tượng
              <br />
              để &quot;Đăng Nhập&quot;.
              <Icon iconName="app" size="30" />
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 2
            </div>
            <div className="p-info_intro_step">
              Chọn mục
              {' '}
              <span>&quot;Quên mật khẩu&quot;</span>
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label">
              Bước 3
            </div>
            <div className="p-info_intro_step">
              Nhập SĐT muốn lấy lại mật khẩu để nhận và nhập mã OTP, sau đó ấn
              {' '}
              <span>&quot;Tiếp tục&quot;</span>
              .
            </div>
          </div>
          <div className="p-info_intro_item lastest">
            <div className="p-info_intro_label">
              Bước 4
            </div>
            <div className="p-info_intro_step">
              Nhập mật khẩu mới và xác nhận lại mật khẩu này.
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        isOpen={isOpenModalGrab}
        handleClose={() => {
          trackingEvent({
            action: 'click',
            category: 'get-grb-vc',
            label: 'close-pop-up'
          });
          setIsOpenModalGrab(false);
        }}
      >
        <Typography.Heading
          textStyle="center"
          extendClasses="color-white fs-20x26 p-info_headingModal"
          fontweight="900"
        >
          ĐÃ GHI BÀN VOUCHER
          <br />
          GRABGIFT
        </Typography.Heading>

        <Typography.Text
          textStyle="center"
          extendClasses="color-white fs-16x20 p-luckyResult_grabCode_above"
        >
          Mã kích hoạt gói ưu đãi trên
          <br />
          ứng dụng Grab của bạn đây!
        </Typography.Text>

        <Typography.Text
          textStyle="center"
          fontweight="900"
          extendClasses="p-luckyResult_grabCode"
        >
          [
          {reward?.rewardData.grabCode}
          ]
        </Typography.Text>
        <Typography.Text
          textStyle="center"
          extendClasses="color-white fs-16x20 p-luckyResult_grabCode_below"
        >
          Đi có Grab đón, ăn có Grab lo,
          <br />
          mở ứng dụng và nhập mã ngay
          <br />
          để tận hưởng nhé!
        </Typography.Text>

        <div className="p-luckyResult_introBtn">
          <ButtonCustom onClick={() => {
            trackingEvent({
              action: 'click',
              category: 'get-grb-vc',
              label: 'guideline'
            });
            setIsOpenModalIntroGrab(true);
          }}
          >
            <Typography.Text
              extendClasses="p-info_btnText"
            >
              Hướng dẫn sử dụng Grab gift
            </Typography.Text>
          </ButtonCustom>
        </div>
      </CustomModal>
      <CustomModal
        variant="introGrab"
        isOpen={isOpenModalIntroGrab}
        handleClose={() => {
          trackingEvent({
            action: 'click',
            category: 'get-grb-vc',
            label: 'close-guideline'
          });
          setIsOpenModalIntroGrab(false);
          gotoMyGift();
        }}
      >
        <Typography.Text
          extendClasses="color-white p-info_intro_heading-large"
          fontweight="900"
          textStyle="uppercase"
        >
          LƯU MÃ VOUCHER GRAB
          <br />
          GIFT NHƯ THẾ NÀO?
        </Typography.Text>
        <div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label small">
              Bước 1
            </div>
            <div className="p-info_intro_step">
              Mở ứng dụng Grab,
              <br />
              chọn mục Quà tặng
            </div>
            <div className="p-info_intro_icon">
              <Icon iconName="grabGift" size="36" />
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label small">
              Bước 2
            </div>
            <div className="p-info_intro_step">
              Chọn biểu tượng
              <br />
              ở góc phải phía
              <br />
              trên màn hình.
              <div className="p-info_intro_icon right">
                <Icon iconName="giftCircle" size="36" />
              </div>
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label small">
              Bước 3
            </div>
            <div className="p-info_intro_step">
              <span>Nhập mã GrabGift và lưu lại</span>
              {' '}
              để
              <br />
              sử dụng cho các dịch vụ di
              <br />
              chuyển và vận chuyển.
            </div>
          </div>
        </div>
      </CustomModal>
      <ModalSystemError
        isOpen={errSystem}
        handleClose={() => {
          setErrSystem(false);
          window.location.reload();
        }}
      />
      <CustomModal isOpen={id === 'none'} handleClose={() => navigate(ROUTE_PATH.HOME)}>
        <Typography.Heading
          textStyle="center"
          extendClasses="color-white fs-22x32 p-gotGift_heading"
          fontweight="900"
        >
          Chúc bạn may mắn
          <br />
          lần sau
        </Typography.Heading>
        <Typography.Text
          textStyle="center"
          extendClasses="color-white fs-16x20 p-gotGift_fail_text"
        >
          Bạn có thể tìm thêm
          {' '}
          <span>
            mã QR trong
            <br />
            thùng Heineken 24 lon
          </span>
          ,
          {' '}
          để tham gia
          <br />
          chơi game và có cơ hội nhận nhiều
          <br />
          phần quà hấp dẫn nhé!!
        </Typography.Text>
        <div className="p-gotGift_fail_rescan">
          <ButtonCustom
            onClick={() => {
              if (id === 'none') {
                trackingEvent({
                  label: 'luck-nxt-time-scan-qr',
                  action: 'click',
                  category: 'game'
                });
              } else {
                trackingButtonNextScanQRCodeEvent(
                  reward?.giftData.trackingEventType,
                  reward?.rewardData.giftType === 'luckyDraw'
                );
              }
              navigate(ROUTE_PATH.CHALLENGE);
            }}
          >
            Quét mã QR mới
          </ButtonCustom>
        </div>
        <div className="p-gotGift_backHome u-mb-80 u-mt-4">
          <Link
            href={ROUTE_PATH.HOME}
            onClick={() => {
              if (id === 'none') {
                trackingEvent({
                  label: 'luck-nxt-time-homepage',
                  action: 'click',
                  category: 'game'
                });
              } else {
                trackingLinkToHomePageEvent(reward?.giftData.trackingEventType, reward?.rewardData.giftType === 'luckyDraw');
              }
            }}
          >
            <Typography.Text
              type="span"
              fontweight="600"
              extendClasses="color-white fs-16x20 p-gotGift_backHome_text"
            >
              Về lại UCL trang chủ
            </Typography.Text>
          </Link>
        </div>
      </CustomModal>
      <div className={mapModifiers('copied', copied && 'show')}>
        Đã copy
      </div>
    </>
  );
};

export default GotGift;
