import React from 'react';
import { Link, Typography } from 'tfc-components';

import Icon from 'components/atoms/Icon';
import CustomModal from 'components/organisms/Modal';

interface ContactModalProps {
  open: boolean;
  handleClose?: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ open, handleClose }) => (
  <CustomModal isOpen={open} variant="contact" handleClose={handleClose}>
    <div className="t-contactModal">
      <div className="t-contactModal_title">
        <Typography.Text fontweight="900">
          Liên hệ
          <br />
          với chúng tôi
        </Typography.Text>
      </div>
      <div className="t-contactModal_line" />
      <div className="t-contactModal_content">
        <Typography.Text fontweight="600">
          Công ty TNHH Nhà Máy Bia Heineken
          <br />
          Vietnam, Tầng 18 và 19, Toà nhà
          <br />
          Vietcombank.
        </Typography.Text>
        <br />
        <Typography.Text fontweight="400">
          Số 5 Công Trường Mê Linh, Phường
          <br />
          Bến Nghé, Quận 1, TP Hồ Chí Minh.
        </Typography.Text>
        <br />
        <Typography.Text fontweight="400">
          Hotline Heineken:
          {' '}
          <Link href="tel:19001845"><span>1900 1845</span></Link>
        </Typography.Text>
      </div>
      <div className="t-contactModal_line" />
      <div className="t-contactModal_social">
        <Link useExternal href="https://www.facebook.com/HeinekenVN" target="_blank">
          <Icon iconName="fb" size="24" />
        </Link>
        <Link useExternal href="https://www.instagram.com/heineken_vn?igsh=NTc4dDJzMnIycThx" target="_blank">
          <Icon iconName="ig" size="24" />
        </Link>
      </div>
    </div>
  </CustomModal>
);

export default ContactModal;
