/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Loading, Typography } from 'tfc-components';

import img from 'assets/images/newsCard/dummy.png';
import BottomNavigate from 'components/organisms/BottomNavigate';
import Header from 'components/organisms/Header';
import MainLayout from 'components/organisms/MainLayout';
import NewsCard, { NewsCardProps } from 'components/organisms/NewsCard';
import useScrollInfinite from 'hooks/useScrollInfinite';
import { getNewsListService } from 'services/news';

const News: React.FC = () => {
  const {
    data: newsRes, isLoading, isFetching, fetchNextPage, hasNextPage
  } = useInfiniteQuery(
    ['get-news-list'],
    ({ pageParam = 1 }) => getNewsListService({
      page: pageParam,
    }),
    {
      getNextPageParam: (lastPage) => (lastPage.meta.page < lastPage.meta.totalPages
        ? lastPage.meta.page + 1 : undefined),
    }
  );

  const newsList: NewsCardProps[] = useMemo(() => {
    if (newsRes) {
      return newsRes.pages.reduce((acc, cur, idxPage) => {
        // eslint-disable-next-line no-param-reassign
        acc = [...acc, ...cur.data.map((item, idxNews) => ({
          imgSrc: item.newsData.thumbnail?.path || '',
          title: item.newsData.title || '',
          isSmall: !(idxPage === 0 && idxNews === 0),
          href: `/news/${item.newsData.slug}`,
        } as NewsCardProps))];
        return acc;
      }, [] as NewsCardProps[]);
    }
    return [];
  }, [newsRes]);

  const handleLoadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };
  const { setNode } = useScrollInfinite(handleLoadMore);

  return (
    <MainLayout>
      <div className="p-news">
        <div className="p-news_content">
          <Header isBack isProfile />
          <Typography.Heading
            textStyle="center"
            extendClasses="color-white fs-22x28 p-news_heading"
            fontweight="900"
          >
            ALL ABOUT UCL
          </Typography.Heading>
          <div className="p-news_list">
            {newsList.map((item, idx) => (
              <div key={`news-card-${idx.toString()}`} className="p-news_list_item" ref={(node) => (idx === newsList.length - 1 ? setNode(node) : undefined)}>
                <NewsCard
                  {...item}
                />
              </div>
            ))}
          </div>
          {(isLoading || isFetching) && <Loading.Circle extendClasses="p-news_loading" color="#19a25c" />}
        </div>
        <div className="p-news_bottom">
          <BottomNavigate />
        </div>
      </div>
    </MainLayout>
  );
};

export default News;
