import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Controller, FormProvider, UseFormReturn, useWatch
} from 'react-hook-form';
import {
  Button, Link, Loading, Typography
} from 'tfc-components';

// eslint-disable-next-line import/no-cycle
import { RegisterOtpForm, UserInfoTypes } from '.';

import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import DatePickerCustom from 'components/molecules/DatePicker';
import Pulldown from 'components/molecules/Pulldown';
import useAddressOptions from 'hooks/useAddressOptions';
import { VerifyOtpParams } from 'services/auth/types';
import { ROUTE_PATH } from 'utils/constants';
import mapModifiers from 'utils/functions';
import { trackingEvent } from 'utils/trackingGA';

type Step1Props = {
  method: UseFormReturn<RegisterOtpForm>;
  mainMethod: UseFormReturn<UserInfoTypes>;
  isLoadingOtp: boolean;
  isLoadingVerify: boolean;
  isDisableForm: boolean;
  verifyError?: string;
  timeout: number;
  hanldeRequestOtp: (data: RegisterOtpForm, cb?: () => void) => void;
  handleVerifyOtp: (data: VerifyOtpParams) => void;
  handleResend: () => void;
  handleRegister: (data: UserInfoTypes, isUpdate?: boolean) => void;
};

const Step1: React.FC<Step1Props> = ({
  method,
  mainMethod,
  isLoadingOtp,
  isLoadingVerify,
  isDisableForm,
  verifyError,
  timeout,
  handleVerifyOtp,
  hanldeRequestOtp,
  handleResend,
  handleRegister
}) => {
  const [otp, setOTP] = useState<string>('');
  const [timeEnabledResend, setTimeEnabledResend] = useState(timeout);
  const [hasSendOtp, setHasSendOtp] = useState(false);
  const [isDisableVerify, setIsDisableVerify] = useState<boolean>(true);
  const [requireSend, setRequireSend] = useState(false);

  const provinceWatch = useWatch({ name: 'provinceCode', control: mainMethod.control });
  const {
    provinces, isLoadingProvinces
  } = useAddressOptions(provinceWatch?.value);

  const onSubmit = async () => {
    const valid = await method.trigger();
    if (valid) {
      setRequireSend(false);
      trackingEvent({
        action: 'click',
        category: 'ken-game',
        label: 'OTP'
      });
      hanldeRequestOtp(method.getValues(), () => {
        if (!hasSendOtp) {
          setHasSendOtp(true);
        }
      });
      setIsDisableVerify(false);
    }
  };

  const onRegister = useCallback(async () => {
    trackingEvent({
      action: 'click',
      category: 'ken-game',
      label: 'submit'
    });
    const data = mainMethod.getValues();
    handleRegister(data, true);
  }, [handleRegister, mainMethod]);

  useEffect(() => {
    let count = timeEnabledResend;
    const timer = setInterval(() => {
      count -= 1;
      setTimeEnabledResend(count);
    }, 1000);
    if (timeEnabledResend === 0) clearInterval(timer);
    return () => {
      clearInterval(timer);
    };
  }, [handleResend, timeEnabledResend]);

  useEffect(() => {
    setTimeEnabledResend(timeout);
  }, [timeout]);

  const maxDate = useMemo(() => {
    const beforeDate = new Date();
    beforeDate.setDate(beforeDate.getDate() - 1);
    beforeDate.setFullYear(beforeDate.getFullYear() - 18);
    return new Date(beforeDate);
  }, []);
  return (
    <div className="p-register_step1">
      <FormProvider {...method}>
        <Controller
          name="phone"
          control={method.control}
          render={({ field, fieldState: { error } }) => (
            <>
              <div className="p-register_fieldButton">
                <div className="p-register_field_input">
                  <Input
                    {...field}
                    placeholder="Số điện thoại"
                    required
                    disabled={hasSendOtp}
                  />
                </div>
                <Button
                  loading={isLoadingOtp}
                  onClick={onSubmit}
                  extendClasses="p-register_otpButton"
                  disabled={hasSendOtp}
                  loadingIndicator={isLoadingOtp ? (
                    <div className="a-button_loadingIndicator">
                      <Loading.CircleDashed color="#fff" width={24} />
                    </div>
                ) : undefined}
                >
                  <Typography.Text
                    extendClasses="p-register_textButton fs-16x20 color-castletonGreen"
                  >
                    GỬI OTP
                  </Typography.Text>
                </Button>
              </div>
              {
                  error && (
                    <div className="p-register_error">
                      <Icon iconName="warning" size="12" />
                      <Typography.Text
                        extendClasses="fs-16x20 p-register_error-text"
                        fontweight="600"
                      >
                        {error.message}
                      </Typography.Text>
                    </div>
                  )
                }
            </>
          )}
        />
        <div className="p-register_field">
          <Input
            placeholder="Nhập OTP"
            required
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
            disabled={isDisableVerify}
          />
        </div>
        {verifyError && (
          <div className="p-register_error p-register_error-center">
            <Icon iconName="warning" size="12" />
            <Typography.Text
              extendClasses="fs-16x20 p-register_error-text"
              fontweight="600"
            >
              {verifyError}
            </Typography.Text>
          </div>
        )}
        {requireSend && (
          <div className="p-register_error p-register_error-center">
            <Icon iconName="warning" size="12" />
            <Typography.Text
              extendClasses="fs-16x20 p-register_error-text"
              fontweight="600"
            >
              Vui lòng bấm gửi OTP trước
            </Typography.Text>
          </div>
        )}
      </FormProvider>
      <div className={mapModifiers('p-register_content_button', isDisableVerify && 'disabled')}>
        <ButtonCustom
          loading={isLoadingVerify}
          onClick={() => {
            mainMethod.clearErrors();
            if (isDisableVerify) {
              setRequireSend(true);
            } else {
              trackingEvent({
                action: 'click',
                category: 'ken-game',
                label: 'cfm-OTP'
              });
              handleVerifyOtp({
                otp,
                phone: method.getValues('phone')
              });
            }
          }}
        >
          XÁC NHẬN
        </ButtonCustom>
      </div>
      {(hasSendOtp && isDisableForm) && (
        <div className="p-register_content_resend">
          <Typography.Text
            extendClasses="fs-16x20 color-white o-otp_noReceive"
            textStyle="center"
            fontweight="600"
          >
            Chưa nhận được à?
          </Typography.Text>
          <Typography.Text
            extendClasses="fs-16x20 o-otp_resend"
            textStyle="center"
            fontweight="700"
          >
            {timeEnabledResend === 0 && (
              <span
                className="fs-16x20 o-otp_resend_underline"
                onClick={() => handleResend()}
              >
                Gửi lại nhé!
              </span>
            )}
            {timeEnabledResend > 0 && (
              <>
                {' '}
                (chờ
                {' '}
                {timeEnabledResend}
                {' '}
                giây nữa)
              </>
            )}
          </Typography.Text>
        </div>
      )}
      <div className="p-register_infoForm">

        <FormProvider {...mainMethod}>
          <Controller
            name="name"
            control={mainMethod.control}
            render={({ field, fieldState: { error } }) => (
              <div className="p-register_field">
                <Input
                  {...field}
                  placeholder="Họ và tên"
                  required
                  disabled={isDisableForm}
                  onClick={() => {
                    trackingEvent({
                      action: 'click',
                      category: 'ken-game',
                      label: 'name'
                    });
                  }}
                />
                {
                  error && (
                    <div className="p-register_error">
                      <Icon iconName="warning" size="12" />
                      <Typography.Text
                        extendClasses="fs-16x20 p-register_error-text"
                        fontweight="600"
                      >
                        {error.message}
                      </Typography.Text>
                    </div>
                  )
                }
              </div>
            )}
          />
          <Controller
            name="dob"
            control={mainMethod.control}
            render={({ field, fieldState: { error } }) => (
              <div className="p-register_field">
                <DatePickerCustom
                  defaultDate={field.value ? new Date(field.value) : undefined}
                  handleSelectDate={field.onChange}
                  maxDate={maxDate}
                  disabled={isDisableForm}
                  handleClick={() => {
                    trackingEvent({
                      action: 'click',
                      category: 'ken-game',
                      label: 'birth'
                    });
                  }}
                />
                {
                  error && (
                    <div className="p-register_error">
                      <Icon iconName="warning" size="12" />
                      <Typography.Text
                        extendClasses="fs-16x20 p-register_error-text"
                        fontweight="600"
                      >
                        {error.message}
                      </Typography.Text>
                    </div>
                  )
                }
              </div>
            )}
          />
          <Controller
            name="provinceCode"
            control={mainMethod.control}
            render={({ field, fieldState: { error } }) => (
              <div className="p-register_field">
                <Pulldown
                  isDisabled={isDisableForm}
                  {...field}
                  required
                  placeholder="Tỉnh/Thành phố"
                  name="province"
                  loading={isLoadingProvinces}
                  options={provinces}
                  isBottom
                  handleChange={field.onChange}
                  onMenuOpen={() => {
                    trackingEvent({
                      action: 'click',
                      category: 'ken-game',
                      label: 'city'
                    });
                  }}
                />
                {
                  error && (
                    <div className="p-register_error">
                      <Icon iconName="warning" size="12" />
                      <Typography.Text
                        extendClasses="fs-16x20 p-register_error-text"
                        fontweight="600"
                      >
                        {error.message}
                      </Typography.Text>
                    </div>
                  )
                }
              </div>
            )}
          />
        </FormProvider>
        <div className="p-register_bottomText">
          <Typography.Text
            extendClasses="fs-16x20 color-white"
            textStyle="center"
          >
            Bạn đã sẵn sàng thể hiện đam mê
            <br />
            chứng tỏ
            {' '}
            <Link href={ROUTE_PATH.CHALLENGE}>
              <span
                className="p-register_bottomText_linear"
              >
                Fan đích thực ?
              </span>
            </Link>
          </Typography.Text>
        </div>
        <div className={mapModifiers('p-register_content_button', isDisableForm && 'disabled')}>
          <ButtonCustom
            onClick={() => {
              if (isDisableForm) {
                mainMethod.setError('provinceCode', { message: 'Vui lòng bấm gửi và xác nhận OTP trước' });
              } else {
                onRegister();
              }
            }}
          >
            ĐĂNG KÝ LIỀN
          </ButtonCustom>
        </div>
      </div>
    </div>
  );
};

export default Step1;
