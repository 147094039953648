import React from 'react';
import { Image, Typography } from 'tfc-components';

import footer from 'assets/images/footer.png';
import mapModifiers from 'utils/functions';

interface FooterProps {
  hasBackground?: boolean;
}

const Footer: React.FC<FooterProps> = ({ hasBackground }) => (
  <div className={mapModifiers('o-footer', hasBackground && 'background')}>
    <Image extendClasses="o-footer_image" imgSrc={footer} alt="footer" ratio={66 / 5} />
    <Typography.Text extendClasses="fs-10x12 color-white" fontweight="400" textStyle="center">
      SẢN PHẨM HEINEKEN SILVER CÓ NỒNG ĐỘ CỒN 4%
    </Typography.Text>
  </div>
);

export default Footer;
