/* eslint-disable prefer-regex-literals */
import * as yup from 'yup';

import { InputCodeForm } from 'components/templates/ModalLuckyCode';
import { PhoneForm } from 'pages/Login';
import { RegisterOtpForm, UserInfoTypes } from 'pages/Register';
import { UserInfoFormTypes } from 'pages/UserInfoUpdate';

export const phoneRegExp = /^0[1-9]\d{8}$/;

const useSchemas = () => {
  const verifyOtp: yup.ObjectSchema<PhoneForm> = yup.object().shape({
    phone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
    name: yup.string().required('Vui lòng cung cấp thông tin'),
    provinceCode: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required()
    }).default(undefined)
      .required('Vui lòng nhập thông tin bắt buộc'),
  });

  const userInfo: yup.ObjectSchema<UserInfoFormTypes> = yup.object().shape({
    name: yup.string().when(['fromGame'], {
      is: (fromGame: boolean) => !fromGame,
      then: (schema) => schema.required('Vui lòng nhập thông tin bắt buộc'),
    }),
    idNumber: yup.string().required('Vui lòng nhập thông tin bắt buộc').typeError('Vui lòng nhập thông tin bắt buộc')
      .test('idNumber', 'CMND/CCCD phải là 9 hoặc 12 chữ số', (value) => {
        if (value) {
          const idNumber = value;
          if (idNumber.length === 9 || idNumber.length === 12) return true;
        }
        return false;
      }),
    address: yup.string().required('Vui lòng nhập thông tin bắt buộc'),
    provinceCode: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required()
    }).default(undefined)
      .required('Vui lòng nhập thông tin bắt buộc'),
    districtCode: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required()
    }).default(undefined)
      .required('Vui lòng nhập thông tin bắt buộc'),
    fromGame: yup.boolean(),
  });

  const inputCode: yup.ObjectSchema<InputCodeForm> = yup.object().shape({
    code: yup.string().required('Vui lòng cung cấp thông tin').matches(new RegExp(process.env.REACT_APP_QRCODE_REGEX as string), 'Mã không đúng định dạng'),
  });

  const redeemPhone = yup.object().shape({
    otherPhone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
  });

  const registerOtp: yup.ObjectSchema<RegisterOtpForm> = yup.object().shape({
    phone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
  });

  const userInfoRegister: yup.ObjectSchema<UserInfoTypes> = yup.object().shape({
    name: yup.string().required('Vui lòng nhập thông tin bắt buộc'),
    dob: yup.string().required('Vui lòng nhập thông tin bắt buộc')
      .test('dob', 'Bạn chưa đủ 18 tuổi', (value) => {
        if (value) {
          const chosenDate = new Date(value);
          const currentDate = new Date();
          const age = currentDate.getFullYear() - chosenDate.getFullYear();
          if (age > 18) return true;
          if (age === 18) {
            if (currentDate.getMonth() > chosenDate.getMonth()) return true;
            if (currentDate.getMonth() === chosenDate.getMonth()) {
              if (currentDate.getDate() >= chosenDate.getDate()) return true;
            }
          }
          return false;
        }
        return true;
      }),
    provinceCode: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required()
    }).default(undefined)
      .required('Vui lòng nhập thông tin bắt buộc')
  });

  return {
    verifyOtp,
    userInfo,
    inputCode,
    redeemPhone,
    registerOtp,
    userInfoRegister
  };
};

export default useSchemas;
