import React from 'react';

import CustomModal from 'components/organisms/Modal';

interface ModalGiftWonProps {
  isOpen: boolean;
  children?: React.ReactNode;
  handleClose?: () => void;
}

const ModalNotify: React.FC<ModalGiftWonProps> = ({
  isOpen,
  children,
  handleClose
}) => (
  <div className="t-modalNotify">
    <CustomModal
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div className="t-modalNotify_content">
        {children}
      </div>
    </CustomModal>
  </div>
);

export default ModalNotify;
