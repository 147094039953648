/* eslint-disable no-param-reassign */
import ball from 'assets/images/ball.png';
import { GiftItems } from 'pages/LuckyWheel';

const image = new Image();
image.src = ball;
export const draw = (
  ctx: CanvasRenderingContext2D,
  R: number,
  data: GiftItems[],
  icons: Array<CanvasImageSource>,
  n: number,
  t: number
) => {
  const angle = Math.PI * 2 / (data.length);
  const colors = Array.from({ length: data.length }, (_, i) => (i % 2 ? '#01e487' : '#179662'));

  // ctx.reset();
  ctx.clearRect(0, 0, 2 * R, 2 * R);
  ctx.save();
  ctx.translate(R, R);

  ctx.save();
  ctx.rotate(-n * angle);

  ctx.beginPath();
  ctx.arc(0, 0, R, 0, Math.PI * 2);
  ctx.fillStyle = '#30e6c0';
  ctx.fill();

  // bulb
  ctx.save();
  for (let i = 0; i < data.length; i += 1) {
    let intensity = t % 500 / 500;
    if (i % 2) {
      intensity = 1 - intensity;
    }

    ctx.save();
    ctx.translate(0, 0.97 * R);

    ctx.fillStyle = '#ccc';
    ctx.beginPath();
    ctx.arc(0, 0, 5, 0, Math.PI * 2);
    ctx.fill();

    const grd = ctx.createRadialGradient(0, 0, 0, 0, 0, 15);
    grd.addColorStop(0, `rgba(255, 255, 255, ${intensity})`);
    grd.addColorStop(1, 'rgba(255, 255, 255, 0)');
    ctx.fillStyle = grd;

    ctx.beginPath();
    ctx.arc(0, 0, 15, 0, Math.PI * 2);
    ctx.fill();

    ctx.fillStyle = `rgba(255, 255, 255, ${intensity})`;
    ctx.beginPath();
    ctx.arc(0, 0, 5, 0, Math.PI * 2);
    ctx.fill();

    ctx.restore();

    ctx.rotate(angle);
  }
  ctx.restore();

  ctx.save();
  ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
  ctx.shadowBlur = 15;
  ctx.fillStyle = '#2efdf5';

  ctx.beginPath();
  ctx.arc(0, 0, 0.94 * R, 0, Math.PI * 2);
  ctx.fill();
  ctx.restore();

  if (t > 0) {
    // s = s0 + v0 * t + 0.5 * a * t^2
    // t = 10, s = 12.5, v = 2.5
    const rotAngle = 0.5 * Math.PI / 2 * (t / 1000) ** 2;
    ctx.rotate(-rotAngle);
  }

  colors.forEach((color, index) => {
    ctx.save();
    ctx.rotate(-Math.PI / 2);
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.arc(0, 0, 0.93 * R, -angle / 2, angle / 2);

    const grd = ctx.createRadialGradient(0, 0, 0, 0, 0, R);
    grd.addColorStop(0, index % 2 ? '#00e991' : '#0db04a');
    grd.addColorStop(0.75, color);
    grd.addColorStop(1, index % 2 ? '#00e991' : '#0db04a');
    ctx.fillStyle = grd;
    ctx.fill();
    ctx.restore();

    ctx.save();
    ctx.translate(0, -3 * R / 4);
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.font = '20px sans-serif';
    ctx.textBaseline = 'top';

    const scale = R / 150;
    const ratio = data[index].name ? 40 : 80;
    const dx = -ratio / 2;
    const dy = data[index].name ? -18 : -30;
    ctx.drawImage(
      icons[index],
      dx * scale,
      dy * scale,
      ratio * scale,
      ratio * scale
    );
    ctx.restore();

    ctx.rotate(angle);
  });

  ctx.restore();

  // center arrow
  ctx.drawImage(image, -105 * 2, -109 * 2, image.width * 2, image.height * 2);
  ctx.restore();
};

const placeholder = '';

export default placeholder;
