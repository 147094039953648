/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Typography } from 'tfc-components';

import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import Header from 'components/organisms/Header';
import NewsCard from 'components/organisms/NewsCard';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { getNewsDetailService } from 'services/news';
import { ROUTE_PATH } from 'utils/constants';
import { formatCalendar } from 'utils/functions';

const NewsDetail: React.FC = () => {
  const { height } = useWindowDimensions();
  const { slug } = useParams();
  const {
    mutate: getNewsDetail, data: newsDetailRes, isLoading,
  } = useMutation(
    ['get-news-detail'],
    (slugParams: string) => getNewsDetailService(slugParams),
  );

  useEffect(() => {
    if (slug) getNewsDetail(slug);
  }, [slug]);

  return (
    <>
      <Header isBack isProfile />
      <div className="p-newsDetail" style={{ height: `${height - 145}px` }}>
        {isLoading && <LoadingFullScreen />}
        {
          newsDetailRes?.translations?.vi?.title && (
            <Typography.Heading
              extendClasses="color-white fs-22x28 p-newsDetail_heading"
              fontweight="900"
            >
              {newsDetailRes?.translations.vi.title}
            </Typography.Heading>
          )
        }
        {
          newsDetailRes?.translations?.vi?.thumbnail.path && (
            <div className="p-newsDetail_img">
              <img src={newsDetailRes?.translations.vi.thumbnail.path} alt={newsDetailRes?.translations.vi.thumbnail.alt || 'news'} />
            </div>
          )
        }

        {
          newsDetailRes?.newsData.publishedAt && (
            <Typography.Text
              fontweight="500"
              extendClasses="color-white fs-14x18"
            >
              {formatCalendar(newsDetailRes?.newsData.publishedAt)}
            </Typography.Text>
          )
        }

        {
          newsDetailRes?.translations?.vi?.description && (
            <Typography.Text
              fontweight="600"
              extendClasses="color-white fs-20x26 p-newsDetail_description"
            >
              {newsDetailRes?.translations?.vi?.description}
            </Typography.Text>
          )
        }

        {
          newsDetailRes?.translations?.vi?.content && (
            <div
              className="color-white fs-16x20 p-newsDetail_description"
              dangerouslySetInnerHTML={{ __html: newsDetailRes?.translations?.vi?.content }}
            />
          )
        }

        {
          newsDetailRes?.relatedByNews
          && newsDetailRes?.relatedByNews.length > 0 && (
            <>
              <div className="p-newsDetail_divider" />

              <div className="p-newsDetail_bottom">
                <div className="p-newsDetail_bottom_title">
                  <Typography.Heading
                    extendClasses="color-white fs-20x25"
                    fontweight="900"
                  >
                    TIN LIÊN QUAN
                  </Typography.Heading>
                </div>
                <div className="p-newsDetail_bottom_list">
                  {
                    newsDetailRes?.relatedByNews?.map((item, idx) => (
                      <div key={`news-card-${idx.toString()}`} className="p-newsDetail_bottom_list_item">
                        <NewsCard
                          variant="horizontal"
                          imgSrc={item.thumbnail?.path || ''}
                          title={item.title || ''}
                          href={`${ROUTE_PATH.NEWS}/${item.slug}`}
                          description={item.description || ''}
                          publishedAt={formatCalendar(newsDetailRes.newsData.publishedAt)}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            </>
          )
        }
      </div>
    </>
  );
};

export default NewsDetail;
