import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  close: 'close',
  calendar: 'calendar',
  arrowDown: 'arrowDown',
  home: 'home',
  homeActive: 'homeActive',
  game: 'game',
  gameActive: 'gameActive',
  gift: 'gift',
  giftActive: 'giftActive',
  news: 'news',
  newsActive: 'newsActive',
  warning: 'warning',
  back: 'back',
  profile: 'profile',
  arrowRight: 'arrowRight',
  check: 'check',
  star: 'star',
  scan: 'scan',
  input: 'input',
  lock: 'lock',
  right: 'right',
  app: 'app',
  fb: 'fb',
  ig: 'ig',
  support: 'support',
  grabGift: 'grabGift',
  giftCircle: 'giftCircle',
  download: 'download',
  blueWarning: 'blueWarning',
  warningE: 'warningE',
};

export type IconName = keyof typeof iconList;

export type IconSize = '12' | '15' | '18' | '20' | '23' | '24' | '28' | '30' | '32' | '36' | '60' | '76' | '80';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
