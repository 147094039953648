/* eslint-disable react/react-in-jsx-scope */
import NavigateLayout from 'components/templates/NavigateLayout';
import Challenge from 'pages/Challenge';
// import Fancard from 'pages/Fancard';
import Gameshow2 from 'pages/Gameshow2';
import GiftDetail from 'pages/GiftDetail';
import GiftTotal from 'pages/GiftTotal';
import GotGift from 'pages/GotGift';
import Info from 'pages/Info';
import Login from 'pages/Login';
import LuckyResult from 'pages/LuckyResult';
import LuckyWheelPage from 'pages/LuckyWheel';
import MyGift from 'pages/MyGift';
import News from 'pages/News';
import NewsDetail from 'pages/NewsDetail';
import Profile from 'pages/Profile';
import Register from 'pages/Register';
import ScanQr from 'pages/ScanQr';
import SoccerGame from 'pages/SoccerGame';
import UserInfoUpdate from 'pages/UserInfoUpdate';
import ValidateQrCode from 'pages/ValidateQrCode';
import { ROUTE_PATH } from 'utils/constants';

export type RouteProps = {
  id: number;
  path?: string;
  element: React.ReactNode;
  protected?: boolean;
  children?: RouteProps[];
};
const routes: RouteProps[] = [
  {
    id: 2,
    element: <NavigateLayout />,
    children: [
      {
        id: 21,
        path: ROUTE_PATH.CHALLENGE,
        element: <Challenge />,
      },
      {
        id: 22,
        path: ROUTE_PATH.GIFT,
        element: <GiftTotal />,
      },
      {
        id: 23,
        path: `${ROUTE_PATH.GIFT}/:code`,
        element: <GiftDetail />
      },
      {
        id: 24,
        path: ROUTE_PATH.MY_GIFT,
        element: <MyGift />,
      },
      {
        id: 15,
        path: `${ROUTE_PATH.NEWS}/:slug`,
        element: <NewsDetail />,
      },
      {
        id: 5,
        path: `${ROUTE_PATH.GOT_GIFT}/:id`,
        element: <GotGift />,
        protected: true,
      },
      {
        id: 5,
        path: `${ROUTE_PATH.LUCKY_GOT_GIFT}/:id`,
        element: <LuckyResult />,
        protected: true,
      },
      // {
      //   id: 24,
      //   path: ROUTE_PATH.TNC,
      //   element: <Tnc />,
      // },
    ],
  },
  {
    id: 3,
    path: ROUTE_PATH.LOGIN,
    element: <Login />,
  },
  {
    id: 4,
    path: ROUTE_PATH.PROFILE,
    element: <Profile />,
    protected: true,
  },
  {
    id: 8,
    path: ROUTE_PATH.UPDATE_USER_INFO,
    element: <UserInfoUpdate />,
    protected: true,
  },
  {
    id: 9,
    path: ROUTE_PATH.VALIDATE_QR_CODE,
    element: <ValidateQrCode />,
  },
  {
    id: 10,
    path: ROUTE_PATH.NEWS,
    element: <News />,
  },
  {
    id: 11,
    path: `${ROUTE_PATH.UPDATE_PHONE}/:rewardId`,
    element: <Info />,
  },
  {
    id: 12,
    path: ROUTE_PATH.SCAN_QR,
    element: <ScanQr />,
  },
  {
    id: 13,
    path: ROUTE_PATH.GAME,
    element: <SoccerGame />,
  },
  // {
  //   id: 7,
  //   path: `${ROUTE_PATH.VALIDATE_QR_CODE}:code`,
  //   element: <RedirectToPage />,
  // },
  {
    id: 14,
    path: ROUTE_PATH.REGISTER,
    element: <Register />,
  },
  {
    id: 15,
    path: ROUTE_PATH.GAMESHOW,
    element: <Gameshow2 />,
  },
  {
    id: 16,
    path: ROUTE_PATH.LUCKY_WHEEL,
    element: <LuckyWheelPage />,
  },
  // {
  //   id: 17,
  //   path: ROUTE_PATH.FAN_CARD,
  //   element: <Fancard />,
  // },
];

export default routes;
