import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Image, Link, Typography } from 'tfc-components';

import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import Header from 'components/organisms/Header';
import CustomModal from 'components/organisms/Modal';
import useWindowDimensions from 'hooks/useWindowDemensions';
import HelmetComponent from 'pages/helmet';
import { getDetailRewardService } from 'services/rewards';
import { ROUTE_PATH } from 'utils/constants';
import mapModifiers from 'utils/functions';
import SEO_DATA from 'utils/seoData';

const LuckyResult: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { height } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const open = searchParams.get('open');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenIntro, setIsOpenIntro] = useState(false);
  const [copied, setCopied] = React.useState(false);
  const { data: reward, isLoading } = useQuery('rewardDetail', () => (id && id !== 'none' ? getDetailRewardService(id) : undefined));
  const onHandleGiveGift = (isPhysical: boolean) => {
    if (isPhysical) {
      navigate(ROUTE_PATH.MY_GIFT);
    } else {
      setIsOpenModal(true);
    }
  };

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.GOT_GIFT} />
      {isLoading && <LoadingFullScreen />}
      <div className="p-gotGift">
        <div className="p-gotGift_content">
          <Header isBack={!!open} isProfile />
          <div className="p-myGift_giftList" style={{ height: `${height - 140}px` }}>
            <Typography.Heading
              textStyle="center"
              extendClasses="color-white fs-22x32 p-gotGift_heading"
              fontweight="900"
            >
              {id === 'none' ? (
                <>
                  Chúc bạn may mắn
                  <br />
                  lần sau
                </>
              ) : (
                <>
                  Chúc mừng bạn
                  <br />
                  đã trúng
                </>
              )}
            </Typography.Heading>
            {id !== 'none' && (
              <>
                <div className="p-gotGift_giftImg">
                  <div className="p-gotGift_animation">
                    <div className="star one" />
                    <div className="star two" />
                    <div className="star three" />
                  </div>
                  <Image
                    imgSrc={reward?.giftData.image || ''}
                    ratio={328 / 284}
                    alt="gift"
                  />
                </div>
                <div className="p-gotGift_giftName fs-14x21 color-white" dangerouslySetInnerHTML={{ __html: reward?.giftData.name || '' }} />
                {reward?.rewardData.giftVendor === 'voucherGrab' && reward.rewardData.redeemStatus === 1 && (
                  <div className="p-gotGift_codeWrap">
                    <div className="p-gotGift_code">
                      <span>{reward?.rewardData.grabCode}</span>
                      <div
                        className="p-gotGift_code_copy"
                        onClick={() => {
                          if (reward?.rewardData.grabCode) {
                            setCopied(true);
                            navigator.clipboard.writeText(reward?.rewardData.grabCode);
                          }
                        }}
                      >
                        Copy
                      </div>
                    </div>
                  </div>
                )}
                <div className="p-gotGift_updateGift">
                  <Typography.Text
                    type="span"
                    extendClasses="color-white fs-16x20 p-gotGift_updateGift_descript"
                  >
                    Quà của bạn đã được cập nhật trong
                    <br />
                    thư mục
                  </Typography.Text>
                  <Link
                    href={ROUTE_PATH.MY_GIFT}
                  >
                    <Typography.Text
                      type="span"
                      fontweight="600"
                      extendClasses="color-white fs-16x20 p-gotGift_updateGift_myGift"
                    >
                      Quà của tôi
                    </Typography.Text>
                  </Link>
                </div>
              </>
            )}
            <div className={mapModifiers('p-gotGift_btnGroup', 'flex')}>
              {id !== 'none' && !open && (
                <ButtonCustom
                  onClick={() => onHandleGiveGift(reward?.rewardData.giftVendor === 'physical')}
                >
                  Nhận quà ngay
                </ButtonCustom>
              )}
              <ButtonCustom
                onClick={() => {
                  navigate(ROUTE_PATH.CHALLENGE);
                }}
              >
                Quét mã QR mới
              </ButtonCustom>
            </div>
            <div className="p-gotGift_backHome">
              <Link
                href={ROUTE_PATH.HOME}
              >
                <Typography.Text
                  type="span"
                  fontweight="600"
                  extendClasses="color-white fs-16x20 p-gotGift_backHome_text"
                >
                  Về lại UCL trang chủ
                </Typography.Text>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isOpenModal}
        handleClose={() => {
          setIsOpenModal(false);
        }}
      >
        <Typography.Heading
          textStyle="center"
          extendClasses="color-white fs-20x26 p-info_headingModal"
          fontweight="900"
        >
          ĐÃ GHI BÀN VOUCHER
          <br />
          GRABGIFT
        </Typography.Heading>

        <Typography.Text
          textStyle="center"
          extendClasses="color-white fs-16x20 p-luckyResult_grabCode_above"
        >
          Mã kích hoạt gói ưu đãi trên
          <br />
          ứng dụng Grab của bạn đây!
        </Typography.Text>

        <Typography.Text
          textStyle="center"
          fontweight="900"
          extendClasses="p-luckyResult_grabCode"
        >
          [
          {reward?.rewardData.grabCode}
          ]
        </Typography.Text>
        <Typography.Text
          textStyle="center"
          extendClasses="color-white fs-16x20 p-luckyResult_grabCode_below"
        >
          Đi có Grab đón, ăn có Grab lo,
          <br />
          mở ứng dụng và nhập mã ngay
          <br />
          để tận hưởng nhé!
        </Typography.Text>

        <div className="p-luckyResult_introBtn">
          <ButtonCustom onClick={() => setIsOpenIntro(true)}>
            <Typography.Text
              extendClasses="p-info_btnText"
            >
              Hướng dẫn sử dụng Grab gift
            </Typography.Text>
          </ButtonCustom>
        </div>
      </CustomModal>
      <CustomModal
        variant="introGrab"
        isOpen={isOpenIntro}
        handleClose={() => {
          setIsOpenIntro(false);
          navigate(ROUTE_PATH.MY_GIFT);
        }}
      >
        <Typography.Text
          extendClasses="color-white p-info_intro_heading-large"
          fontweight="900"
          textStyle="uppercase"
        >
          LƯU MÃ VOUCHER GRAB
          <br />
          GIFT NHƯ THẾ NÀO?
        </Typography.Text>
        <div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label small">
              Bước 1
            </div>
            <div className="p-info_intro_step">
              Mở ứng dụng Grab,
              <br />
              chọn mục Quà tặng
            </div>
            <div className="p-info_intro_icon">
              <Icon iconName="grabGift" size="36" />
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label small">
              Bước 2
            </div>
            <div className="p-info_intro_step">
              Chọn biểu tượng
              <br />
              ở góc phải phía
              <br />
              trên màn hình.
              <div className="p-info_intro_icon right">
                <Icon iconName="giftCircle" size="36" />
              </div>
            </div>
          </div>
          <div className="p-info_intro_item">
            <div className="p-info_intro_label small">
              Bước 3
            </div>
            <div className="p-info_intro_step">
              <span>Nhập mã GrabGift và lưu lại</span>
              {' '}
              để
              <br />
              sử dụng cho các dịch vụ di
              <br />
              chuyển và vận chuyển.
            </div>
          </div>
        </div>
      </CustomModal>
      <div className={mapModifiers('copied', copied && 'show')}>
        Đã copy
      </div>
    </>
  );
};

export default LuckyResult;
