import {
  CodeFanCardData,
  FanCardParams,
  FancardCheckerData,
  ProfileParams, RequestOtpData, RequestOtpParams, RequestRedeemTypes,
  RewardsDataTypes, SoccersDataTypes, UserProfile, VerifyOtpData, VerifyOtpParams
} from './types';

import axiosInstance from 'services/common/instance';

export const getProfileService = async (): Promise<UserProfile> => {
  const res = await axiosInstance.get('accounts/auth/profile');
  return res.data.data;
};

export const requestOtpService = async (data: RequestOtpParams): Promise<RequestOtpData> => {
  const res = await axiosInstance.post('accounts/auth/request-otp', data);
  return res.data.data;
};

export const verifyOtpService = async (data: VerifyOtpParams): Promise<VerifyOtpData> => {
  const res = await axiosInstance.post('accounts/auth/verify-otp', data);
  return res.data.data;
};

export const updateProfileService = async (data: ProfileParams): Promise<void> => {
  await axiosInstance.post('accounts/auth/update-profile', data);
};

export const getRewardListService = async (): Promise<RewardsDataTypes[]> => {
  const res = await axiosInstance.get('accounts/auth/rewards');
  return res.data.data;
};

export const requestRedeemService = async (params: RequestRedeemTypes)
  : Promise<{ fptPlayCode: string, redeemStatus: number }> => {
  const res = await axiosInstance.post('accounts/auth/redeem', params);
  return res.data.data;
};

export const getSoccersListService = async (): Promise<SoccersDataTypes[]> => {
  const res = await axiosInstance.get('accounts/auth/soccers');
  return res.data.data;
};

export const getCodeFanCardService = async (params: FanCardParams): Promise<CodeFanCardData> => {
  const formData = new FormData();
  if (params.cardImage) {
    formData.append('cardImage', params.cardImage);
  }
  if (params.code) {
    formData.append('code', params.code);
  }
  formData.append('step', params.step.toString());
  const res = await axiosInstance.post('fan-cards', formData);
  return res.data.data;
};

export const checkFanCardImageService = async (image: File): Promise<FancardCheckerData> => {
  const formData = new FormData();
  formData.append('cardImage', image);
  const res = await axiosInstance.post('fan-cards/check-card-image', formData);
  return res.data.data;
};
