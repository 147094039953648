function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo(
      { behavior: 'smooth', top: ref.current.offsetTop - 68 },
    ); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}
export const formatDateYYYYMMDD = (date?: string | Date) => {
  if (!date) return '';
  const dateFormat = typeof (date) === 'string' ? new Date(date) : date;
  let day: string | number = dateFormat.getDate();
  let month: string | number = dateFormat.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${dateFormat.getFullYear()}-${month}-${day}`;
};

export const formatDateDDMMYYYY = (date?: string | Date) => {
  if (!date) return '';
  const dateFormat = typeof (date) === 'string' ? new Date(date) : date;
  let day: string | number = dateFormat.getDate();
  let month: string | number = dateFormat.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${day}-${month}-${dateFormat.getFullYear()}`;
};

export const formatDateDDMMYYYY2 = (date?: string | Date) => {
  if (!date) return '';
  const dateFormat = typeof (date) === 'string' ? new Date(date) : date;
  let day: string | number = dateFormat.getDate();
  let month: string | number = dateFormat.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${day}/${month}/${dateFormat.getFullYear()}`;
};

export const formatCalendar = (date?: string | Date) => {
  if (!date) return '';
  const dateFormat = typeof (date) === 'string' ? new Date(date) : date;
  let day: string | number = dateFormat.getDate();
  let month: string | number = dateFormat.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${day}/${month}/${dateFormat.getFullYear()}`;
};

export function formatDateYYMMDDtoDDMMYY(date?: string) {
  if (!date) return '';
  const datePart = date.match(/\d+/g);
  const year = datePart ? datePart[0] : ''; // get only two digits
  const month = datePart ? datePart[1] : '';
  const day = datePart ? datePart[2] : '';

  return `${day}/${month}/${year}`;
}

export function formatDateDDMMYYtoYYMMDD(date?: string) {
  if (!date) return '';
  const datePart = date.match(/\d+/g);
  const year = datePart ? datePart[2] : ''; // get only two digits
  const month = datePart ? datePart[1] : '';
  const day = datePart ? datePart[0] : '';

  return `${year}-${month}-${day}`;
}

export function formatDateHHmmDDMM(date?: string) {
  if (!date) return '';
  const datePart = new Date(date);

  return `${datePart.getHours() > 9 ? datePart.getHours() : `0${datePart.getHours()}`}:${datePart.getMinutes() > 9 ? datePart.getMinutes() : `0${datePart.getMinutes()}`}' NGÀY ${datePart.getDate() > 9 ? datePart.getDate() : `0${datePart.getDate()}`}.${datePart.getMonth() + 1 > 9 ? datePart.getMonth() + 1 : `0${datePart.getMonth() + 1}`}`;
}

// export const getImageURL = (imgUrl?: string) => `${BASE_URL || ''}${imgUrl || ''}`;

export const getSearchParams = (searchEntries: any) => {
  let result: { [key: string]: string } = {};
  for (const [a, b] of searchEntries) {
    result = { ...result, [a]: b };
  }
  return result;
};

export const downloadFile = (path: string, name?: string) => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.download = name || `${Date.now()}`;
  a.href = path;
  a.click();
  URL.revokeObjectURL(path);
  a.remove();
};

export const compressImage = (
  url: string,
): Promise<{
  dataUrl: Blob,
}> => {
  let resolver = (data: any) => data;
  const imager = document.createElement('img');
  imager.src = url;
  const canvas = document.createElement('canvas');
  imager.onload = () => {
    const maxWidth = 400;
    const maxHeight = 600;

    const imgwidth = imager.width;
    const imgheight = imager.height;
    let ratio = Math.min(maxWidth / imgwidth, maxHeight / imgheight);
    if (ratio > 1) {
      ratio = 1;
    }

    const newWidth = imgwidth * ratio;
    const newHeight = imgheight * ratio;
    canvas.width = newWidth;
    canvas.height = newHeight;
    canvas.getContext('2d')?.drawImage(
      imager,
      0,
      0,
      canvas.width,
      canvas.height,
    );
    canvas.toBlob(
      (b) => {
        if (b) {
          const result = {
            dataUrl: b,
          };
          resolver(result);
        }
      },
    );
  };
  return new Promise((resolve) => {
    resolver = resolve;
  });
};
