import { NewsDataTypes, NewsDetailDataTypes } from './types';

import axiosInstance from 'services/common/instance';

// eslint-disable-next-line import/prefer-default-export
export const getNewsListService = async (params?: BaseFilterParams):
  Promise<APIPaginationResponse<NewsDataTypes[]>> => {
  const res = await axiosInstance.get('news', { params });
  return res.data;
};

export const getNewsDetailService = async (slug: string):
  Promise<NewsDetailDataTypes> => {
  const res = await axiosInstance.get(`news/${slug}`);
  return res.data.data;
};
