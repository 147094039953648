/* eslint-disable import/no-cycle */
/* eslint-disable max-len */

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography } from 'tfc-components';

import Step1 from './step1';
import Step2 from './step2';

import ButtonCustom from 'components/atoms/Button';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import Footer from 'components/organisms/Footer';
import Header from 'components/organisms/Header';
import MainLayout from 'components/organisms/MainLayout';
import CustomModal from 'components/organisms/Modal';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { requestOtpService, updateProfileService, verifyOtpService } from 'services/auth';
import { ProfileParams, RequestOtpParams, VerifyOtpParams } from 'services/auth/types';
import returnValidateErrorMessages from 'services/common/errors';
import {
  getLocalStorage,
  setAccessToken,
  setRefreshToken
} from 'services/common/storage';
import { trackingURLService } from 'services/systems';
import { getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LOCAL_STORAGE, ROUTE_PATH } from 'utils/constants';
import { formatDateYYYYMMDD } from 'utils/functions';
import useSchemas from 'utils/schemas';
import { trackingEvent } from 'utils/trackingGA';

export type RegisterOtpForm = {
  phone: string;
};

export interface UserInfoTypes {
  name?: string;
  dob?: string;
  provinceCode?: OptionType;
}

const fieldToText = (field: string) => {
  switch (field) {
    case 'phone':
      return 'Số điện thoại';
    case 'otp':
      return 'OTP';
    default:
      return '';
  }
};

const Register: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { height } = useWindowDimensions();
  const { profile, isStartApp } = useAppSelector((state) => state.auth);
  const luckyDrawClosed = useAppSelector((state) => state.systems.system?.luckyDrawConfig.closed);
  const systemLoading = useAppSelector((state) => state.systems.systemLoading);
  const { registerOtp, userInfoRegister } = useSchemas();
  const [searchParams] = useSearchParams();
  const missingProfile = searchParams.get('missingProfile');
  const verifyOtpMethod = useForm<RegisterOtpForm>({
    mode: 'onChange',
    resolver: yupResolver(registerOtp),
    defaultValues: {
      phone: '',
    },
  });
  const mainMethod = useForm<UserInfoTypes>({
    mode: 'onChange',
    resolver: yupResolver(userInfoRegister),
    defaultValues: {
      name: '',
      dob: ''
    },
  });

  const [step, setStep] = React.useState<number>(1);
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [isDisableForm, setIsDisableForm] = React.useState<boolean>(true);
  const [verifyError, setVerifyError] = React.useState<string | undefined>();

  const { mutate: requestOtp, isLoading, data: requestOtpData } = useMutation('requestOtpRegister', (data: { params: RequestOtpParams, cb?: () => void }) => requestOtpService(data.params), {
    onSuccess: (_, variables) => {
      if (variables.cb) {
        variables.cb();
      }
    },
    onError: (error: any) => {
      if (error.length > 0) {
        verifyOtpMethod.setError('phone', { message: returnValidateErrorMessages(error[0], fieldToText(error[0].field)) });
      } else {
        verifyOtpMethod.setError('phone', { message: 'Có lỗi xảy ra, vui lòng thử lại' });
      }
    }
  });
  const { mutate: trackingMutate } = useMutation('tracking-url', trackingURLService);

  const { mutate: verifyOtpMutate, isLoading: verifyLoading } = useMutation('verifyOtp', verifyOtpService, {
    onSuccess: (data) => {
      setAccessToken(data.accessToken);
      setRefreshToken(data.refreshToken);
      const queryParams = getLocalStorage(LOCAL_STORAGE.TRACKING_URL);
      if (queryParams) {
        const parser = JSON.parse(queryParams);
        trackingMutate({ ...parser, others: { ...parser.others, rejectedAgeCheck: String(getLocalStorage(LOCAL_STORAGE.AGE_GATE) === 'false') } });
      }
      dispatch(getProfileAction()).unwrap().then((prof) => {
        if (prof?.profile) {
          mainMethod.reset({
            name: prof.profile.name,
            dob: prof.profile.dob,
            provinceCode: prof.profile.province ? {
              label: prof.profile.province.name,
              value: prof.profile.province.code
            } : undefined
          });
        }
      });
      setIsDisableForm(false);
      setVerifyError('');
    },
    onError: (error: any) => {
      if (error.length > 0) {
        setVerifyError(returnValidateErrorMessages(error[0], fieldToText(error[0].field)));
      } else {
        setVerifyError('Có lỗi xảy ra, vui lòng thử lại');
      }
    }
  });

  const { mutate: updateInfo, isLoading: loadingUpdate } = useMutation('updateInfo', (data: ProfileParams) => updateProfileService(data), {
    onSuccess: () => {
      dispatch(getProfileAction());
      setIsOpenModal(true);
    },
    onError: (error: any) => {
      if (error.length > 0) {
        error.forEach((err: ValidateError) => {
          mainMethod.setError(
            err.field,
            { message: returnValidateErrorMessages(err, fieldToText(err.field)) }
          );
        });
      }
    }
  });

  const hanldeRequestOtp = (data: RegisterOtpForm, cb?: () => void) => {
    requestOtp({
      params: {
        phone: data.phone,
      },
      cb
    });
  };

  const handleVerifyOtp = (data: VerifyOtpParams) => {
    verifyOtpMutate({
      phone: data.phone,
      otp: data.otp
    });
  };

  const handleRegister = async (data: UserInfoTypes, isUpdate?: boolean) => {
    if (!isUpdate) {
      setIsOpenModal(true);
      return;
    }
    const result = await mainMethod.trigger();
    if (!result) {
      return;
    }
    if (isUpdate) {
      updateInfo({
        ...data,
        dob: formatDateYYYYMMDD(data.dob),
        provinceCode: data.provinceCode?.value || '',
      });
    }
  };

  const handleResendOtp = useCallback(() => {
    const { phone } = verifyOtpMethod.getValues();
    requestOtp({
      params: {
        phone,
      }
    });
  }, [requestOtp, verifyOtpMethod]);

  useEffect(() => {
    if ((profile && !isStartApp) || !!missingProfile) {
      setStep(2);
    }
  }, [isStartApp, profile, missingProfile]);

  useEffect(() => {
    if (luckyDrawClosed) {
      navigate(ROUTE_PATH.HOME + window.location.search);
    }
  }, [luckyDrawClosed, navigate]);

  return (
    <MainLayout
      noBg
      bgColor="darkGreen"
    >
      <div className="p-register">
        {systemLoading && <LoadingFullScreen />}
        <Header isLogoPresent />
        <div
          className="p-register_content"
          style={{
            paddingBottom: `${step ? '140' : ''}px`,
            height: `${step ? 'unset' : `${height}px`}`
          }}
        >
          <div className="p-register_content_subHeading">
            <Typography.Text
              extendClasses="fs-16x20"
              textStyle="center"
            >
              Một sân chơi chỉ có thể là Heineken!
            </Typography.Text>
          </div>
          <div className="p-register_content_description">
            {step ? (
              <Typography.Text
                extendClasses="fs-16x20 color-white"
                textStyle="center"
              >
                Chương trình sẽ diễn ra xuyên suốt từ
                <br />
                <span style={{ color: '#33ff6c' }}>ngày 9 đến 10/5/2024</span>
                <br />
                Hãy chắc chắn bạn có thể tham gia ghi
                <br />
                hình trước khi đăng ký nhé!
              </Typography.Text>
            ) : (
              <Typography.Text
                extendClasses="fs-16x20 color-white"
                textStyle="center"
              >
                Thử thách cam go đến đâu, giải thưởng to
                <br />
                đến đấy. Còn chần chờ gì nữa, mời Fan
                <br />
                đích thực chào sân ngay!
              </Typography.Text>
            )}
          </div>
          {(() => {
            switch (step) {
              case 1:
                return (
                  <Step1
                    timeout={requestOtpData ? requestOtpData.retryAtSeconds : 0}
                    isLoadingOtp={isLoading}
                    method={verifyOtpMethod}
                    mainMethod={mainMethod}
                    isLoadingVerify={verifyLoading}
                    isDisableForm={isDisableForm}
                    verifyError={verifyError}
                    hanldeRequestOtp={hanldeRequestOtp}
                    handleVerifyOtp={handleVerifyOtp}
                    handleResend={handleResendOtp}
                    handleRegister={handleRegister}
                  />
                );
              case 2:
                return (
                  <Step2
                    method={mainMethod}
                    handleRegister={handleRegister}
                    loadingRegister={loadingUpdate}
                  />
                );
              default:
                return null;
            }
          })()}
          {!step && (
            <div className="p-register_content_button">
              <ButtonCustom onClick={() => setStep(profile?.profile ? 2 : 1)}>
                ĐĂNG KÝ LIỀN
              </ButtonCustom>
            </div>
          )}
        </div>
        <div className="p-register_footer">
          <Footer hasBackground />
        </div>
      </div>
      <CustomModal isOpen={isOpenModal}>
        <Typography.Text
          textStyle="center"
          extendClasses="color-white p-register_modal_heading"
          fontweight="900"
        >
          Ghi danh
          <br />
          thành công
        </Typography.Text>
        <Typography.Text
          textStyle="center"
          extendClasses="fs-16x20 color-white p-register_modal_text"
        >
          Bạn đã đăng ký ghi danh thành công
          <br />
          chương trình
          {' '}
          <span>
            KEN LEAGUE - 24H truy
            <br />
            tìm Fan đích thực!
          </span>
          <br />
          Kết quả sẽ được thông báo đến bạn vào
          <br />
          ngày 06/05/2024.
          <br />
          Số lượng đăng ký có hạn.
          <br />
          <br />
          Trong lúc chờ kết quả, mời Fan trúng
          <br />
          ngay quà đỉnh với
          {' '}
          <span>“Vòng Xoay may mắn”</span>
          <br />
          này nhé!
        </Typography.Text>
        <div className="p-register_modal_button">
          <ButtonCustom onClick={() => {
            trackingEvent({
              action: 'click',
              category: 'ken-game',
              label: 'join-lucky-draw'
            });
            navigate(ROUTE_PATH.LUCKY_WHEEL);
            setIsOpenModal(false);
          }}
          >
            xoay liền!
          </ButtonCustom>
        </div>
      </CustomModal>
    </MainLayout>
  );
};

export default Register;
