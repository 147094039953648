/* eslint-disable no-nested-ternary */
import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import {
  Controller, FormProvider, useForm, useWatch
} from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select/dist/declarations/src/Select';
import { Typography } from 'tfc-components';

import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import Pulldown from 'components/molecules/Pulldown';
import Header from 'components/organisms/Header';
import MainLayout from 'components/organisms/MainLayout';
import CustomModal from 'components/organisms/Modal';
import ModalSystemError from 'components/templates/ModalSystemError';
import useAddressOptions from 'hooks/useAddressOptions';
import useDidMount from 'hooks/useDidMount';
import HelmetComponent from 'pages/helmet';
import { updateProfileService } from 'services/auth';
import { ProfileParams } from 'services/auth/types';
import returnValidateErrorMessages from 'services/common/errors';
import { getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ROUTE_PATH } from 'utils/constants';
import { formatDateDDMMYYYY } from 'utils/functions';
import useSchemas from 'utils/schemas';
import SEO_DATA from 'utils/seoData';
import { trackingEvent } from 'utils/trackingGA';

export interface UserInfoFormTypes {
  name?: string;
  idNumber: string;
  address: string;
  provinceCode: OptionType;
  districtCode: OptionType;
  fromGame?: boolean,
}
const fieldToText = (field: string) => {
  switch (field) {
    case 'dob':
      return 'Ngày sinh';
    case 'name':
      return 'Họ và tên';
    case 'idNumber':
      return 'CMND/CCCD';
    case 'provinceCode':
      return 'Tỉnh/Thành phố';
    case 'districtCode':
      return 'Quận/Huyện';
    case 'address':
      return 'Địa chỉ';
    default:
      return '';
  }
};

const UserInfoUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const profile = useAppSelector((state) => state.auth.profile);
  const { userInfo } = useSchemas();
  const [searchParams] = useSearchParams();
  const fromGame = searchParams.get('fromGame');
  const fromProfile = searchParams.get('profile');
  const [errSystem, setErrSystem] = React.useState(false);
  const method = useForm<UserInfoFormTypes>({
    mode: 'onChange',
    resolver: yupResolver(userInfo),
    defaultValues: {
      name: '',
      idNumber: undefined,
      address: '',
    },
  });
  const { isDirty } = method.formState;

  const districtRef = useRef<Select>(null);
  const readonly = useMemo(() => !!profile && !!profile.profile
    && profile.profile.updatedCount === 1, [profile]);
  const provinceWatch = useWatch({ name: 'provinceCode', control: method.control });
  const districtWatch = useWatch({ name: 'districtCode', control: method.control });
  const {
    provinces, districts, isLoadingProvinces, isLoadingDistricts
  } = useAddressOptions(provinceWatch?.value);

  const { mutate: updateInfo, isLoading } = useMutation('updateInfo', (data: { params: ProfileParams, fromGame: boolean }) => updateProfileService(data.params), {
    onSuccess: (_, variables) => {
      if (variables.fromGame) {
        navigate(ROUTE_PATH.MY_GIFT);
      }
      dispatch(getProfileAction());
      setIsOpenModal(false);
    },
    onError: (error: any) => {
      if (error.length > 0) {
        error.forEach((err: ValidateError) => {
          method.setError(
            err.field,
            { message: returnValidateErrorMessages(err, fieldToText(err.field)) }
          );
        });
      } else {
        setErrSystem(true);
      }
      setIsOpenModal(false);
    }
  });

  const onSubmit = async (data: UserInfoFormTypes) => {
    const valid = await method.trigger();
    if (valid) {
      const {
        provinceCode, districtCode, ...rest
      } = data;

      updateInfo({
        params: {
          ...rest,
          provinceCode: provinceCode?.value,
          districtCode: districtCode?.value,
          idNumber: data.idNumber?.toString(),
          disabledIncrease: !fromGame ? false : undefined
        },
        fromGame: !!fromGame || false,
      });
    }
  };
  useEffect(() => {
    if (profile && profile?.profile) {
      method.reset({
        name: profile?.profile?.name || '',
        idNumber: String(profile?.profile?.idNumber || ''),
        address: profile?.profile?.address || '',
        provinceCode: profile?.profile?.province ? {
          value: profile?.profile?.province?.code,
          label: profile?.profile?.province?.name
        } : undefined,
        districtCode: profile?.profile?.district ? {
          value: profile?.profile?.district.code,
          label: profile?.profile?.district.name
        } : undefined,
        fromGame: !!fromGame || false,
      });
    }
  }, [fromGame, method, profile]);

  useDidMount(() => {
    if (fromGame) {
      setIsEdit(true);
    }
  });

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.UPDATE_USER_INFO} />
      <MainLayout>
        <Header isBack={!!fromProfile} isProfile />
        <div className="p-login_info">
          <div className="p-login_title">
            <Typography.Text fontweight="900" textStyle="center">
              Thông tin cá nhân
            </Typography.Text>
          </div>
          {profile?.profile !== null
            ? profile?.profile?.updatedCount === 0
              ? (
                null
              ) : (
                <div className="p-userUpdate_updated">
                  <Typography.Text
                    fontweight="700"
                    textStyle="center"
                    extendClasses="p-userUpdate_updatedText fs-16x20"
                  >
                    Hệ thống đã ghi nhận thông tin của bạn
                  </Typography.Text>
                  <Typography.Text
                    textStyle="center"
                    extendClasses="color-white"
                  >
                    {`Bạn đã cập nhật thông tin cá nhân vào ngày
                  ${formatDateDDMMYYYY(profile?.profile?.updatedAt)} rồi, nên không thể chỉnh sửa nữa`}
                  </Typography.Text>
                </div>
              )
            : ''}
          {profile?.profile ? (
            isEdit ? (
              <div className="p-login_info_form">
                <FormProvider {...method}>
                  {(!fromGame || !profile.profile.name) && (
                    <Controller
                      name="name"
                      control={method.control}
                      render={({ field, fieldState: { error } }) => (
                        <div className="p-login_info_field">
                          <Input
                            {...field}
                            readOnly={readonly}
                            required
                            placeholder="Họ và tên"
                            onClick={() => {
                              trackingEvent({
                                action: 'click',
                                category: 'info',
                                label: 'name'
                              });
                            }}
                          />
                          {
                            error ? (
                              <div className="o-otp_error">
                                <Icon iconName="warning" size="12" />
                                <Typography.Text
                                  extendClasses="fs-16x20 o-otp_error-text"
                                  textStyle="center"
                                  fontweight="600"
                                >
                                  {error.message}
                                </Typography.Text>
                              </div>
                            ) : null
                          }
                        </div>
                      )}
                    />
                  )}
                  <Controller
                    name="idNumber"
                    control={method.control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="p-login_info_field" style={{ zIndex: 4 }}>
                        <Input
                          {...field}
                          required
                          placeholder="CMND/CCCD"
                          maxLength={12}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value.length <= 12) {
                              field.onChange(value);
                            }
                          }}
                          onClick={() => {
                            trackingEvent({
                              action: 'click',
                              category: 'info',
                              label: 'id'
                            });
                          }}
                        />
                        {
                          error ? (
                            <div className="o-otp_error">
                              <Icon iconName="warning" size="12" />
                              <Typography.Text
                                extendClasses="fs-16x20 o-otp_error-text"
                                textStyle="center"
                                fontweight="600"
                              >
                                {error.message}
                              </Typography.Text>
                            </div>
                          ) : null
                        }
                      </div>
                    )}
                  />
                  <Controller
                    name="provinceCode"
                    control={method.control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="p-login_info_field" style={{ zIndex: 3 }}>
                        <Pulldown
                          {...field}
                          required
                          placeholder="Tỉnh/Thành phố"
                          name="province"
                          isDisabled={!!fromGame && !!profile?.profile?.province}
                          loading={isLoadingProvinces}
                          options={provinces}
                          handleChange={(option) => {
                            if (districtWatch) {
                              districtRef.current?.clearValue();
                            }
                            field.onChange(option);
                          }}
                          onMenuOpen={() => {
                            trackingEvent({
                              action: 'click',
                              category: 'info',
                              label: 'city'
                            });
                          }}
                        />
                        {
                          error ? (
                            <div className="o-otp_error">
                              <Icon iconName="warning" size="12" />
                              <Typography.Text
                                extendClasses="fs-16x20 o-otp_error-text"
                                textStyle="center"
                                fontweight="600"
                              >
                                {error.message}
                              </Typography.Text>
                            </div>
                          ) : null
                        }
                      </div>
                    )}
                  />
                  <Controller
                    name="districtCode"
                    control={method.control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="p-login_info_field" style={{ zIndex: 2 }}>
                        <Pulldown
                          {...field}
                          required
                          ref={districtRef}
                          placeholder="Quận/Huyện"
                          name="district"
                          loading={isLoadingDistricts}
                          options={districts}
                          handleChange={field.onChange}
                          onMenuOpen={() => {
                            trackingEvent({
                              action: 'click',
                              category: 'info',
                              label: 'province'
                            });
                          }}
                        />
                        {
                          error ? (
                            <div className="o-otp_error">
                              <Icon iconName="warning" size="12" />
                              <Typography.Text
                                extendClasses="fs-16x20 o-otp_error-text"
                                textStyle="center"
                                fontweight="600"
                              >
                                {error.message}
                              </Typography.Text>
                            </div>
                          ) : null
                        }
                      </div>
                    )}
                  />
                  <Controller
                    name="address"
                    control={method.control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="p-login_info_field" style={{ zIndex: 1 }}>
                        <Input
                          {...field}
                          required
                          placeholder="Số nhà, đường"
                          onClick={() => {
                            trackingEvent({
                              action: 'click',
                              category: 'info',
                              label: 'address'
                            });
                          }}
                        />
                        {
                          error ? (
                            <div className="o-otp_error">
                              <Icon iconName="warning" size="12" />
                              <Typography.Text
                                extendClasses="fs-16x20 o-otp_error-text"
                                textStyle="center"
                                fontweight="600"
                              >
                                {error.message}
                              </Typography.Text>
                            </div>
                          ) : null
                        }
                      </div>
                    )}
                  />
                </FormProvider>
              </div>
            ) : (
              <div className="p-userUpdate_infoTable">
                <div className="p-userUpdate_infoTable_item">
                  <Typography.Text
                    fontweight="700"
                    extendClasses="p-userUpdate_infoTable_info"
                  >
                    Họ và tên:
                    {' '}
                  </Typography.Text>
                  <Typography.Text
                    fontweight="900"
                    extendClasses="color-white"
                  >
                    {profile?.profile?.name || ''}
                  </Typography.Text>
                </div>
                {/* <div className="p-userUpdate_infoTable_item">
                <Typography.Text
                  fontweight="700"
                  extendClasses="p-userUpdate_infoTable_info"
                >
                  Ngày sinh:
                  {' '}
                </Typography.Text>
                <Typography.Text
                  fontweight="900"
                  extendClasses="color-white"
                >
                  {formatDateDDMMYYYY(profile?.profile?.dob)}
                </Typography.Text>
              </div> */}
                <div className="p-userUpdate_infoTable_item">
                  <Typography.Text
                    fontweight="700"
                    extendClasses="p-userUpdate_infoTable_info"
                  >
                    CMND/CCCD:
                    {' '}
                  </Typography.Text>
                  <Typography.Text
                    fontweight="900"
                    extendClasses="color-white"
                  >
                    {profile?.profile?.idNumber || ''}
                  </Typography.Text>
                </div>
                <div className="p-userUpdate_infoTable_item">
                  <Typography.Text
                    fontweight="700"
                    extendClasses="p-userUpdate_infoTable_info"
                  >
                    Tỉnh/ Thành phố:
                    {' '}
                  </Typography.Text>
                  <Typography.Text
                    fontweight="900"
                    extendClasses="color-white"
                  >
                    {profile?.profile?.province?.name || ''}
                  </Typography.Text>
                </div>
                <div className="p-userUpdate_infoTable_item">
                  <Typography.Text
                    fontweight="700"
                    extendClasses="p-userUpdate_infoTable_info"
                  >
                    Quận/ Huyện:
                    {' '}
                  </Typography.Text>
                  <Typography.Text
                    fontweight="900"
                    extendClasses="color-white"
                  >
                    {profile?.profile?.district?.name || ''}
                  </Typography.Text>
                </div>
                <div className="p-userUpdate_infoTable_item">
                  <Typography.Text
                    fontweight="700"
                    extendClasses="p-userUpdate_infoTable_info"
                  >
                    Số nhà, đường:
                    {' '}
                  </Typography.Text>
                  <Typography.Text
                    fontweight="900"
                    extendClasses="color-white"
                  >
                    {profile?.profile?.address || ''}
                  </Typography.Text>
                </div>
              </div>
            )
          ) : (
            <div className="p-login_info_form">
              <FormProvider {...method}>
                {(!fromGame || !profile?.profile?.name) && (
                  <Controller
                    name="name"
                    control={method.control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="p-login_info_field" style={{ zIndex: 6 }}>
                        <Input
                          {...field}
                          readOnly={readonly}
                          required
                          placeholder="Họ và tên"
                          onClick={() => {
                            trackingEvent({
                              action: 'click',
                              category: 'info',
                              label: 'name'
                            });
                          }}
                        />
                        {
                          error ? (
                            <div className="o-otp_error">
                              <Icon iconName="warning" size="12" />
                              <Typography.Text
                                extendClasses="fs-16x20 o-otp_error-text"
                                textStyle="center"
                                fontweight="600"
                              >
                                {error.message}
                              </Typography.Text>
                            </div>
                          ) : null
                        }
                      </div>
                    )}
                  />
                )}
                <Controller
                  name="idNumber"
                  control={method.control}
                  render={({ field, fieldState: { error } }) => (
                    <div className="p-login_info_field" style={{ zIndex: 4 }}>
                      <Input
                        {...field}
                        required
                        placeholder="CMND/CCCD"
                        maxLength={12}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.length <= 12) {
                            field.onChange(value);
                          }
                        }}
                        onClick={() => {
                          trackingEvent({
                            action: 'click',
                            category: 'info',
                            label: 'id'
                          });
                        }}
                      />
                      {
                        error ? (
                          <div className="o-otp_error">
                            <Icon iconName="warning" size="12" />
                            <Typography.Text
                              extendClasses="fs-16x20 o-otp_error-text"
                              textStyle="center"
                              fontweight="600"
                            >
                              {error.message}
                            </Typography.Text>
                          </div>
                        ) : null
                      }
                    </div>
                  )}
                />
                <Controller
                  name="provinceCode"
                  control={method.control}
                  render={({ field, fieldState: { error } }) => (
                    <div className="p-login_info_field" style={{ zIndex: 3 }}>
                      <Pulldown
                        {...field}
                        required
                        placeholder="Tỉnh/Thành phố"
                        name="province"
                        loading={isLoadingProvinces}
                        options={provinces}
                        handleChange={(option) => {
                          if (districtWatch) {
                            districtRef.current?.clearValue();
                          }
                          field.onChange(option);
                        }}
                        onMenuOpen={() => {
                          trackingEvent({
                            action: 'click',
                            category: 'info',
                            label: 'city'
                          });
                        }}
                      />
                      {
                        error ? (
                          <div className="o-otp_error">
                            <Icon iconName="warning" size="12" />
                            <Typography.Text
                              extendClasses="fs-16x20 o-otp_error-text"
                              textStyle="center"
                              fontweight="600"
                            >
                              {error.message}
                            </Typography.Text>
                          </div>
                        ) : null
                      }
                    </div>
                  )}
                />
                <Controller
                  name="districtCode"
                  control={method.control}
                  render={({ field, fieldState: { error } }) => (
                    <div className="p-login_info_field" style={{ zIndex: 2 }}>
                      <Pulldown
                        {...field}
                        required
                        ref={districtRef}
                        placeholder="Quận/Huyện"
                        name="district"
                        loading={isLoadingDistricts}
                        options={districts}
                        handleChange={field.onChange}
                        onMenuOpen={() => {
                          trackingEvent({
                            action: 'click',
                            category: 'info',
                            label: 'province'
                          });
                        }}
                      />
                      {
                        error ? (
                          <div className="o-otp_error">
                            <Icon iconName="warning" size="12" />
                            <Typography.Text
                              extendClasses="fs-16x20 o-otp_error-text"
                              textStyle="center"
                              fontweight="600"
                            >
                              {error.message}
                            </Typography.Text>
                          </div>
                        ) : null
                      }
                    </div>
                  )}
                />
                <Controller
                  name="address"
                  control={method.control}
                  render={({ field, fieldState: { error } }) => (
                    <div className="p-login_info_field" style={{ zIndex: 1 }}>
                      <Input
                        {...field}
                        required
                        placeholder="Số nhà, đường"
                        onClick={() => {
                          trackingEvent({
                            action: 'click',
                            category: 'info',
                            label: 'address'
                          });
                        }}
                      />
                      {
                        error ? (
                          <div className="o-otp_error">
                            <Icon iconName="warning" size="12" />
                            <Typography.Text
                              extendClasses="fs-16x20 o-otp_error-text"
                              textStyle="center"
                              fontweight="600"
                            >
                              {error.message}
                            </Typography.Text>
                          </div>
                        ) : null
                      }
                    </div>
                  )}
                />
              </FormProvider>
            </div>
          )}
          <div className="p-userUpdate_button">
            {
              !fromGame && !profile?.profile && (
                <ButtonCustom
                  onClick={() => {
                    trackingEvent({
                      action: 'click',
                      category: 'info',
                      label: 'skip-register'
                    });
                    navigate(ROUTE_PATH.HOME);
                  }}
                >
                  Bỏ qua
                </ButtonCustom>
              )
            }
            {!profile?.profile ? (
              <ButtonCustom
                onClick={() => {
                  trackingEvent({
                    action: 'click',
                    category: 'info',
                    label: 'submit'
                  });
                  onSubmit(method.getValues());
                }}
                loading={isLoading}
              >
                Cập nhật ngay
              </ButtonCustom>
            ) : profile?.profile && profile?.profile?.updatedCount === 0 ? (
              <div className="p-userUpdate_buttonGroup">
                <ButtonCustom
                  loading={isLoading}
                  disabled={!!(!isDirty && isEdit)}
                  onClick={async () => {
                    if (isEdit) {
                      const isValid = await method.trigger();
                      if (isValid) {
                        trackingEvent({
                          action: 'click',
                          category: 'info',
                          label: 'cf-2nd-submit'
                        });
                        onSubmit(method.getValues());
                      }
                    } else {
                      trackingEvent({
                        action: 'click',
                        category: 'info',
                        label: 'edit-info'
                      });
                      setIsEdit(true);
                    }
                  }}
                >
                  {isEdit ? 'Cập nhật ngay' : 'Chỉnh sửa'}
                </ButtonCustom>
              </div>
            ) : null}
            {profile?.profile !== null && profile?.profile?.updatedCount !== 0 ? (
              <div className="p-userUpdate_button-cannotEdit">
                <ButtonCustom onClick={() => {
                  trackingEvent({
                    action: 'click',
                    category: 'info',
                    label: 'view-my-gifts'
                  });
                  navigate(ROUTE_PATH.MY_GIFT);
                }}
                >
                  Xem quà
                </ButtonCustom>
                <ButtonCustom onClick={() => {
                  trackingEvent({
                    action: 'click',
                    category: 'info',
                    label: 'scan-qr'
                  });
                  navigate(ROUTE_PATH.CHALLENGE);
                }}
                >
                  Quét mã QR mới
                </ButtonCustom>
              </div>
            ) : null}
          </div>
        </div>
        <CustomModal
          isOpen={isOpenModal}
          handleClose={() => setIsOpenModal(false)}
        >
          <Typography.Heading
            textStyle="center"
            extendClasses="color-white fs-20x26 p-userUpdate_headingModal"
            fontweight="900"
          >
            BẠN CHỈ ĐƯỢC
            {' '}
            <br />
            {' '}
            CẬP NHẬT THÔNG TIN
            {' '}
            <br />
            {' '}
            CÁ NHÂN 1 LẦN THÔI
          </Typography.Heading>
          <Typography.Text
            textStyle="center"
            extendClasses="color-white fs-16x20 p-userUpdate_warning"
            fontweight="600"
          >
            Một click là không sửa lại đâu đó.
          </Typography.Text>
          <Typography.Text
            textStyle="center"
            extendClasses="color-white fs-16x20"
          >
            Bạn có muốn quay lại kiểm tra
            {' '}
            <br />
            {' '}
            thông tin lần nữa không?
          </Typography.Text>
          <div className="p-userUpdate_really">
            <ButtonCustom
              loading={isLoading}
              onClick={() => {
                // trackingEvent({
                //   action: 'click',
                //   category: 'info',
                //   label: 'cf-2nd-submit'
                // });
                onSubmit(method.getValues());
              }}
            >
              chắc chắn lắm rồi
            </ButtonCustom>
          </div>
          <div className="p-userUpdate_cancel">
            <ButtonCustom onClick={() => {
              trackingEvent({
                action: 'click',
                category: 'info',
                label: 're-check-update'
              });
              setIsOpenModal(false);
            }}
            >
              cho kiểm tra lại nhé
            </ButtonCustom>
          </div>
        </CustomModal>
        <ModalSystemError
          isOpen={errSystem}
          handleClose={() => {
            setErrSystem(false);
            window.location.reload();
          }}
        />
      </MainLayout>
    </>
  );
};

export default UserInfoUpdate;
