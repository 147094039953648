import {
  GiftForLuckyDraw, LuckyDrawCheckData, LuckyDrawData, LuckyDrawTrackingParams
} from './types';

import axiosInstance from 'services/common/instance';

export const getGiftsForLuckyDraw = async (): Promise<GiftForLuckyDraw> => {
  const res = await axiosInstance.get('systems/gifts-for-lucky-draw');
  return res.data.data;
};

export const drawService = async (data?: LuckyDrawTrackingParams): Promise<LuckyDrawData> => {
  const res = await axiosInstance.post('lucky-draw', data);
  return res.data.data;
};

export const checkDrawService = async (): Promise<LuckyDrawCheckData> => {
  const res = await axiosInstance.post('lucky-draw/check');
  return res.data.data;
};
