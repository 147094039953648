import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import { ROUTE_PATH } from 'utils/constants';

interface ProtectedRouteProps {
  children?: any;
  path: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  path
}) => {
  const { profile, loading } = useAppSelector((state) => state.auth);

  if (loading) return null;
  if (!profile) return <Navigate to={ROUTE_PATH.LOGIN} state={{ path }} />;

  return children;
};

export default ProtectedRoute;
