import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { Image, Link, Typography } from 'tfc-components';

import ContactModal from '../ContactModal';
import ModalNotify from '../ModalNotify';

import heneiken from 'assets/images/heineken.png';
import Icon from 'components/atoms/Icon';
import Footer from 'components/organisms/Footer';
import useIsMobile from 'hooks/useDeviceQueries';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleContactModal } from 'store/system';
import { ROUTE_PATH } from 'utils/constants';
import { formatDateHHmmDDMM } from 'utils/functions';

const QrDesktop: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useIsMobile();
  const contactModalOpen = useAppSelector((state) => state.systems.contactModalOpen);
  const system = useAppSelector((state) => state.systems.system);
  const { height } = useWindowDimensions();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('qr');

  const [ended, setEnded] = React.useState(false);

  useEffect(() => {
    if (system?.eventEnded) {
      setEnded(true);
    }
  }, [system]);
  return (
    <div className="main">
      {!isMobile && (
        <div className="t-qrDesktop" style={{ minHeight: `${height}px` }}>
          <div className="t-qrDesktop_content">
            <div className="t-qrDesktop_logo">
              <Image
                ratio={272 / 153}
                imgSrc={heneiken}
                alt="logo"
              />
            </div>
            <div className="t-qrDesktop_sologan">
              <Typography.Text
                extendClasses="fs-48x60 color-white white-space-nowrap"
                textStyle="uppercase"
                fontweight="400"
              >
                NÂNG heineken
              </Typography.Text>
              <Typography.Text
                extendClasses="fs-67x83 color-white"
                textStyle="uppercase"
                fontweight="900"
              >
                vì fan
              </Typography.Text>
              <Typography.Text
                extendClasses="fs-67x83 color-white white-space-nowrap"
                textStyle="uppercase"
                fontweight="900"
              >
                đích thực
              </Typography.Text>
            </div>
            <div className="t-qrDesktop_qr">
              <div className="t-qrDesktop_qr_qrCode">
                <div className="t-qrDesktop_qr_main">
                  <QRCodeSVG
                    value={window.location.href}
                  />
                </div>
              </div>
              <div className="t-qrDesktop_qr_content">
                <Typography.Text fontweight="600">
                  Khung thành với ngàn quà to chỉ có trên phiên bản điện thoại.
                  <br />
                  Mời Fan quét mã, chào sân quà khủng và trổ tài tiền đạo nhé!
                </Typography.Text>
              </div>
            </div>
            <div className="t-qrDesktop_footer">
              <Footer />
            </div>
          </div>
          <div className="t-qrDesktop_legal">
            <div className="t-qrDesktop_legal_left">
              <Link useExternal href="https://www.facebook.com/HeinekenVN" target="_blank">
                <Icon iconName="fb" size="20" />
              </Link>
              <Link useExternal href="https://www.instagram.com/heineken_vn?igsh=NTc4dDJzMnIycThx" target="_blank">
                <Icon iconName="ig" size="20" />
              </Link>
              <div className="t-qrDesktop_legal_line" />
              <Link useExternal href={`${window.location.origin}/tnc.pdf`} target="_blank">
                <Typography.Text fontweight="400">Điều khoản & Điều kiện chung</Typography.Text>
              </Link>
              <div className="t-qrDesktop_legal_line" />
              <Link useExternal href={`${window.location.origin}/dpa.pdf`} target="_blank">
                <Typography.Text fontweight="400">Chính sách bảo mật</Typography.Text>
              </Link>
              <div className="t-qrDesktop_legal_line" />
              <Link onClick={() => dispatch(toggleContactModal())}>
                <Typography.Text fontweight="400">Liên hệ với chúng tôi</Typography.Text>
              </Link>
            </div>
            <div className="t-qrDesktop_legal_right">
              <Typography.Text fontweight="600">
                Công ty TNHH Nhà Máy Bia Heineken Vietnam, Tầng 18 và 19, Toà nhà Vietcombank.
              </Typography.Text>
              <Typography.Text fontweight="400">
                Số 5 Công Trường Mê Linh, Phường Bến Nghé, Quận 1, TP Hồ Chí Minh.
              </Typography.Text>
            </div>
          </div>
        </div>
      )}
      <Outlet />
      <ContactModal open={contactModalOpen} handleClose={() => dispatch(toggleContactModal())} />
      <ModalNotify
        isOpen={ended}
        handleClose={() => {
          setEnded(false);
          if (code || window.location.pathname === '/game/hit-the-target') {
            navigate(ROUTE_PATH.HOME);
          }
        }}
      >
        <Typography.Text
          textStyle="center"
          fontweight="900"
          extendClasses="color-white t-qrDesktop_modal_text"
        >
          CHƯƠNG TRÌNH KHUYẾN MÃI
          <br />
          QUÉT MÃ QR ĐÃ KẾT THÚC
          <br />
          VÀO
          {' '}
          {formatDateHHmmDDMM(system?.eventEndAt || '')}
        </Typography.Text>
      </ModalNotify>
    </div>
  );
};

export default QrDesktop;
