export const COLORS = {
  white: '#ffffff',
  deepGreen: '#007119'
};

export const LOCAL_STORAGE = {
  AGE_GATE: 'UCL_AGE_GATE',
  DEVICE_ID: 'UCL_DEVICE_ID',
  QRCODE: 'UCL_QRCODE',
  PASSED_QRCODE: 'UCL_PASSED_QRCODE',
  TOKEN: 'UCL_TOKEN',
  REFRESH_TOKEN: 'UCL_REFRESH_TOKEN',
  INNERHEIGHT: 'UCL_INNERHEIGHT',
  TRACKING_URL: 'UCL_TRACKING_URL',
  TPLAYINGTIME: 'UCL_TPLAYINGTIME',
  FANCARD_UUID: 'UCL_FANCARD_UUID'
};

export const ROUTE_PATH = {
  VALIDATE_QR_CODE: '/',
  HOME: '/',
  AGE_GATE_REJECTED: '/under-18',
  CHALLENGE: '/challenge',
  PROFILE: '/profile',
  LOGIN: '/login',
  UPDATE_USER_INFO: '/profile/update',
  GOT_GIFT: '/result',
  SCAN_QR: '/scan-qr',
  MY_GIFT: '/profile/gift',
  GAME: '/game/hit-the-target',
  UPDATE_PHONE: '/fpt-play',
  GIFT: '/gift',
  TNC: '/tnc',
  NEWS: '/news',
  REGISTER: '/register',
  LUCKY_WHEEL: '/lucky-wheel',
  LUCKY_GOT_GIFT: '/lucky-got-gift',
  FAN_CARD: '/fan-card',
  GAMESHOW: '/gameshow',
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;
