/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Link, Typography } from 'tfc-components';

import doubleArrow from 'assets/images/doubleArrow.png';
import time from 'assets/images/gameshow-time.png';
import participants from 'assets/images/participants.png';
import waiting from 'assets/images/pre-waiting.png';
import realBanner2 from 'assets/images/realBanner2.png';
import tottiGroup from 'assets/images/tottiGroup.png';
import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import Footer from 'components/organisms/Footer';
import Header from 'components/organisms/Header';
import ModalNotify from 'components/templates/ModalNotify';
import useIsMobile from 'hooks/useDeviceQueries';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { useAppSelector } from 'store/hooks';
import { ROUTE_PATH } from 'utils/constants';
import { trackingEvent } from 'utils/trackingGA';

const Gameshow2: React.FC = () => {
  const navigate = useNavigate();
  const profile = useAppSelector((state) => state.auth.profile);
  // const luckyDrawClosed = useAppSelector((
  //   state
  // ) => state.systems.system?.luckyDrawConfig.closed);
  const systemLoading = useAppSelector((state) => state.systems.systemLoading);
  const { width } = useWindowDimensions();
  const { isMobile } = useIsMobile();
  const [openModal, setOpenModal] = React.useState(false);

  const paddingTopVal = width <= 320 ? '110%' : '109.2%';
  // useEffect(() => {
  //   if (luckyDrawClosed) {
  //     navigate(ROUTE_PATH.HOME + window.location.search);
  //   }
  // }, [luckyDrawClosed, navigate]);
  return (
    <div className="p-gameshow2">
      {systemLoading && <LoadingFullScreen />}
      <Header isBack isLogoPresent />
      <div
        className="p-gameshow2_heading"
        style={{
          paddingTop: `${isMobile ? `calc(${paddingTopVal} - 80px)` : ''}`
        }}
      >
        <Typography.Text
          extendClasses="fs-16x20"
          textStyle="center"
        >
          Chương trình đã đóng đơn đăng ký
          <br />
          vào ngày 28.04 mất rồi.
          <br />
          MỜI BẠN TIẾP TỤC CHƯƠNG TRÌNH VÀO
        </Typography.Text>
      </div>
      <div className="p-gameshow2_waiting">
        <Image
          imgSrc={waiting}
          ratio={668 / 76}
          alt="banner"
        />
      </div>
      {/* <div className="p-gameshow2_attendButton">
        <ButtonCustom onClick={() => {
          trackingEvent({
            action: 'click',
            category: 'ken-game',
            label: 'game-1'
          });
          if (profile && profile.account.luckyDrawCount > 0) {
            setOpenModal(true);
          } else {
            navigate(ROUTE_PATH.REGISTER);
          }
        }}
        >
          tham gia ngay
        </ButtonCustom>
      </div> */}
      <div className="p-gameshow2_arrowDown">
        <Image
          imgSrc={doubleArrow}
          ratio={53 / 58}
          alt="arrow"
        />
      </div>
      <div className="p-gameshow2_realBanner">
        <Image
          imgSrc={realBanner2}
          ratio={698 / 750}
          alt="banner"
        />
      </div>
      <div className="p-gameshow2_tottiImg">
        <Image
          imgSrc={tottiGroup}
          ratio={750 / 492}
          alt="banner"
        />
      </div>
      <div className="p-gameshow2_starName">
        <Typography.Text>Cùng 7 KOL hàng đầu Việt Nam</Typography.Text>
      </div>
      <div className="p-gameshow2_participants">
        <Image
          imgSrc={participants}
          ratio={750 / 842}
          alt="banner"
        />
      </div>
      <div className="p-gameshow2_time">
        <Image
          imgSrc={time}
          ratio={752 / 316}
          alt="time"
        />
      </div>
      <div className="p-gameshow2_buttonGroup">
        {/* <div className="p-gameshow2_attendButton2">
          <ButtonCustom onClick={() => {
            trackingEvent({
              action: 'click',
              category: 'ken-game',
              label: 'game-2'
            });
            if (profile && profile.account.luckyDrawCount > 0) {
              setOpenModal(true);
            } else {
              navigate(ROUTE_PATH.REGISTER);
            }
          }}
          >
            Tham gia để có cơ hội nhận quà Ken League
          </ButtonCustom>
        </div>
        <div className="p-gameshow2_attendText">
          <Link
            href={`${ROUTE_PATH.GIFT}?lucky=true`}
            onClick={() => {
              trackingEvent({
                action: 'click',
                category: 'ken-game',
                label: 'btn-gift'
              });
            }}
          >
            <div className="p-gameshow2_detail">
              <Typography.Text
                extendClasses="fs-16x20 p-gameshow2_detailText"
                fontweight="500"
              >
                Khám phá quà ngay
              </Typography.Text>
              <div className="p-giftTotal_icon">
                <Icon iconName="right" size="12" />
              </div>
            </div>
          </Link>
        </div> */}
      </div>
      <div className="p-gameshow2_footer">
        <Footer hasBackground />
      </div>
      <ModalNotify
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
      >
        <Typography.Text
          textStyle="center"
          extendClasses="color-white fs-20x26 t-modalNotify_message"
          fontweight="900"
        >
          Bạn đã hết lượt quay
        </Typography.Text>
      </ModalNotify>
    </div>
  );
};

export default Gameshow2;
