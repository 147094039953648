import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { ROUTE_PATH } from 'utils/constants';

const RedirectToPage: React.FC = () => {
  const { code } = useParams();

  return <Navigate to={`${ROUTE_PATH.VALIDATE_QR_CODE}?qr=${code}&utm_source=Packaging&utm_medium=QRCode&utm_campaign=Heineken-UCL`} />;
};

export default RedirectToPage;
