/* eslint-disable max-len */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import {
  Controller, FormProvider, useForm
} from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography } from 'tfc-components';

import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import Pulldown from 'components/molecules/Pulldown';
import Header from 'components/organisms/Header';
import MainLayout from 'components/organisms/MainLayout';
import OTP from 'components/organisms/OTP';
import ModalNotify from 'components/templates/ModalNotify';
import ModalSystemError from 'components/templates/ModalSystemError';
import useAddressOptions from 'hooks/useAddressOptions';
import HelmetComponent from 'pages/helmet';
import { requestOtpService, verifyOtpService } from 'services/auth';
import returnValidateErrorMessages from 'services/common/errors';
import { getLocalStorage, setAccessToken, setRefreshToken } from 'services/common/storage';
import { trackingURLService } from 'services/systems';
import { getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LOCAL_STORAGE, ROUTE_PATH } from 'utils/constants';
import mapModifiers from 'utils/functions';
import useSchemas from 'utils/schemas';
import SEO_DATA from 'utils/seoData';
import { trackingEvent } from 'utils/trackingGA';

export type PhoneForm = {
  phone: string;
  name: string;
  provinceCode: OptionType;
};
const fieldToText = (field: string) => {
  switch (field) {
    case 'phone':
      return 'Số điện thoại';
    default:
      return '';
  }
};
const Login: React.FC = () => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loadingProfile = useAppSelector((state) => state.auth.loading);
  const { verifyOtp } = useSchemas();
  const method = useForm<PhoneForm>({
    mode: 'onChange',
    resolver: yupResolver(verifyOtp),
    defaultValues: {
      phone: '',
    },
  });
  const {
    provinces, isLoadingProvinces
  } = useAddressOptions();
  /* SearchParams */
  const [searchParams] = useSearchParams();
  const qrCode = searchParams.get('code');

  /* States */
  const [step, setStep] = React.useState(0);
  const [invalid, setInvalid] = React.useState(false);
  const [verifyError, setVerifyError] = React.useState<string | undefined>();
  const [errSystem, setErrSystem] = React.useState(false);

  /* Mutations */
  const { mutate: trackingMutate } = useMutation('tracking-url', trackingURLService);
  // const { mutate: openGift, isLoading: openGiftLoading } = useMutation('openGift', openGiftService, {
  //   onSuccess: (data) => {
  //     if (data.valid) {
  //       navigate(`${ROUTE_PATH.GOT_GIFT}/${data.rewardId ? data.rewardId : 'none'}`);
  //     } else {
  //       setVerifyError('QRCode không hợp lệ');
  //     }
  //     localStorage.removeItem(LOCAL_STORAGE.QRCODE);
  //   },
  //   onError: () => {
  //     setErrSystem(true);
  //   }
  // });
  // const { mutate: assignAccount, isLoading: assignLoading } = useMutation('assignAccount', soccerAssignAccountService, {
  //   onSuccess: (data) => {
  //     if (data.valid) {
  //       navigate(`${ROUTE_PATH.UPDATE_USER_INFO}?game=true`);
  //       // navigate(`${ROUTE_PATH.GOT_GIFT}/${data.rewardId ? data.rewardId : 'none'}`);
  //     } else {
  //       setVerifyError('QRCode không hợp lệ');
  //     }
  //   },
  //   onError: (error: any) => {
  //     if (error.length > 0) {
  //       setVerifyError(returnValidateErrorMessages(error[0], fieldToText(error[0].field)));
  //     } else {
  //       setErrSystem(true);
  //     }
  //   }
  // });
  // const { mutate: updateInfo, isLoading: updateInfoLoading } = useMutation('updateInfo', updateProfileService, {
  //   onSettled: () => {
  //     dispatch(getProfileAction()).unwrap().finally(() => {
  //       if (qrCode) {
  //         navigate(`${ROUTE_PATH.GAME}?code=${qrCode}`);
  //       } else {
  //         navigate(ROUTE_PATH.UPDATE_USER_INFO);
  //       }
  //     });
  //   }
  // });
  const { mutate: requestOtp, isLoading, data: requestOtpData } = useMutation('requestOtp', requestOtpService, {
    onSuccess: () => {
      setStep((prev) => prev + 1);
    },
    onError: (error: any) => {
      if (error.length > 0) {
        method.setError('phone', { message: returnValidateErrorMessages(error[0], fieldToText(error[0].field)) });
      } else {
        setErrSystem(true);
      }
    }
  });
  const { mutate: verifyOtpMutate, isLoading: verifyLoading } = useMutation('verifyOtp', verifyOtpService, {
    onSuccess: (data) => {
      setAccessToken(data.accessToken);
      setRefreshToken(data.refreshToken);
      const queryParams = getLocalStorage(LOCAL_STORAGE.TRACKING_URL);
      if (queryParams) {
        const parser = JSON.parse(queryParams);
        trackingMutate({ ...parser, others: { ...parser.others, rejectedAgeCheck: String(getLocalStorage(LOCAL_STORAGE.AGE_GATE) === 'false') } });
      }

      // const {
      //   provinceCode, name
      // } = method.getValues();
      // updateInfo({
      //   name,
      //   provinceCode: provinceCode.value,
      // });
      dispatch(getProfileAction()).unwrap().finally(() => {
        if (qrCode) {
          navigate(`${ROUTE_PATH.GAME}?code=${qrCode}`);
        } else {
          navigate(ROUTE_PATH.UPDATE_USER_INFO);
        }
      });
    },
    onError: (error: any) => {
      if (error.length > 0) {
        setVerifyError(returnValidateErrorMessages(error[0], fieldToText(error[0].field)));
      } else {
        setErrSystem(true);
      }
    }
  });

  /* Functions */
  const hanldeRequestOtp = (data: PhoneForm) => {
    trackingEvent({
      action: 'click',
      category: 'login',
      label: 'otp'
    });
    requestOtp({
      phone: data.phone,
      profile: {
        name: data.name,
        provinceCode: data.provinceCode.value
      }
    });
  };

  const handleResendOTP = () => {
    const { phone, name, provinceCode } = method.getValues();
    requestOtp({
      phone,
      profile: {
        name,
        provinceCode: provinceCode.value
      }
    });
  };

  /* Effects */

  useEffect(() => {
    if (step === 0) {
      setVerifyError(undefined);
    }
  }, [step]);

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.LOGIN} />
      <MainLayout noBg={step === 0}>
        <div className={mapModifiers('p-login', step === 0 && 'can')}>
          <Header isBack={step > 0} handleBack={() => setStep((prev) => prev - 1)} />
          {step === 0 ? (
            <div className="p-login_content">
              <div className="p-login_title">
                <Typography.Text fontweight="900">
                  Nhập
                  <br />
                  thông tin
                </Typography.Text>
              </div>
              <FormProvider {...method}>
                <div className="p-login_input">
                  <Controller
                    name="phone"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <Input
                          required
                          value={value}
                          placeholder="Nhập số điện thoại"
                          onChange={onChange}
                          onClick={() => {
                            trackingEvent({
                              action: 'click',
                              category: 'login',
                              label: 'phone'
                            });
                          }}
                        />
                        {
                          error ? (
                            <div className="o-otp_error">
                              <Icon iconName="warning" size="12" />
                              <Typography.Text
                                extendClasses="fs-16x20 o-otp_error-text"
                                textStyle="center"
                                fontweight="600"
                              >
                                {error.message}
                              </Typography.Text>
                            </div>
                          ) : null
                        }
                      </>
                    )}
                  />
                </div>
                <div className="p-login_input">
                  <Controller
                    name="name"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <Input
                          required
                          value={value}
                          placeholder="Họ và tên"
                          onChange={onChange}
                          onClick={() => {
                            trackingEvent({
                              action: 'click',
                              category: 'login',
                              label: 'full-name'
                            });
                          }}
                        />
                        {
                          error ? (
                            <div className="o-otp_error">
                              <Icon iconName="warning" size="12" />
                              <Typography.Text
                                extendClasses="fs-16x20 o-otp_error-text"
                                textStyle="center"
                                fontweight="600"
                              >
                                {error.message}
                              </Typography.Text>
                            </div>
                          ) : null
                        }
                      </>
                    )}
                  />
                </div>
                <div className="p-login_input">
                  <Controller
                    name="provinceCode"
                    control={method.control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="p-login_info_field" style={{ zIndex: 2 }}>
                        <Pulldown
                          {...field}
                          required
                          placeholder="Tỉnh/Thành phố"
                          name="province"
                          loading={isLoadingProvinces}
                          options={provinces}
                          isBottom
                          handleChange={field.onChange}
                          onMenuOpen={() => {
                            trackingEvent({
                              action: 'click',
                              category: 'login',
                              label: 'city'
                            });
                          }}
                        />
                        {
                          error ? (
                            <div className="o-otp_error">
                              <Icon iconName="warning" size="12" />
                              <Typography.Text
                                extendClasses="fs-16x20 o-otp_error-text"
                                textStyle="center"
                                fontweight="600"
                              >
                                {error.message}
                              </Typography.Text>
                            </div>
                          ) : null
                        }
                      </div>
                    )}
                  />
                </div>
              </FormProvider>
              <div className="p-login_button">
                <ButtonCustom
                  loading={isLoading}
                  onClick={method.handleSubmit(hanldeRequestOtp)}
                >
                  Gửi OTP
                </ButtonCustom>
              </div>
            </div>
          ) : (
            <div className="p-login_otp">
              <div className="p-login_title">
                <Typography.Text fontweight="900" textStyle="center">
                  Nhập OTP
                </Typography.Text>
              </div>
              <div className="p-login_otp_description">
                <Typography.Text textStyle="center" fontweight="400">
                  Mã xác minh đã được gửi qua tin nhắn
                  <br />
                  số điện thoại
                  {' '}
                  <span>{method.getValues('phone')}</span>
                </Typography.Text>
              </div>
              <OTP
                error={verifyError}
                timeout={requestOtpData ? requestOtpData.retryAtSeconds : 0}
                loading={verifyLoading || loadingProfile}
                handleResendOTP={handleResendOTP}
                handleVerify={(otp) => {
                  trackingEvent({
                    action: 'click',
                    category: 'login',
                    label: 'confirm-otp'
                  });
                  verifyOtpMutate({ phone: method.getValues('phone'), otp });
                }}
              />
            </div>
          )}
        </div>
        <ModalNotify
          isOpen={invalid}
          handleClose={() => {
            trackingEvent({
              action: 'click',
              category: 'game',
              label: 'game1-skip',
            });
            setInvalid(false);
            navigate(ROUTE_PATH.HOME);
          }}
        >
          <Typography.Text
            textStyle="center"
            extendClasses="color-white fs-20x26 t-modalNotify_message"
            fontweight="900"
          >
            Mã QR không hợp lệ
          </Typography.Text>
          <div className="p-validateQrCode_button">
            <ButtonCustom
              onClick={() => {
                trackingEvent({
                  action: 'click',
                  category: 'game',
                  label: 'game1-input-valid-code',
                });
                setInvalid(false);
                navigate(ROUTE_PATH.CHALLENGE);
              }}
            >
              Quét mã khác
            </ButtonCustom>
          </div>
        </ModalNotify>
        <ModalSystemError
          isOpen={errSystem}
          handleClose={() => {
            setErrSystem(false);
            window.location.reload();
          }}
        />
      </MainLayout>
    </>
  );
};

export default Login;
