import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Typography } from 'tfc-components';

import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import CustomModal from 'components/organisms/Modal';
import useSchemas from 'utils/schemas';

interface ModalGiftWonProps {
  handleClose?: () => void;
  handlePlay: (data: InputCodeForm) => void;
  isOpen: boolean
}

export type InputCodeForm = {
  code: string;
};

const ModalLuckyCode: React.FC<ModalGiftWonProps> = ({
  isOpen,
  handleClose,
  handlePlay,
}) => {
  const { inputCode } = useSchemas();
  const method = useForm<InputCodeForm>({
    mode: 'onChange',
    resolver: yupResolver(inputCode),
    defaultValues: {
      code: '',
    }
  });

  useEffect(() => {
    method.reset();
  }, [handleClose]);

  return (
    <div className="t-modalLuckyCode">
      <CustomModal
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <Typography.Heading
          textStyle="center"
          extendClasses="color-white fs-20x26 t-modalLuckyCode_heading"
          fontweight="900"
        >
          NHẬP MÃ DỰ THƯỞNG
        </Typography.Heading>
        <FormProvider {...method}>
          <Controller
            name="code"
            control={method.control}
            render={({ field, fieldState: { error } }) => (
              <div className="t-modalLuckyCode_field">
                <Input placeholder="Nhập..." {...field} />
                {
                  error ? (
                    <div className="o-otp_error">
                      <Icon iconName="warning" size="12" />
                      <Typography.Text
                        extendClasses="fs-16x20 o-otp_error-text"
                        textStyle="center"
                        fontweight="600"
                      >
                        {error.message}
                      </Typography.Text>
                    </div>
                  ) : null
                }
              </div>
            )}
          />
        </FormProvider>
        <div className="t-modalLuckyCode_button">
          <ButtonCustom
            onClick={method.handleSubmit(handlePlay)}
          >
            <Typography.Text
              extendClasses="t-modalLuckyCode_btnText"
            >
              chơi ngay
            </Typography.Text>
          </ButtonCustom>
        </div>
      </CustomModal>
    </div>
  );
};

export default ModalLuckyCode;
