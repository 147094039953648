import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
// import { useSearchParams } from 'react-router-dom';
import { Typography } from 'tfc-components';

import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import GiftCard, { GiftCardProps } from 'components/organisms/GiftCard';
import Header from 'components/organisms/Header';
// import FancardModal from 'components/templates/FancardModal';
import useWindowDimensions from 'hooks/useWindowDemensions';
import HelmetComponent from 'pages/helmet';
import { getRewardListService, getSoccersListService } from 'services/auth';
import SEO_DATA from 'utils/seoData';

const MyGift: React.FC = () => {
  const { height } = useWindowDimensions();
  // const [searchParams] = useSearchParams();
  // const isLuckyDraw = searchParams.get('luckyDraw');
  // const [showModal, setShowModal] = React.useState(!!isLuckyDraw);

  const { data, isLoading } = useQuery(
    ['get-reward-list'],
    getRewardListService,
    {
      onError(err) {
        console.log(err);
      },
    }
  );
  const { data: soccer, isLoading: soccerLoading } = useQuery(
    ['get-soccer-list'],
    getSoccersListService,
    {
      onError(err) {
        console.log(err);
      },
    }
  );

  const giftList: GiftCardProps[] = useMemo(() => {
    const result: GiftCardProps[] = data?.map((item) => ({
      id: item.rewardData?.id,
      giftName: item.giftData?.name,
      giftImg: item.giftData?.thumbnail || '',
      soccerStatus: item.soccerData?.status || 'DELIVERING',
      voucherStatus: item.rewardData?.redeemStatus,
      qrCode: item.soccerData?.qrCode,
      vendor: item.rewardData?.giftVendor,
      enable: !!item.rewardData,
      fptPlayCode: item.rewardData?.fptPlayCode,
      redeemFptMsgCode: item.rewardData?.redeemFptMsgCode
    } as GiftCardProps)) || [];
    const soccerResult: GiftCardProps[] = soccer?.filter((
      item
    ) => !item.reward).map((item) => ({
      id: item.reward?.id,
      giftName: item.gift?.name,
      giftImg: item.gift?.thumbnail || '',
      soccerStatus: item.soccer?.status,
      voucherStatus: item.reward?.redeemStatus,
      qrCode: item.soccer?.qrCode,
      vendor: item.reward?.giftVendor,
      enable: !!item.reward,
      fptPlayCode: item.reward?.fptPlayCode,
      redeemFptMsgCode: item.reward?.redeemFptMsgCode
    })) || [];

    return [...result, ...soccerResult].sort((a, b) => (b.id || 0) - (a.id || 0));
  }, [data, soccer]);

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.MY_GIFT} />
      <div className="p-myGift">
        {
          (isLoading || soccerLoading) && (
            <LoadingFullScreen />
          )
        }
        <Header isBack isProfile />
        <Typography.Heading
          textStyle="center"
          extendClasses="color-white fs-22x28 p-myGift_heading"
          fontweight="900"
        >
          QUÀ CỦA TÔI
        </Typography.Heading>
        <div className="p-myGift_giftList" style={{ height: `${height - 185}px` }}>
          {giftList.map((item, index) => (
            <div
              key={`p-myGift_giftItem${index.toString()}`}
              className="p-myGift_giftItem"
            >
              <GiftCard
                vendor={item.vendor}
                id={item.id}
                giftName={item.giftName}
                soccerStatus={item.soccerStatus}
                voucherStatus={item.voucherStatus}
                giftImg={item.giftImg}
                qrCode={item.qrCode}
                enable={item.enable}
                fptPlayCode={item.fptPlayCode}
                redeemFptMsgCode={item.redeemFptMsgCode}
              />
            </div>
          ))}
        </div>
      </div>
      {/* <FancardModal isOpen={showModal} handleClose={() => setShowModal(false)} /> */}
    </>
  );
};

export default MyGift;
