import React from 'react';
import { Image, Typography } from 'tfc-components';

import giftBox from 'assets/images/giftBox.png';
import ButtonCustom from 'components/atoms/Button';
import CustomModal from 'components/organisms/Modal';
import { trackingEvent } from 'utils/trackingGA';

interface ModalGiftWonProps {
  handleClose?: () => void;
  handleLogin?: () => void;
  isOpen: boolean;
  hasLogin?: boolean;
  loading?: boolean;
}

const ModalGiftWon: React.FC<ModalGiftWonProps> = ({
  isOpen,
  hasLogin,
  loading,
  handleClose,
  handleLogin,
}) => (
  <div className="t-modalGiftWon">
    <CustomModal
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <Typography.Heading
        textStyle="center"
        extendClasses="color-white fs-20x26 t-modalGiftWon_heading"
        fontweight="900"
      >
        GHI BÀN QUÁ TUYỆT
        {' '}
        <br />
        ĐĂNG NHẬP ĐỂ BIẾT!
      </Typography.Heading>
      <div className="t-modalGiftWon_content">
        <Image
          imgSrc={giftBox}
          ratio={189 / 190}
          alt="giftBox"
        />
      </div>
      <div className="t-modalGiftWon_button">
        <ButtonCustom
          onClick={() => {
            if (handleLogin) {
              handleLogin();
              if (hasLogin) {
                trackingEvent({
                  action: 'click',
                  category: 'game',
                  label: 'pop-up-get-gift'
                });
              } else {
                trackingEvent({
                  action: 'click',
                  category: 'game',
                  label: 'pop-up-login-get-gift'
                });
              }
            }
          }}
          loading={loading}
        >
          {hasLogin ? 'Nhận quà ngay' : 'Đăng nhập ngay nhận quà liền tay'}
        </ButtonCustom>
      </div>
    </CustomModal>
  </div>
);

export default ModalGiftWon;
