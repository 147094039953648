import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings } from 'react-slick';
import { Typography } from 'tfc-components';

// import fancard from 'assets/images/home/fancard-game.png';
import background from 'assets/images/home/soccer-game.png';
import Carousel from 'components/organisms/Carousel';
import ChallengeCard from 'components/organisms/ChallengeCard';
import Header from 'components/organisms/Header';
import ModalLuckyCode from 'components/templates/ModalLuckyCode';
import ModalNotify from 'components/templates/ModalNotify';
import HelmetComponent from 'pages/helmet';
import { useAppSelector } from 'store/hooks';
import { ROUTE_PATH } from 'utils/constants';
import mapModifiers, { formatDateHHmmDDMM } from 'utils/functions';
import SEO_DATA from 'utils/seoData';
import { trackingEvent } from 'utils/trackingGA';

const settingBanner: Settings = {
  dots: false,
  centerMode: false,
  infinite: true,
  slidesToScroll: 1,
  speed: 500,
  initialSlide: 0,
  swipeToSlide: true,
  variableWidth: true,
  arrows: false,
};
const Challenge: React.FC = () => {
  const navigate = useNavigate();
  const system = useAppSelector((state) => state.systems.system);

  const [ended, setEnded] = React.useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [popupInput, setPopupInput] = React.useState(false);
  const handlePlay = ({ code }: { code: string }) => {
    trackingEvent({
      action: 'click',
      category: 'game',
      label: 'game1-play-w-code'
    });
    navigate(`${ROUTE_PATH.VALIDATE_QR_CODE}?qr=${code}&qrSource=manual&utm_source=Packaging&utm_medium=QRCode&utm_campaign=Heineken-UCL`);
  };
  return (
    <>
      <HelmetComponent seoData={SEO_DATA.CHALLENGE} />
      <div className="p-challenge">
        <Header isBack isProfile />
        <div className="p-challenge_content">
          <div className="p-challenge_title">
            <Typography.Text fontweight="900">
              TRỔ TÀI
              <br />
              FAN ĐÍCH THỰC
            </Typography.Text>
          </div>
          <div className="p-challenge_carousel">
            <Carousel settings={{
              ...settingBanner,
              beforeChange(_, nextSlide) {
                setCurrentSlide(nextSlide);
              },
            }}
            >
              <div className={mapModifiers('p-challenge_item', currentSlide === 0 && 'active')}>
                <ChallengeCard
                  name={(
                    <>
                      Chân sút
                      <br />
                      siêu đẳng
                    </>
                  )}
                  opening
                  index={1}
                  background={background}
                  handleQR={() => {
                    if (system?.eventEnded) {
                      setEnded(true);
                    } else {
                      navigate(ROUTE_PATH.SCAN_QR);
                    }
                  }}
                  handleInput={() => {
                    if (system?.eventEnded) {
                      setEnded(true);
                    } else {
                      setPopupInput(true);
                    }
                  }}
                >
                  Để có thể ra sân ghi bàn, Fan hãy tìm và quét mã QR có trong
                  thùng Heineken 24 lon để kích hoạt trò chơi nhé!
                </ChallengeCard>
              </div>
              {/* <div className={mapModifiers('p-challenge_item', currentSlide === 1 && 'active')}>
                <ChallengeCard
                  name={(
                    <>
                      Lộ diện
                      <br />
                      fan đích thực
                    </>
                  )}
                  opening
                  index={2}
                  background={fancard}
                  variant="fancard"
                >
                  Bắt trọn khuôn mặt tràn trề nhiệt huyết bóng đá,
                  để khám phá xem mình là loại Fan đích thực nào,
                  rồi nhận được chiếc thẻ chất chơi như cầu thủ.
                </ChallengeCard>
              </div> */}
              <div className={mapModifiers('p-challenge_item', currentSlide === 2 && 'active')}>
                <ChallengeCard
                  smaller
                  index={2}
                >
                  Để có thể ra sân ghi bàn, Fan hãy tìm và quét mã QR có trong
                  thùng Heineken 24 lon để kích hoạt trò chơi nhé!
                </ChallengeCard>
              </div>
            </Carousel>
          </div>
        </div>
        <ModalLuckyCode
          isOpen={popupInput}
          handleClose={() => setPopupInput(false)}
          handlePlay={handlePlay}
        />
        <ModalNotify isOpen={ended} handleClose={() => setEnded(false)}>
          <Typography.Text
            textStyle="center"
            fontweight="900"
            extendClasses="color-white t-qrDesktop_modal_text"
          >
            CHƯƠNG TRÌNH KHUYẾN MÃI
            <br />
            QUÉT MÃ QR ĐÃ KẾT THÚC
            <br />
            VÀO
            {' '}
            {formatDateHHmmDDMM(system?.eventEndAt || '')}
          </Typography.Text>
        </ModalNotify>
      </div>
    </>
  );
};

export default Challenge;
