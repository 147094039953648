import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Link, Typography } from 'tfc-components';

import Icon from 'components/atoms/Icon';
import BottomNavigate from 'components/organisms/BottomNavigate';
import Header from 'components/organisms/Header';
import MainLayout from 'components/organisms/MainLayout';
import HelmetComponent from 'pages/helmet';
import { getProfileService } from 'services/auth';
import { getAccessToken } from 'services/common/storage';
import { ROUTE_PATH } from 'utils/constants';
import SEO_DATA from 'utils/seoData';
import { trackingEvent } from 'utils/trackingGA';

const Profile: React.FC = () => {
  const navigate = useNavigate();

  const showLast3Digits = (phone: string) => {
    if (!phone) {
      return '';
    }
    return `xxxxxxx${phone.slice(-3)}`;
  };

  const { data: profile } = useQuery(
    'getProfile',
    getProfileService,
    {
      enabled: !!getAccessToken()
    }
  );

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.PROFILE} />
      <MainLayout noBg>
        <div className="p-profile">
          <div className="p-profile_content">
            <Header isBack />
            <div className="p-profile_data">
              <Typography.Text extendClasses="p-profile_prefix" fontweight="500">Hi</Typography.Text>
              <Typography.Text extendClasses="p-profile_name" fontweight="900">{profile?.profile?.name || showLast3Digits(profile?.account.phone || '')}</Typography.Text>
              <div className="p-profile_menu">
                <div className="p-profile_menu_top">
                  <div
                    className="p-profile_menu_avatar"
                    onClick={() => {
                      trackingEvent({
                        action: 'click',
                        category: 'account',
                        label: 'user-info'
                      });
                      navigate(`${ROUTE_PATH.UPDATE_USER_INFO}?profile=true`);
                    }}
                  >
                    <Icon iconName="profile" size="36" />
                    <Typography.Text fontweight="600">Thông tin cá nhân</Typography.Text>
                    <Icon iconName="right" size="12" />
                  </div>
                  <Link
                    href={ROUTE_PATH.MY_GIFT}
                    onClick={() => {
                      trackingEvent({
                        action: 'click',
                        category: 'account',
                        label: 'user-gift'
                      });
                    }}
                  >
                    <div className="p-profile_menu_item">
                      <Typography.Text fontweight="900">Quà của tôi</Typography.Text>
                      <div className="p-profile_menu_count">{profile?.account?.rewardsCount || 0}</div>
                    </div>
                  </Link>
                  <div
                    className="p-profile_menu_item"
                    onClick={() => {
                      trackingEvent({
                        action: 'click',
                        category: 'account',
                        label: 'tnc'
                      });
                      window.open(`${window.location.origin}/tnc.pdf`, '_blank');
                    }}
                  >
                    <Typography.Text fontweight="900">ĐIỀU KHOẢN DỊCH VỤ</Typography.Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-profile_bottom">
            <BottomNavigate />
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Profile;
