import React, {
  useEffect,
  useMemo, useState
} from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';
import {
  Image, Link, Loading, Typography
} from 'tfc-components';

import Icon from 'components/atoms/Icon';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import Header from 'components/organisms/Header';
import HelmetComponent from 'pages/helmet';
import { getGiftsService } from 'services/gifts';
import { useAppSelector } from 'store/hooks';
import { COLORS, ROUTE_PATH } from 'utils/constants';
import SEO_DATA from 'utils/seoData';
import { trackingLinkDetailGiftEvent } from 'utils/trackingGA';

const GiftTotal: React.FC = () => {
  const navigate = useNavigate();
  const luckyDrawClosed = useAppSelector((state) => state.systems.system?.luckyDrawConfig.closed);
  const systemLoading = useAppSelector((state) => state.systems.systemLoading);
  const [searchParams] = useSearchParams();
  const lucky = searchParams.get('lucky');
  const [ref1, setRef1] = useState<Slider | null>(null);
  const [ref2, setRef2] = useState<Slider | null>(null);

  const {
    data: giftsData,
    isLoading,
  } = useQuery(
    'getGiftsService',
    getGiftsService,
    {
      onError: (error: any) => {
        console.log({ getGiftsServiceError: error });
      }
    }
  );

  const formattedData = useMemo(() => (giftsData?.filter((item) => item.giftData.giftType === 'soccer')?.map((val) => ({
    image: val?.giftData?.image,
    name: val?.giftData?.nameList,
    code: val?.giftData?.code,
    trackingEventType: val?.giftData.trackingEventType,
    isLucky: val?.giftData.giftType === 'luckyDraw'
  })) || []), [giftsData]);

  useEffect(() => {
    if (luckyDrawClosed && lucky) {
      navigate(ROUTE_PATH.HOME + window.location.search);
    }
  }, [luckyDrawClosed, navigate]);

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.GIFT} />
      {systemLoading && <LoadingFullScreen />}
      <div className="p-giftTotal">
        {isLoading
          ? (
            <div className="p-giftTotal_loading">
              <Loading.CircleDashed color={COLORS.white} width={48} />
            </div>
          ) : (
            <div className="p-giftTotal_content">
              <Header isProfile />
              <Slider
                asNavFor={ref2 || undefined}
                ref={(slider) => setRef1(slider)}
                infinite={false}
              >
                {
                  formattedData.map((item, idx) => (
                    <div
                      key={`p-giftTotal_collection_item${idx.toString()}`}
                      className="p-giftTotal_info"
                    >
                      <div className="p-giftTotal_giftImg highlight-layer-gift">
                        <div className="p-gotGift_animation">
                          <div className="star one" />
                          <div className="star two" />
                          <div className="star three" />
                        </div>
                        <Image
                          imgSrc={item.image || ''}
                          ratio={328 / 284}
                          alt="gift"
                        />
                      </div>
                      <div className="p-giftTotal_giftName fs-14x21 color-white" dangerouslySetInnerHTML={{ __html: item.name || '' }} />
                      <Link
                        href={`${ROUTE_PATH.GIFT}/${item.code}`}
                        onClick={() => trackingLinkDetailGiftEvent(
                          item.trackingEventType,
                          item.isLucky
                        )}
                      >
                        <div className="p-giftTotal_detail">
                          <Typography.Text
                            extendClasses="fs-16x20 p-giftTotal_text"
                            fontweight="400"
                          >
                            Xem chi tiết
                          </Typography.Text>
                          <div className="p-giftTotal_icon">
                            <Icon iconName="right" size="12" />
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                }
              </Slider>
              <div className="p-giftTotal_collection">
                <Typography.Text
                  fontweight="900"
                  textStyle="uppercase"
                  extendClasses="p-giftTotal_collection_heading fs-14x21 color-white"
                >
                  bst quà
                </Typography.Text>
                <Slider
                  asNavFor={ref1 || undefined}
                  ref={(slider) => setRef2(slider)}
                  slidesToShow={3}
                  infinite={false}
                  swipeToSlide
                  focusOnSelect
                >
                  {
                    formattedData.map((item, idx) => (
                      <div
                        key={`p-giftTotal_collection_item${idx.toString()}`}
                        className="p-giftTotal_collection_item"
                      >
                        <div className="p-giftTotal_collection_imgGift">
                          <Image
                            imgSrc={item.image || ''}
                            ratio={1}
                            alt="gift"
                          />
                        </div>
                      </div>
                    ))
                  }
                </Slider>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default GiftTotal;
