/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { Input, Typography } from 'tfc-components';

import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';
import { trackingEvent } from 'utils/trackingGA';

type Props = {
  error?: string;
  loading?: boolean;
  timeout: number;
  handleResendOTP?: () => void;
  handleVerify?: (otp: string) => void;
};
const OTP: React.FC<Props> = ({
  error, timeout, loading, handleResendOTP, handleVerify
}) => {
  const numInput = 4;
  const [otp, setOtp] = useState(Array(numInput).fill(''));
  const [timeEnabledResend, setTimeEnabledResend] = React.useState(timeout);

  const otpRef = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  // useEffect(() => {
  //   otpRef[0].current?.focus();
  //   setOtp(Array(numInput).fill(''));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [error]);

  const onHandleReSendOTP = () => {
    trackingEvent({
      action: 'click',
      category: 'game',
      label: 're-send-otp',
    });
    if (handleResendOTP) {
      handleResendOTP();
    }
  };

  useEffect(() => {
    let count = timeEnabledResend;
    const timer = setInterval(() => {
      count -= 1;
      setTimeEnabledResend(count);
    }, 1000);
    if (timeEnabledResend === 0) clearInterval(timer);
    return () => {
      clearInterval(timer);
    };
  }, [timeEnabledResend]);

  useEffect(() => {
    setTimeEnabledResend(timeout);
  }, [timeout]);

  return (
    <div className="o-otp">
      <div className="o-otp_wrapper">
        <div className="o-otp_input">
          {otp.map((_, idx) => (
            <Fragment key={`item${idx.toString()}`}>
              <Input
                id={`OTP${idx + 1}`}
                name={`OTP${idx + 1}`}
                type="number"
                maxLength={1}
                ref={otpRef[idx]}
                extendClasses={mapModifiers('custom-input', error ? 'error' : '')}
                value={otp[idx]}
                onFocus={({ target }) => target.select()}
                onChange={({ target: { value } }) => {
                  const intValue = parseInt(value, 10);
                  const formatedValue = intValue ? String(intValue % 10) : value;

                  setOtp((oldOtp) => oldOtp.map((v, nth) => (idx === nth ? formatedValue : v)));

                  if (value.length > 0) {
                    if (idx < numInput - 1) {
                      otpRef[idx + 1].current?.focus();
                    }
                  }
                }}
                onKeyUp={({ key }) => {
                  const { current } = otpRef[idx];
                  if (!current) {
                    return;
                  }
                  if (key === 'Backspace' && idx > 0) {
                    setOtp((oldOtp) => oldOtp.map((v, nth) => (idx === nth ? '' : v)));
                    current.value = '';
                    otpRef[idx - 1].current?.focus();
                  }
                }}
              />
            </Fragment>
          ))}
        </div>
        {
          error ? (
            <div className="o-otp_error">
              <Icon iconName="warning" size="12" />
              <Typography.Text
                extendClasses="fs-16x20 o-otp_error-text"
                textStyle="center"
                fontweight="600"
              >
                {error}
              </Typography.Text>
            </div>
          ) : null
        }
      </div>
      <div className="o-otp_button">
        <ButtonCustom
          type="button"
          onClick={() => handleVerify && handleVerify(otp.join(''))}
          disabled={loading || otp.join('').length !== numInput}
          loading={loading}
          colorLoading="#008638"
        >
          Gửi OTP
        </ButtonCustom>
      </div>
      <div>
        <Typography.Text
          extendClasses="fs-16x20 color-white o-otp_noReceive"
          textStyle="center"
          fontweight="600"
        >
          Chưa nhận được à?
        </Typography.Text>
        <Typography.Text
          extendClasses="fs-16x20 o-otp_resend"
          textStyle="center"
          fontweight="700"
        >
          {timeEnabledResend === 0 && (
            <span
              className="fs-16x20 o-otp_resend_underline"
              onClick={() => onHandleReSendOTP()}
            >
              Gửi lại nhé!
            </span>
          )}
          {timeEnabledResend > 0 && (
            <>
              {' '}
              (chờ
              {' '}
              {timeEnabledResend}
              {' '}
              giây nữa)
            </>
          )}
        </Typography.Text>
      </div>
    </div>
  );
};

export default OTP;
