import React, { forwardRef } from 'react';

import Icon, { IconName } from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  center?: boolean;
  iconName?: IconName;
}

const InputFwRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = (
  {
    center, required, iconName, ...props
  },
  ref
) => (
    // eslint-disable-next-line react/jsx-indent
    <div className={mapModifiers('a-input', center && 'center', props.disabled && 'disabled')}>
      {required && <span className="a-input_required">*</span>}
      <input
        ref={ref}
        {...props}
      />
      {
        iconName && (
          <div className="a-input_icon">
            <Icon iconName={iconName} size="24" />
          </div>
        )
      }
    </div>
  );

const Input = forwardRef(InputFwRef);

export default Input;
