/* eslint-disable */
import React, { useEffect, useMemo } from 'react';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from 'react-router-dom'
import { Image, Typography } from 'tfc-components';

import giftWaiting from 'assets/images/gift_waiting.png';
import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import ModalNotify from 'components/templates/ModalNotify';
import { STATUSSOCCER } from 'services/auth/types';
import { GiftVendor } from 'services/rewards/types';
import { openGiftService } from 'services/soccers';
import { useAppSelector } from 'store/hooks';
import { ROUTE_PATH } from 'utils/constants';
import mapModifiers from 'utils/functions';
import { trackingEvent } from 'utils/trackingGA';
import ModalSystemError from 'components/templates/ModalSystemError';

export interface GiftCardProps {
  id?: number,
  vendor?: GiftVendor,
  giftName?: string
  soccerStatus?: STATUSSOCCER;
  voucherStatus?: number;
  giftImg: string;
  qrCode?: string;
  enable?: boolean;
  fptPlayCode?: string;
  redeemFptMsgCode?: number;
}

const GiftCard: React.FC<GiftCardProps> = ({
  id,
  giftName,
  soccerStatus,
  voucherStatus,
  giftImg,
  qrCode,
  vendor,
  enable,
  fptPlayCode,
  redeemFptMsgCode
}) => {
  const profile = useAppSelector((state) => state.auth.profile);
  const navigate = useNavigate();
  const [invalid, setInvalid] = React.useState(false);
  const [errSystem, setErrSystem] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [redeemError, setRedeemError] = React.useState(false);

  const giftStatus = useMemo(() => {
    if (vendor === 'physical') {
      switch (soccerStatus) {
        case 'REVOKED':
          return {
            statusText: 'Đã thu hồi',
            backgroundColor: 'white'
          };
        case 'DELIVERING':
          return {
            statusText: 'Chưa đổi',
            backgroundColor: 'fireEngineRed'
          };
        default:
          return {
            statusText: 'Đã săn',
            backgroundColor: 'white'
          };
      }
    } else if (soccerStatus === 'REVOKED') {
      return {
        statusText: 'Đã thu hồi',
        backgroundColor: 'white'
      };
    } else {
      switch (voucherStatus) {
        // case 13:
        //   return {
        //     statusText: 'Đã thu hồi',
        //     backgroundColor: 'white'
        //   };
        case 5:
          return {
            statusText: 'Đang chờ đổi',
            backgroundColor: 'white'
          };
        case 9:
          return {
            statusText: 'Đã đổi',
            backgroundColor: 'linear'
          };
        case 13:
          return {
            statusText: 'Đổi không thành công',
            backgroundColor: 'fireEngineRed'
          };
        case 1:
          return {
            statusText: 'Chưa đổi',
            backgroundColor: 'fireEngineRed'
          };
        default:
          return {
            statusText: 'Đã săn',
            backgroundColor: 'white'
          };
      }
    }
  }, [voucherStatus, soccerStatus, vendor]);

  const { mutate: openGift, isLoading: openGiftLoading } = useMutation('openGift', openGiftService, {
    onSuccess: (data) => {
      if (data.valid) {
        navigate(`${ROUTE_PATH.GOT_GIFT}/${data.rewardId ? data.rewardId : 'none'}?open=1`);
      } else {
        setInvalid(true);
      }
    },
    onError: () => {
      setErrSystem(true);
    }
  });

  const returnMsgFPTError = (code?: number) => {
    switch (code) {
      case 7:
        return 'Bạn đã đổi vượt quá hạn mức cho phép là 60 mã, tổng đài FPT sẽ liên hệ hỗ trợ bạn trong thời gian sớm nhất nhé!';
      case 3:
      case 8:
        return 'Mã kích hoạt này đã được sử dụng'
      default:
        return 'Lỗi hệ thống FPT, bạn vui lòng quay trở lại để thử lại sau nhé'
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 1500);
  }, [copied]);

  const Element = !enable || !!redeemFptMsgCode ? 'div' : Link;

  return (
    <>
      <Element to={enable && !redeemFptMsgCode ? `${ROUTE_PATH.GOT_GIFT}/${id}?open=1` : ''}
        onClick={() => {
          if (!!redeemFptMsgCode) {
            console.log(1);

            setRedeemError(true);
          }
        }}>
        {openGiftLoading && <LoadingFullScreen />}
        <div className="o-giftCard">
          <div className="o-giftCard_left">
            <div className="o-giftCard_imgGift">
              <Image
                imgSrc={!enable ? giftWaiting : giftImg}
                ratio={1}
                alt="gift"
              />
            </div>
          </div>
          <div className="o-giftCard_right">
            <div className={mapModifiers('o-giftCard_status', giftStatus.backgroundColor)}>
              <Typography.Text
                fontweight="700"
                textStyle="uppercase"
                extendClasses={`fs-10x19 color-${giftStatus.backgroundColor === 'fireEngineRed' ? 'white' : 'laSalleGreen'}`}
              >
                {giftStatus.statusText}
              </Typography.Text>
              {giftStatus.backgroundColor === 'linear' && (
                <div className="o-giftCard_star">
                  <Icon iconName="star" size="15" />
                </div>
              )}
            </div>
            {vendor !== 'physical' && voucherStatus === 1 && <>
              <div className="o-giftCard_noteExpired">NHẬP SĐT TRƯỚC NGÀY 18/06 ĐỂ ĐỔI QUÀ</div>
              <div className="o-giftCard_iconWarning"><Icon iconName='warningE' size='36' /></div>
            </>}
            {
              soccerStatus === 'REVOKED' ? (
                <div className="o-giftCard_giftName">
                  <div className="fs-14x21 o-giftCard_giftName_text" >
                    Đã thu hồi
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  {
                    !enable ? (
                      <div className="o-giftCard_btn">
                        <ButtonCustom
                          type="button"
                          onClick={() => {
                            if (profile?.profile && qrCode) {
                              openGift(qrCode);
                            } else {
                              navigate(`${ROUTE_PATH.UPDATE_USER_INFO}?game=true`);
                            }
                          }}
                        >
                          <Typography.Text
                            extendClasses="t-modalLuckyCode_btnText"
                          >
                            Nhận quà
                          </Typography.Text>
                        </ButtonCustom>
                      </div>
                    ) : (
                      <div className="o-giftCard_giftName">
                        <div className={mapModifiers("o-giftCard_giftName_text", !!fptPlayCode && voucherStatus === 1 && 'small')} dangerouslySetInnerHTML={{ __html: giftName || '' }} />
                        {fptPlayCode && voucherStatus === 1 && <div className="o-giftCard_fptCode">
                          <span>{fptPlayCode}</span>
                          <div className="o-giftCard_fptCode_copy" onClick={(e) => {
                            e.preventDefault();
                            trackingEvent({
                              label: 'copy-code-fpt',
                              action: 'click',
                              category: 'gift'
                            });
                            setCopied(true);
                            navigator.clipboard.writeText(fptPlayCode);
                          }}>Copy</div>
                        </div>}
                      </div>
                    )
                  }
                </React.Fragment>
              )
            }
          </div>
        </div>
      </Element>

      <ModalNotify
        isOpen={invalid}
        handleClose={() => {
          trackingEvent({
            action: 'click',
            category: 'game',
            label: 'game1-skip',
          });
          setInvalid(false);
          navigate(ROUTE_PATH.HOME);
        }}
      >
        <Typography.Text
          textStyle="center"
          extendClasses="color-white fs-20x26 t-modalNotify_message"
          fontweight="900"
        >
          Mã QR không hợp lệ
        </Typography.Text>
        <div className="p-validateQrCode_button">
          <ButtonCustom
            onClick={() => {
              trackingEvent({
                action: 'click',
                category: 'game',
                label: 'game1-input-valid-code',
              });
              setInvalid(false);
              navigate(ROUTE_PATH.CHALLENGE);
            }}
          >
            Quét mã khác
          </ButtonCustom>
        </div>
      </ModalNotify>
      <ModalNotify
        isOpen={redeemError}
        handleClose={() => {
          setRedeemError(false)
        }}
      >
        <Typography.Text
          textStyle="center"
          extendClasses="color-white fs-20x26 t-modalNotify_message"
          fontweight="900"
        >
          {returnMsgFPTError(redeemFptMsgCode)}
        </Typography.Text>
      </ModalNotify>
      <ModalSystemError
        isOpen={errSystem}
        handleClose={() => {
          setErrSystem(false);
          window.location.reload();
        }}
      />
      <div className={mapModifiers('copied', copied && 'show')}>
        Đã copy
      </div>
    </>
  );
};

export default GiftCard;
