import React from 'react';
import { Typography, Image } from 'tfc-components';

import heneiken from 'assets/images/heineken.png';
import Footer from 'components/organisms/Footer';
import MainLayout from 'components/organisms/MainLayout';
import HelmetComponent from 'pages/helmet';
import SEO_DATA from 'utils/seoData';

const AgeGateRejected: React.FC = () => (
  <>
    <HelmetComponent seoData={SEO_DATA.AGE_GATE_REJECTED} />
    <MainLayout noBg>
      <div className="p-ageGate p-ageGate_rejected">
        <div className="p-ageGate_content">
          <div className="p-ageGate_logo">
            <Image
              ratio={272 / 153}
              imgSrc={heneiken}
              alt="logo"
            />
          </div>
          <div className="p-ageGate_rejected_content">
            <div>
              <Typography.Text extendClasses="p-ageGate_rejected_text" fontweight="900">
                Bạn cần
                <br />
                đủ 18 tuổi
              </Typography.Text>
              <Typography.Text extendClasses="p-ageGate_rejected_text" fontweight="900">
                để có thể tham gia
                <br />
                hoạt động này.
              </Typography.Text>
            </div>
            <div className="p-ageGate_rejected_seeyou">
              <Typography.Text extendClasses="fs-16x20" fontweight="600">
                Hẹn bạn mùa Champion League
                <br />
                khi tròn 18 tuổi nhé!
              </Typography.Text>
            </div>
          </div>
        </div>
        <div className="p-ageGate_footer">
          <Footer hasBackground />
        </div>
      </div>
    </MainLayout>
  </>
);

export default AgeGateRejected;
