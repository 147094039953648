// import ReactGA4 from 'react-ga4';
import TagManager from 'react-gtm-module';

import { TrackingEventType } from 'services/gifts/types';

// Event không có hoặc ko thể làm dc
// 2. re-scan-qr

export type EventLabel = 'btn-over-18' | 'btn-under-18' | 'btn-game' | 'btn-about-ucl' | 'btn-gift'
  | 'game1-scan-qr' | 'game1-input-code' | 'game1-play-w-code' | 'change-user-img'
  | 'user-info' | 'user-gift' | 'user-activitie' | 'tnc' | 'ps5-gift' | 'ps5-scan-qr'
  | 'ps5-homepage' | 'serif-gift' | 'serif-scan-qr' | 'serif-homepage' | 'hnk-star-gift'
  | 'hnk-star-scan-qr' | 'hnk-star-homepage' | 'cash-gift' | 'cash-scan-qr' | 'cash-homepage'
  | 'fpt-gift' | 'fpt-scan-qr' | 'fpt-homepage' | 'kick-the-ball' | 're-scan-qr'
  | 'phone-num' | 'confirm-phone-num' | 'otp'
  | 'confirm-otp' | 'name' | 'birth' | 'id' | 'city' | 'province' | 'address' | 'submit'
  | 'ps5-view-detail' | 'serif-view-detail' | 'hnk-star-view-detail' | 'cash-view-detail'
  | 'fpt-view-detail' | 'homepage' | 'game' | 'gifts' | 'game1-input-valid-code' | 'game1-skip' | 'pop-up-close-guide'
  | 're-send-otp' | 'fpt-my-gift' | 'ps5-my-gift' | 'serif-my-gift' | 'hnk-star-my-gift' | 'cash-my-gift' | 'phone-info'
  | 'other-phone' | 'cf-new-phone' | 'close-popup' | 'edit-info' | 'scan-qr' | 'view-my-gifts' | 're-check-update'
  | 'cf-2nd-submit' | 'account'
  | 'pop-up-get-gift' | 'pop-up-login-get-gift' // Thay đổi mới event lần 3, event cũ là: 'pop-up-login' | 'pop-up-gift'
  | 'skip-register' // Bổ sung mới lần 3
  | 'luck-nxt-time-scan-qr' | 'luck-nxt-time-homepage' | 'copy-code-fpt' | 'use-fpt-to-my-phone' | 'send-fpt-to-others' // Bổ sung 24/3/2024
  | 'phone' | 'full-name' | 'start-play' // Bổ sung 26/3/2024 (Cột M)
  // Phase 2
  | 'ken-league-game-page' | 'ken-league-gift' | 'play-now' | 'selfie' | 'orther-img' | 'submit-img' | 'download' | 'game-1' | 'game-2'
  | 'OTP' | 'cfm-OTP' | 'ps5-detail' | 'serif-detail' | 'mu-detail' | 'arsenal-detail' | 'bayern-detail' | 'real-detail' | 'imac-detail' | 'balo-detail' | 'speaker-detail' | 'bike-detail' | 'grb-50k-detail' | 'grb-25k-detail'
  | 'join-lucky-draw' | 'play-lucky-draw' | 'explore-ken-card'
  | 'mu-my-gift' | 'arsenal-my-gift' | 'bayern-my-gift' | 'real-my-gift' | 'imac-my-gift' | 'balo-my-gift' | 'speaker-my-gift' | 'bike-my-gift' | 'grb-50k-my-gift' | 'grb-25k-my-gift'
  | 'mu-homepage' | 'arsenal-homepage' | 'bayern-homepage' | 'real-homepage' | 'imac-homepage' | 'balo-homepage' | 'speaker-homepage' | 'bike-homepage' | 'grb-50k-homepage' | 'grb-25k-homepage'
  | 'guideline' | 'close-pop-up' | 'close-guideline' | 'pop-up-ken-card'
  // Update 08/05/2024
  | 'ps5-regist' | 'serif-regist' | 'hnk-star-regist' | 'cash-regist'
  | 'ps5-sbm-regist' | 'serif-sbm-regist' | 'hnk-star-sbm-regist' | 'cash-sbm-regist'
  | 'ps5-new-qr' | 'serif-new-qr' | 'hnk-star-new-qr' | 'cash-new-qr' | 'fpt-new-qr'
  | 'delivery-ID' | 'delivery-city' | 'delivery-province' | 'delivery-address';

type EventCategory = 'age-gate' | 'home' | 'game' | 'account' | 'gift' | 'login' | 'info' | 'menu' | 'redeem-vc-fpt'
  // Phase 2
  | 'ken-game' | 'get-grb-vc' | 'user-gift' | 'ken-gift';

// UCL
export type UclEventTypes = {
  action: 'click';
  category: EventCategory;
  label?: EventLabel;
};

export function trackingEvent(event: UclEventTypes) {
  // ReactGA4.event('gtmEvent', event);
  TagManager.dataLayer({
    dataLayer: {
      event: 'gtmEvent', eventLabel: event.label, eventAction: event.action, eventCategory: event.category
    }
  });
}

export function trackingButtonReceiveEvent(typeTracking?: TrackingEventType) {
  if (typeTracking) {
    // ReactGA4.event('gtmEvent', {
    //   action: 'click',
    //   category: 'game',
    //   label: typeTracking,
    // } as UclEventTypes);
    TagManager.dataLayer({
      dataLayer: {
        event: 'gtmEvent', eventLabel: typeTracking, eventAction: 'click', eventCategory: 'game'
      }
    });
  }
}

export function trackingButtonNextScanQRCodeEvent(
  typeTracking?: TrackingEventType,
  isLuckyDraw?: boolean
) {
  if (typeTracking === 'ps5-gift') {
    trackingEvent({
      action: 'click',
      category: isLuckyDraw ? 'ken-game' : 'game',
      label: 'ps5-scan-qr',
    });
  } else if (typeTracking === 'serif-gift') {
    trackingEvent({
      action: 'click',
      category: isLuckyDraw ? 'ken-game' : 'game',
      label: 'serif-scan-qr',
    });
  } else if (typeTracking === 'hnk-star-gift') {
    trackingEvent({
      action: 'click',
      category: isLuckyDraw ? 'ken-game' : 'game',
      label: 'hnk-star-scan-qr',
    });
  } else if (typeTracking === 'cash-gift') {
    trackingEvent({
      action: 'click',
      category: isLuckyDraw ? 'ken-game' : 'game',
      label: 'cash-scan-qr',
    });
  } else if (typeTracking === 'fpt-gift') {
    trackingEvent({
      action: 'click',
      category: isLuckyDraw ? 'ken-game' : 'game',
      label: 'fpt-scan-qr',
    });
  }
}

export function trackingLinkToHomePageEvent(
  typeTracking?: TrackingEventType,
  isLuckyDraw?: boolean
) {
  switch (typeTracking) {
    case 'ps5-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'ps5-homepage',
      });
      break;
    case 'serif-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'serif-homepage',
      });
      break;
    case 'hnk-star-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'hnk-star-homepage',
      });
      break;
    case 'cash-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'cash-homepage',
      });
      break;
    case 'fpt-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'fpt-homepage',
      });
      break;
    case 'mu-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'mu-homepage',
      });
      break;
    case 'arsenal-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'arsenal-homepage',
      });
      break;
    case 'bayern-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'bayern-homepage',
      });
      break;
    case 'real-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'real-homepage',
      });
      break;
    case 'imac-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'imac-homepage',
      });
      break;
    case 'balo-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'balo-homepage',
      });
      break;
    case 'speaker-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'speaker-homepage',
      });
      break;
    case 'bike-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'bike-homepage',
      });
      break;
    case 'grb-50k-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'grb-50k-homepage',
      });
      break;
    case 'grb-25k-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'grb-25k-homepage',
      });
      break;
    default:
      break;
  }
}

export function trackingLinkToMyGiftEvent(
  typeTracking?: TrackingEventType,
  isLuckyDraw?: boolean
) {
  switch (typeTracking) {
    case 'ps5-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'ps5-my-gift',
      });
      break;
    case 'serif-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'serif-my-gift',
      });
      break;
    case 'hnk-star-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'hnk-star-my-gift',
      });
      break;
    case 'cash-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'cash-my-gift',
      });
      break;
    case 'fpt-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'fpt-my-gift',
      });
      break;
    case 'mu-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'mu-my-gift',
      });
      break;
    case 'arsenal-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'arsenal-my-gift',
      });
      break;
    case 'bayern-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'bayern-my-gift',
      });
      break;
    case 'real-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'real-my-gift',
      });
      break;
    case 'imac-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'imac-my-gift',
      });
      break;
    case 'balo-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'balo-my-gift',
      });
      break;
    case 'speaker-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'speaker-my-gift',
      });
      break;
    case 'bike-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'bike-my-gift',
      });
      break;
    case 'grb-50k-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'grb-50k-my-gift',
      });
      break;
    case 'grb-25k-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-game' : 'game',
        label: 'grb-25k-my-gift',
      });
      break;
    default:
      break;
  }
}

export function trackingLinkDetailGiftEvent(
  typeTracking?: TrackingEventType,
  isLuckyDraw?: boolean
) {
  // eslint-disable-next-line max-len
  // mu-gift | arsenal-gift | bayern-gift | real-gift | imac-gift | balo-gift | speaker-gift | bike-gift | grb-25k-gift | grb-50k-gift
  switch (typeTracking) {
    case 'ps5-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: isLuckyDraw ? 'ps5-detail' : 'ps5-view-detail',
      });
      break;
    case 'serif-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: isLuckyDraw ? 'serif-detail' : 'serif-view-detail',
      });
      break;
    case 'hnk-star-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'hnk-star-view-detail',
      });
      break;
    case 'cash-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'cash-view-detail',
      });
      break;
    case 'fpt-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'fpt-view-detail',
      });
      break;
    case 'mu-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'mu-detail',
      });
      break;
    case 'arsenal-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'arsenal-detail',
      });
      break;
    case 'bayern-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'bayern-detail',
      });
      break;
    case 'real-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'real-detail',
      });
      break;
    case 'imac-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'imac-detail',
      });
      break;
    case 'balo-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'balo-detail',
      });
      break;
    case 'speaker-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'speaker-detail',
      });
      break;
    case 'bike-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'bike-detail',
      });
      break;
    case 'grb-50k-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'grb-50k-detail',
      });
      break;
    case 'grb-25k-gift':
      trackingEvent({
        action: 'click',
        category: isLuckyDraw ? 'ken-gift' : 'gift',
        label: 'grb-25k-detail',
      });
      break;
    default:
      break;
  }
}

export function trackingRegistGiftEvent(
  typeTracking?: TrackingEventType,
) {
  // eslint-disable-next-line max-len
  // mu-gift | arsenal-gift | bayern-gift | real-gift | imac-gift | balo-gift | speaker-gift | bike-gift | grb-25k-gift | grb-50k-gift
  switch (typeTracking) {
    case 'ps5-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'ps5-regist',
      });
      break;
    case 'serif-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'serif-regist',
      });
      break;
    case 'hnk-star-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'hnk-star-regist',
      });
      break;
    case 'cash-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'cash-regist',
      });
      break;
    default:
      break;
  }
}

export function trackingSubmitRegistGiftEvent(
  typeTracking?: TrackingEventType,
) {
  // eslint-disable-next-line max-len
  // mu-gift | arsenal-gift | bayern-gift | real-gift | imac-gift | balo-gift | speaker-gift | bike-gift | grb-25k-gift | grb-50k-gift
  switch (typeTracking) {
    case 'ps5-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'ps5-sbm-regist',
      });
      break;
    case 'serif-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'serif-sbm-regist',
      });
      break;
    case 'hnk-star-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'hnk-star-sbm-regist',
      });
      break;
    case 'cash-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'cash-sbm-regist',
      });
      break;
    default:
      break;
  }
}

export function trackingNewQrGiftEvent(
  typeTracking?: TrackingEventType,
) {
  // eslint-disable-next-line max-len
  // mu-gift | arsenal-gift | bayern-gift | real-gift | imac-gift | balo-gift | speaker-gift | bike-gift | grb-25k-gift | grb-50k-gift
  switch (typeTracking) {
    case 'ps5-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'ps5-new-qr',
      });
      break;
    case 'serif-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'serif-new-qr',
      });
      break;
    case 'hnk-star-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'hnk-star-new-qr',
      });
      break;
    case 'cash-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'cash-new-qr',
      });
      break;
    case 'fpt-gift':
      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'fpt-new-qr',
      });
      break;
    default:
      break;
  }
}
