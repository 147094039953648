/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Controller, FormProvider, UseFormReturn, useWatch
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'tfc-components';

import { UserInfoTypes } from '.';

import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import DatePickerCustom from 'components/molecules/DatePicker';
import Pulldown from 'components/molecules/Pulldown';
import useAddressOptions from 'hooks/useAddressOptions';
import { useAppSelector } from 'store/hooks';
import { ROUTE_PATH } from 'utils/constants';
import { formatDateDDMMYYYY } from 'utils/functions';
import { trackingEvent } from 'utils/trackingGA';

type Step2Props = {
  method: UseFormReturn<UserInfoTypes>;
  handleRegister: (data: UserInfoTypes, isUpdate?: boolean) => void;
  loadingRegister?: boolean;
};

const Step2: React.FC<Step2Props> = ({
  method,
  handleRegister,
  loadingRegister
}) => {
  const profile = useAppSelector((state) => state.auth.profile);
  const provinceWatch = useWatch({ name: 'provinceCode', control: method.control });
  const {
    provinces, isLoadingProvinces
  } = useAddressOptions(provinceWatch?.value);
  const navigate = useNavigate();

  const isUpdate = useMemo(() => !profile?.profile
    || profile.profile.updatedCount === 0
    || !profile.profile.name || !profile.profile.dob || !profile.profile.province, [profile]);

  const onRegister = useCallback(async () => {
    trackingEvent({
      action: 'click',
      category: 'ken-game',
      label: 'submit'
    });
    const data = method.getValues();
    handleRegister(data, isUpdate);
  }, [handleRegister, isUpdate, method]);

  useEffect(() => {
    if (profile && profile?.profile) {
      method.reset({
        name: profile?.profile?.name || '',
        provinceCode: profile?.profile?.province ? {
          value: profile?.profile?.province?.code,
          label: profile?.profile?.province?.name
        } : undefined,
        dob: profile?.profile?.dob
      });
    } else {
      method.reset({});
    }
  }, [method, profile]);

  const maxDate = useMemo(() => {
    const beforeDate = new Date();
    beforeDate.setDate(beforeDate.getDate() - 1);
    beforeDate.setFullYear(beforeDate.getFullYear() - 18);
    return new Date(beforeDate);
  }, []);

  return (
    <div className="p-register_step2">
      {profile?.profile !== null
        ? profile?.profile?.updatedCount === 0
          ? (
            null
          ) : (
            <div className="p-userUpdate_updated">
              <Typography.Text
                fontweight="700"
                textStyle="center"
                extendClasses="p-userUpdate_updatedText fs-16x20"
              >
                Hệ thống đã ghi nhận thông tin của bạn
              </Typography.Text>
              <Typography.Text
                textStyle="center"
                extendClasses="color-white"
              >
                {`Bạn đã cập nhật thông tin cá nhân vào ngày
                  ${formatDateDDMMYYYY(profile?.profile?.updatedAt)} rồi, nên không thể chỉnh sửa nữa`}
              </Typography.Text>
            </div>
          )
        : ''}
      {isUpdate ? (
        <FormProvider {...method}>
          <Controller
            name="name"
            control={method.control}
            render={({ field, fieldState: { error } }) => (
              <div className="p-register_field">
                <Input
                  {...field}
                  placeholder="Họ và tên"
                  required
                  onClick={() => {
                    trackingEvent({
                      action: 'click',
                      category: 'ken-game',
                      label: 'name'
                    });
                  }}
                />
                {
                  error && (
                    <div className="p-register_error">
                      <Icon iconName="warning" size="12" />
                      <Typography.Text
                        extendClasses="fs-16x20 p-register_error-text"
                        textStyle="center"
                        fontweight="600"
                      >
                        {error.message}
                      </Typography.Text>
                    </div>
                  )
                }
              </div>
            )}
          />
          <Controller
            name="dob"
            control={method.control}
            render={({ field, fieldState: { error } }) => (
              <div className="p-register_field">
                <DatePickerCustom
                  defaultDate={field.value ? new Date(field.value) : undefined}
                  handleSelectDate={field.onChange}
                  maxDate={maxDate}
                  handleClick={() => {
                    trackingEvent({
                      action: 'click',
                      category: 'ken-game',
                      label: 'birth'
                    });
                  }}
                />
                {
                  error && (
                    <div className="p-register_error">
                      <Icon iconName="warning" size="12" />
                      <Typography.Text
                        extendClasses="fs-16x20 p-register_error-text"
                        textStyle="center"
                        fontweight="600"
                      >
                        {error.message}
                      </Typography.Text>
                    </div>
                  )
                }
              </div>
            )}
          />
          <Controller
            name="provinceCode"
            control={method.control}
            render={({ field, fieldState: { error } }) => (
              <div className="p-register_field">
                <Pulldown
                  {...field}
                  required
                  placeholder="Tỉnh/Thành phố"
                  name="province"
                  loading={isLoadingProvinces}
                  options={provinces}
                  isBottom
                  handleChange={field.onChange}
                  onMenuOpen={() => {
                    trackingEvent({
                      action: 'click',
                      category: 'ken-game',
                      label: 'city'
                    });
                  }}
                />
                {
                  error && (
                    <div className="p-register_error">
                      <Icon iconName="warning" size="12" />
                      <Typography.Text
                        extendClasses="fs-16x20 p-register_error-text"
                        textStyle="center"
                        fontweight="600"
                      >
                        {error.message}
                      </Typography.Text>
                    </div>
                  )
                }
              </div>
            )}
          />
        </FormProvider>
      ) : (
        <div className="p-userUpdate_infoTable">
          <div className="p-userUpdate_infoTable_item">
            <Typography.Text
              fontweight="700"
              extendClasses="p-userUpdate_infoTable_info"
            >
              Họ và tên:
              {' '}
            </Typography.Text>
            <Typography.Text
              fontweight="900"
              extendClasses="color-white"
            >
              {profile?.profile?.name || ''}
            </Typography.Text>
          </div>
          <div className="p-userUpdate_infoTable_item">
            <Typography.Text
              fontweight="700"
              extendClasses="p-userUpdate_infoTable_info"
            >
              Ngày sinh:
              {' '}
            </Typography.Text>
            <Typography.Text
              fontweight="900"
              extendClasses="color-white"
            >
              {formatDateDDMMYYYY(profile?.profile?.dob)}
            </Typography.Text>
          </div>
          <div className="p-userUpdate_infoTable_item">
            <Typography.Text
              fontweight="700"
              extendClasses="p-userUpdate_infoTable_info"
            >
              Tỉnh/ Thành phố:
              {' '}
            </Typography.Text>
            <Typography.Text
              fontweight="900"
              extendClasses="color-white"
            >
              {profile?.profile?.province?.name || ''}
            </Typography.Text>
          </div>
        </div>
      )}
      <div className="p-register_bottomText">
        <Typography.Text
          extendClasses="fs-16x20 color-white"
          textStyle="center"
        >
          Bạn đã sẵn sàng thể hiện đam mê
          <br />
          chứng tỏ
          {' '}
          <span
            className="p-register_bottomText_linear"
            onClick={() => navigate(ROUTE_PATH.CHALLENGE)}
          >
            Fan đích thực ?
          </span>
        </Typography.Text>
      </div>
      <div className="p-register_content_button">
        <ButtonCustom
          loading={loadingRegister}
          onClick={onRegister}
        >
          ĐĂNG KÝ LIỀN
        </ButtonCustom>
      </div>
    </div>
  );
};

export default Step2;
