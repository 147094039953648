import { GeneralDataTypes, TrackingUrlData } from './types';

import axiosInstance from 'services/common/instance';

export const getGeneralService = async (): Promise<GeneralDataTypes> => {
  const res = await axiosInstance.get('systems/general');
  return res.data?.data;
};

export const trackingURLService = async (data: TrackingUrlData): Promise<void> => {
  await axiosInstance.post('trackings', data);
};
