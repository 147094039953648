import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm
} from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Image, Typography } from 'tfc-components';

import introSt2 from 'assets/images/intro-step2.png';
import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import Header from 'components/organisms/Header';
import MainLayout from 'components/organisms/MainLayout';
import CustomModal from 'components/organisms/Modal';
import ModalNotify from 'components/templates/ModalNotify';
import HelmetComponent from 'pages/helmet';
import { requestRedeemService } from 'services/auth';
import { RequestRedeemTypes } from 'services/auth/types';
import { useAppSelector } from 'store/hooks';
import { ROUTE_PATH } from 'utils/constants';
import useSchemas from 'utils/schemas';
import SEO_DATA from 'utils/seoData';
import { trackingEvent } from 'utils/trackingGA';

interface GotGiftProps {
}

type GotGiftFormData = {
  otherPhone: string;
};

const Info: React.FC<GotGiftProps> = () => {
  const { rewardId } = useParams();
  const navigate = useNavigate();
  const { redeemPhone } = useSchemas();
  const profile = useAppSelector((state) => state.auth.profile);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false);
  const [isOpenModalIntro, setIsOpenModalIntro] = useState(false);
  const [isOpenModalForgot, setIsOpenModalForgot] = useState(false);
  const [invalid, setInvalid] = React.useState<'noVoucher' | 'other' | ''>('');
  const method = useForm<GotGiftFormData>({
    mode: 'onChange',
    resolver: yupResolver(redeemPhone),
    defaultValues: {
      otherPhone: ''
    },
  });

  const { mutate: requestRedeemMutate, data: responseRedeem, isLoading } = useMutation(
    'requestRedeemService',
    (params: RequestRedeemTypes) => requestRedeemService(params),
    {
      onSuccess: () => {
        setIsOpenModal(false);
        setIsOpenModalSuccess(true);
      },
      onError: (error: any) => {
        setIsOpenModal(false);
        if (Array.isArray(error) && error[0]?.code === 'noVoucher') {
          setInvalid('noVoucher');
        } else {
          setInvalid('other');
        }
      }
    }
  );

  const onSubmit = (data: GotGiftFormData) => {
    if (rewardId) {
      requestRedeemMutate({
        phone: data.otherPhone,
        rewardId: Number(rewardId)
      });
    }
  };

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.UPDATE_PHONE} />
      <MainLayout>
        {isLoading && <LoadingFullScreen />}
        <div className="p-info">
          <div className="p-info_content">
            <Header isBack />
            <Typography.Heading
              textStyle="center"
              extendClasses="color-white fs-22x28 p-info_heading"
              fontweight="900"
            >
              ĐĂNG NHẬP FPT
              {' '}
              <br />
              {' '}
              THÌ &quot;A LÔ&quot; SỐ NÀO?
            </Typography.Heading>
            <div
              className="p-info_fieldPhone p-info_field"
              onClick={() => {
                if (profile?.account.phone) {
                  trackingEvent({
                    action: 'click',
                    category: 'redeem-vc-fpt',
                    label: 'phone-info'
                  });
                  onSubmit({ otherPhone: profile?.account.phone });
                }
              }}
            >
              <Input placeholder="Nhập..." value={profile?.account.phone} readOnly />
            </div>
            <div
              className="p-info_fieldPhoneOther p-info_field"
              onClick={() => {
                trackingEvent({
                  action: 'click',
                  category: 'gift',
                  label: 'other-phone'
                });
                setIsOpenModal(true);
              }}
            >
              <Input
                readOnly
                placeholder="Số điện thoại khác"
              />
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={isOpenModal}
          handleClose={() => {
            setIsOpenModal(false);
            method.reset();
          }}
        >
          <Typography.Heading
            textStyle="center"
            extendClasses="color-white fs-20x26 p-info_headingModal"
            fontweight="900"
          >
            XÁC NHẬN SỐ ĐIỆN
            <br />
            THOẠI KIẾN TẠO
            <br />
            ĐƯỜNG CHUYỀN
          </Typography.Heading>
          <FormProvider {...method}>
            <Controller
              name="otherPhone"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <div className="p-info_fieldPhoneModal p-info_fieldModal">
                  <Input placeholder="Nhập..." {...field} />
                  {
                    error ? (
                      <div className="o-otp_error">
                        <Icon iconName="warning" size="12" />
                        <Typography.Text
                          extendClasses="fs-16x20 o-otp_error-text"
                          textStyle="center"
                          fontweight="600"
                        >
                          {error.message}
                        </Typography.Text>
                      </div>
                    ) : null
                  }
                </div>
              )}
            />
          </FormProvider>
          <div className="p-info_button">
            <ButtonCustom
              onClick={() => {
                trackingEvent({
                  action: 'click',
                  category: 'redeem-vc-fpt',
                  label: 'cf-new-phone'
                });
                onSubmit(method.getValues());
              }}
              loading={isLoading}
            >
              <Typography.Text
                extendClasses="p-info_btnText"
              >
                xác nhận
              </Typography.Text>
            </ButtonCustom>
          </div>
        </CustomModal>

        <CustomModal
          isOpen={isOpenModalSuccess}
          handleClose={() => {
            trackingEvent({
              action: 'click',
              category: 'redeem-vc-fpt',
              label: 'close-popup'
            });
            setIsOpenModalSuccess(false);
            navigate(ROUTE_PATH.MY_GIFT);
          }}
        >
          <Typography.Heading
            textStyle="center"
            extendClasses="color-white fs-20x26 p-info_headingModalSuccess"
            fontweight="900"
          >
            ĐÃ GHI BÀN GÓI SMAX
            <br />
            TRÊN FPT PLAY
          </Typography.Heading>
          <Typography.Text fontweight="400" textStyle="center" extendClasses="color-white fs-16x20">
            Mã kích hoạt đã được tích hợp vào
            <br />
            số điện thoại
            {' '}
            <span style={{ color: '#33FF6C', fontWeight: 600 }}>
              {method.getValues('otherPhone') || profile?.account.phone}
            </span>
          </Typography.Text>
          <Typography.Text fontweight="900" textStyle="center" extendClasses="fs-20x26 p-info_code">
            {responseRedeem?.fptPlayCode}
          </Typography.Text>
          <Typography.Text fontweight="400" textStyle="center" extendClasses="color-white fs-16x20">
            Truy cập và đăng nhập tại
            <br />
            <a style={{ color: '#33FF6C', fontWeight: 600 }} href="http://FPTPlay.vn">http://FPTPlay.vn</a>
            {' '}
            để tận hưởng
            <br />
            1 tháng xem trực tiếp
            <br />
            Champions League trên ứng
            <br />
            dụng FPT nhé!
          </Typography.Text>
          <div
            className="p-info_buttonIntro"
            onClick={() => {
              setIsOpenModalIntro(true);
              setIsOpenModalSuccess(false);
            }}
          >
            <ButtonCustom>Hướng dẫn đăng ký tài khoản</ButtonCustom>
          </div>
        </CustomModal>
        <ModalNotify
          isOpen={invalid !== ''}
          handleClose={() => {
            setInvalid('');
            navigate(ROUTE_PATH.MY_GIFT);
          }}
        >
          <Typography.Text
            textStyle="center"
            extendClasses="color-white fs-20x26 t-modalNotify_message"
            fontweight="900"
          >
            {
              invalid === 'noVoucher' ? 'Không tìm thấy mã quà tặng' : ''
            }
            {
              invalid === 'other' ? (
                <>
                  Đã có lỗi xảy ra
                  <br />
                  Vui lòng thử lại
                </>
              ) : ''
            }
          </Typography.Text>
        </ModalNotify>
        <CustomModal
          variant="introduction"
          isOpen={isOpenModalIntro}
          handleClose={() => {
            setIsOpenModalIntro(false);
            navigate(ROUTE_PATH.MY_GIFT);
          }}
        >
          <Typography.Text
            extendClasses="color-white p-info_intro_heading"
            fontweight="400"
          >
            Hướng dẫn gia nhập
            <br />
            đội fan đích thực
          </Typography.Text>
          <Typography.Text
            extendClasses="color-white p-info_intro_heading-large"
            fontweight="900"
            textStyle="uppercase"
          >
            Heineken x
            <br />
            FPT play
          </Typography.Text>
          <div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 1
              </div>
              <div className="p-info_intro_step">
                Mở ứng dụng FPT PLAY, tại màn
                <br />
                {' '}
                hình chính, chọn biểu tượng
                <br />
                để &quot;Đăng Nhập&quot;.
                <Icon iconName="app" size="30" />
              </div>
            </div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 2
              </div>
              <div className="p-info_intro_step">
                Chọn
                {' '}
                <span>&quot;Đăng ký tài khoản&quot;</span>
                <Image extendClasses="p-info_image" imgSrc={introSt2} alt="UCL" ratio={131 / 19} />
              </div>
            </div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 3
              </div>
              <div className="p-info_intro_step">
                Nhập số điện thoại và chọn
                {' '}
                <span>&quot;Tiếp tục&quot;</span>
                .
              </div>
            </div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 4
              </div>
              <div className="p-info_intro_step">
                Nhập mã OTP để xác thực, sau đó chọn
                {' '}
                <span>&quot;Tiếp tục&quot;</span>
                .
              </div>
            </div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 5
              </div>
              <div className="p-info_intro_step">
                Nhập mật khẩu và xác nhận lại mật khẩu.
              </div>
            </div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 6
              </div>
              <div className="p-info_intro_step">
                <span>Đăng ký/Đăng nhập</span>
                {' '}
                vào tài khoản FPT Play vừa tạo thành công.
              </div>
            </div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 7
              </div>
              <div className="p-info_intro_step">
                Chọn biểu tượng
                {' '}
                <Icon iconName="app" size="30" />
                {' '}
                trên màn hình chính để thấy vị trí nhập mã kích hoạt gói SMAX.
              </div>
            </div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 8
              </div>
              <div className="p-info_intro_step">
                Chọn
                {' '}
                <span>&quot;Nhập mã kích hoạt&quot;</span>
                .
              </div>
            </div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 9
              </div>
              <div className="p-info_intro_step">
                Nhập mã kích hoạt và chọn
                {' '}
                <span>&quot;Xác nhận&quot;</span>
                .
              </div>
            </div>
            <div
              className="p-info_intro_forgot"
              onClick={() => {
                setIsOpenModalForgot(true);
                setIsOpenModalIntro(false);
              }}
            >
              <Typography.Text
                fontweight="600"
                extendClasses="color-white fs-16x20"
              >
                Hướng dẫn lấy lại mật khẩu
                {' '}
              </Typography.Text>
              <Icon iconName="right" size="12" />
            </div>
          </div>
        </CustomModal>
        <CustomModal
          variant="introduction"
          isOpen={isOpenModalForgot}
          handleClose={() => {
            setIsOpenModalForgot(false);
            navigate(ROUTE_PATH.MY_GIFT);
          }}
        >
          <Typography.Text
            extendClasses="color-white p-info_intro_heading"
            fontweight="400"
          >
            Hướng dẫn
            <br />
            lấy lại phong độ
          </Typography.Text>
          <Typography.Text
            extendClasses="color-white p-info_intro_heading-large"
            fontweight="900"
            textStyle="uppercase"
          >
            KHI FAN
            <br />
            “QUÊN MẬT KHẨU”
          </Typography.Text>
          <div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 1
              </div>
              <div className="p-info_intro_step">
                Mở ứng dụng FPT PLAY, tại màn
                <br />
                {' '}
                hình chính, chọn biểu tượng
                <br />
                để &quot;Đăng Nhập&quot;.
                <Icon iconName="app" size="30" />
              </div>
            </div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 2
              </div>
              <div className="p-info_intro_step">
                Chọn mục
                {' '}
                <span>&quot;Quên mật khẩu&quot;</span>
              </div>
            </div>
            <div className="p-info_intro_item">
              <div className="p-info_intro_label">
                Bước 3
              </div>
              <div className="p-info_intro_step">
                Nhập SĐT muốn lấy lại mật khẩu để nhận và nhập mã OTP, sau đó ấn
                {' '}
                <span>&quot;Tiếp tục&quot;</span>
                .
              </div>
            </div>
            <div className="p-info_intro_item lastest">
              <div className="p-info_intro_label">
                Bước 4
              </div>
              <div className="p-info_intro_step">
                Nhập mật khẩu mới và xác nhận lại mật khẩu này.
              </div>
            </div>
          </div>
        </CustomModal>
      </MainLayout>
    </>
  );
};

export default Info;
