import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Typography } from 'tfc-components';

import heneiken from 'assets/images/heineken.png';
import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Footer from 'components/organisms/Footer';
import MainLayout from 'components/organisms/MainLayout';
import HelmetComponent from 'pages/helmet';
import { useAppDispatch } from 'store/hooks';
import { toggleContactModal } from 'store/system';
import { LOCAL_STORAGE, ROUTE_PATH } from 'utils/constants';
import mapModifiers from 'utils/functions';
import SEO_DATA from 'utils/seoData';
import { trackingEvent } from 'utils/trackingGA';

type AgeGateProps = {
  hanldeSuccess: () => void;
};

const AgeGate: React.FC<AgeGateProps> = ({ hanldeSuccess }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [checkedCondition, setCheckedCondition] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<boolean>(false);

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.AGE_GATE} />
      <MainLayout>
        <div className="p-ageGate">
          <div className="p-ageGate_content">
            <div className="p-ageGate_logo">
              <Image
                ratio={272 / 153}
                imgSrc={heneiken}
                alt="logo"
              />
            </div>
            <div className="p-ageGate_support" onClick={() => dispatch(toggleContactModal())}>
              <Icon iconName="support" size="32" />
              <Typography.Text
                extendClasses="fs-12x16 color-white"
              >
                Hỗ trợ
              </Typography.Text>
            </div>
            <div className="p-ageGate_age">
              <Typography.Text extendClasses="p-ageGate_text" fontweight="900">Bạn trên</Typography.Text>
              <Typography.Text extendClasses="p-ageGate_text bigger" fontweight="900">18?</Typography.Text>
            </div>
            <div className="p-ageGate_condition_confirm">
              <div className="p-ageGate_condition p-ageGate_condition-active">
                <div
                  className={mapModifiers('p-ageGate_condition_circle', checkedCondition && 'active')}
                >
                  <Icon iconName="check" size="12" />
                </div>
                <div className="p-ageGate_condition_content">
                  <Typography.Text extendClasses="fs-12x16 color-white">
                    Tôi đồng ý cho HEINEKEN Vietnam xử lý các thông tin cá nhân của
                    tôi cho mục đích tiếp thị, phân tích nội bộ,
                    chăm sóc khách hàng và các mục đích khác; cụ thể xem chi tiết ở
                    {' '}
                    <a
                      href={`${window.location.origin}/dpa.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Thông báo điều khoản bảo vệ dữ liệu cá nhân
                    </a>
                    {' '}
                    và
                    {' '}
                    <a
                      href={`${window.location.origin}/tnc.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Điều khoản & điều kiện chung
                    </a>
                  </Typography.Text>
                </div>
                <div className="p-ageGate_layerClick" onClick={() => setCheckedCondition(!checkedCondition)} />
              </div>
              {action && !checkedCondition && (
                <div className="p-ageGate_error">
                  <Icon iconName="warning" size="12" />
                  <Typography.Text
                    extendClasses="p-ageGate_error-text"
                    fontweight="600"
                  >
                    Bạn cần đồng ý với các điều khoản và điều kiện cũng như chính sách cookie
                  </Typography.Text>
                </div>
              )}
            </div>
            <div className="p-ageGate_controls">
              <div className="p-ageGate_button">
                <ButtonCustom
                  variant={checkedCondition ? 'outline-blue' : 'white'}
                  disabled={!checkedCondition}
                  onClick={() => {
                    trackingEvent({
                      action: 'click',
                      category: 'age-gate',
                      label: 'btn-over-18'
                    });
                    if (checkedCondition) {
                      localStorage.setItem(LOCAL_STORAGE.AGE_GATE, 'true');
                      hanldeSuccess();
                    } else setAction(true);
                  }}
                >
                  Đã trên 18
                </ButtonCustom>
              </div>
              <div className="p-ageGate_button">
                <ButtonCustom
                  onClick={() => {
                    trackingEvent({
                      action: 'click',
                      category: 'age-gate',
                      label: 'btn-under-18'
                    });
                    navigate(ROUTE_PATH.AGE_GATE_REJECTED);
                    localStorage.setItem(LOCAL_STORAGE.AGE_GATE, 'false');
                  }}
                >
                  Chưa đủ 18
                </ButtonCustom>
              </div>
            </div>
          </div>
          <div className="p-ageGate_footer">
            <Footer hasBackground />
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default AgeGate;
