import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Image, Loading, Typography
} from 'tfc-components';

import Header from 'components/organisms/Header';
import useWindowDimensions from 'hooks/useWindowDemensions';
import HelmetComponent from 'pages/helmet';
import { getGiftByCodeService } from 'services/gifts';
import { useAppSelector } from 'store/hooks';
import { COLORS, ROUTE_PATH } from 'utils/constants';
import SEO_DATA from 'utils/seoData';

const GiftDetail: React.FC = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const { height } = useWindowDimensions();
  const luckyDrawClosed = useAppSelector((state) => state.systems.system?.luckyDrawConfig.closed);

  const {
    data: giftsData,
    isLoading
  } = useQuery(
    'getGiftByCodeService',
    () => getGiftByCodeService(code || ''),
    {
      enabled: !!code,
      onError: (error: any) => {
        console.log({ getGiftByCodeService: error });
      }
    }
  );

  useEffect(() => {
    if (luckyDrawClosed && giftsData?.giftData.giftType === 'luckyDraw') {
      navigate(ROUTE_PATH.HOME + window.location.search);
    }
  }, [luckyDrawClosed, giftsData, navigate]);

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.GIFT_DETAIL} />
      <div className="p-giftDetail">
        {isLoading ? (
          <div className="p-giftTotal_loading">
            <Loading.CircleDashed color={COLORS.white} width={48} />
          </div>
        ) : (
          <div className="p-giftDetail_content">
            <Header isProfile isBack />
            <div className="p-giftDetail_body" style={{ height: `${height - 125}px` }}>
              <div className="p-giftDetail_giftImg highlight-layer-gift">
                <div className="p-gotGift_animation">
                  <div className="star one" />
                  <div className="star two" />
                  <div className="star three" />
                </div>
                <Image
                  imgSrc={giftsData?.giftData?.thumbnail || ''}
                  ratio={328 / 284}
                  alt="gift"
                />
              </div>
              <div className="p-giftDetail_detailGift">
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-16x20 p-giftDetail_giftCount"
                >
                  Số lượng còn lại:
                  {' '}
                  {(giftsData?.giftData?.quantity || 0)
                    - (giftsData?.giftData?.rewardsCount || 0) || 0}
                </Typography.Text>
                <div className="p-giftDetail_giftTitle fs-20x25 color-white" dangerouslySetInnerHTML={{ __html: giftsData?.giftData?.nameDetail || '' }} />
                {/* <Typography.Text
                  fontweight="600"
                  extendClasses="fs-16x20 p-giftDetail_giftSubTitle"
                >
                  {giftsData?.giftData?.description || ''}
                </Typography.Text> */}
                <Typography.Text
                  fontweight="600"
                  extendClasses="fs-14x18 color-white p-giftDetail_giftDescript"
                >
                  {giftsData?.giftData?.content || ''}
                </Typography.Text>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GiftDetail;
