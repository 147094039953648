import React from 'react';
import { Image, Link, Typography } from 'tfc-components';

import mapModifiers from 'utils/functions';

export interface NewsCardProps {
  imgSrc: string;
  title: string;
  publishedAt?: string;
  description?: string;
  href?: string;
  variant?: 'horizontal' | 'sm';
}

const NewsCard: React.FC<NewsCardProps> = ({
  title, imgSrc, href, variant, publishedAt, description
}) => (
  <div className={mapModifiers('o-newsCard', variant || '')}>
    <Link href={href}>
      <div className="o-newsCard_wrapper">
        <Image extendClasses="o-newsCard_image" imgSrc={imgSrc} alt="news" ratio={variant === 'horizontal' ? 2 / 1 : 335 / 188} />
        <div className="o-newsCard_content">
          <Typography.Text
            extendClasses="color-white o-newsCard_content_title"
            fontweight="600"
          >
            {title}
          </Typography.Text>
          <Typography.Text
            extendClasses="color-white u-mt-4 fs-10x19"
            fontweight="400"
          >
            {publishedAt}
          </Typography.Text>
          <Typography.Text
            extendClasses="color-white fs-12x16 u-mt-4 o-newsCard_content_description"
            fontweight="400"
          >
            {description}
          </Typography.Text>
        </div>
      </div>
    </Link>
  </div>
);

export default NewsCard;
