import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { useAppSelector } from 'store/hooks';

const useGTMTracker = () => {
  const gtmIds = useAppSelector((state) => state.systems.system?.others.gtmIds);

  useEffect(() => {
    if (gtmIds && gtmIds.length > 0) {
      gtmIds.forEach((gtm) => {
        if (gtm) {
          TagManager.initialize({ gtmId: gtm });
        }
      });
    }
  }, [gtmIds]);
};

export default useGTMTracker;
