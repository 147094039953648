import { StaticAllData } from './types';

import axiosInstance from 'services/common/instance';

export const getPageService = async <T>(slug: string): Promise<BasePageDataTypes<T>> => {
  const res = await axiosInstance.get(`pages/${slug}`);
  return res.data.data;
};

export const getPageStaticAllService = async (): Promise<StaticAllData[]> => {
  const res = await axiosInstance.get('pages/static/all');
  return res.data.data;
};
