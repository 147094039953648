/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useRef, useEffect, useState, useCallback, CSSProperties, useMemo
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading, Typography } from 'tfc-components';

import { InAppComponent } from 'App';
import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import MainLayout from 'components/organisms/MainLayout';
import CustomModal from 'components/organisms/Modal';
import ModalGiftWon from 'components/templates/ModalGiftWon';
import ModalNotify from 'components/templates/ModalNotify';
import ModalSystemError from 'components/templates/ModalSystemError';
import useInAppDetect from 'hooks/useInAppDetect';
import useWindowDimensions from 'hooks/useWindowDemensions';
import HelmetComponent from 'pages/helmet';
import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import {
  checkForceWinService,
  checkSoccerQrCodeService, forceWinService, savePlaySoccerResultService
} from 'services/soccers';
import { PlayResult, PlayResultParams } from 'services/soccers/types';
import { useAppSelector } from 'store/hooks';
import { LOCAL_STORAGE, ROUTE_PATH } from 'utils/constants';
import SEO_DATA from 'utils/seoData';
import { trackingEvent } from 'utils/trackingGA';

// const messageSoccerFail = (s: number) => {
//   switch (s) {
//     case 6:
//     case 7:
//     case 8:
//       return 'BÓNG ĐẬP XÀ NGANG';
//     case 13:
//     case 14:
//       return 'BÓNG ĐẬP CỘT DỌC';
//     default:
//       return 'BÓNG RA NGOÀI';
//   }
// };

export interface MyCustomCSS extends CSSProperties {
  '--var-progress': string;
}
export interface MyCustomAnimationCSS extends CSSProperties {
  '--var-delay': string;
}

const SoccerGame: React.FC = () => {
  /* Hooks */
  const navigate = useNavigate();
  const inApp = useInAppDetect();
  const { width, height } = useWindowDimensions();
  const { profile, isStartApp } = useAppSelector((state) => state.auth);

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');
  const timePG = sessionStorage.getItem(LOCAL_STORAGE.TPLAYINGTIME);
  /* Refs */
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videosRef = useRef<HTMLVideoElement[]>([]);
  const bannerRef = useRef<HTMLImageElement>(new Image());
  const closeRef = useRef<HTMLDivElement>(null);
  const played = useRef(false);
  const tempResults = useRef<PlayResult[]>([]);
  const waitingPlay = useRef(0);
  const reward = useRef<number | null>();
  const hasForceCheck = useRef(false);

  /* States */
  const [valid, setValid] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [errSystem, setErrSystem] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [countdownS, setCountdownS] = useState<number | undefined>(undefined);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cornerNumber, setCornerNumber] = useState<number | undefined>();

  /* Variables */
  const validPosition = [9, 10, 11, 16, 17, 18];
  const allowTime = 180000; // 3 minutes

  const scenarios = [
    '',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_01.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_02.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_03.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_04.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_05.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_06.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_07.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_08.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_09.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_10.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_11.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_12.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_13.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_14.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_15.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_16.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_17.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_18.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_19.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_20.mp4',
    'https://cdn.3forcom.org/static/ucl-hvn-2024/game/shoot_21.mp4',
  ];

  /* Mutations */
  const { mutate: validateMutate } = useMutation('validateQrCode', checkSoccerQrCodeService, {
    onSuccess: (data) => {
      if (!data.valid) setInvalid(true);
    },
    onError: () => {
      setErrSystem(true);
    }
  });
  const { mutate: checkForceWin } = useMutation('checkForceWin', checkForceWinService, {
    onSuccess: (data) => {
      if (data.valid && data.rewardId) {
        reward.current = data.rewardId;
      } else {
        reward.current = null;
      }
      hasForceCheck.current = true;
    },
    onError: () => {
      if (code) {
        validateMutate(code);
      } else {
        navigate(ROUTE_PATH.HOME);
      }
    },
  });
  const { mutate: forceWin, isLoading: loadingForceWin } = useMutation('forceWin', forceWinService, {
    onSuccess: (data) => {
      if (data.valid && data.rewardId) {
        reward.current = data.rewardId;
      } else {
        reward.current = null;
      }
    },
    onError: () => {
      reward.current = null;
    },
  });

  const { mutate: playGame } = useMutation('playGame', (data: {
    params: PlayResultParams,
    isLogin: boolean, hasProfile: boolean,
  }) => savePlaySoccerResultService({
    qrCode: data.params.qrCode,
    results: data.params.results.map((item) => (!item.correctPoint ? item : {
      ...item, correctPoint: validPosition.findIndex((i) => i === item.correctPoint)
    }))
  }), {
    onSuccess: (data, variables) => {
      if (!data.valid) {
        setInvalid(true);
      } else {
        setLocalStorage(LOCAL_STORAGE.PASSED_QRCODE, variables.params.qrCode);
        navigate(`${ROUTE_PATH.GOT_GIFT}/${data.rewardId ? data.rewardId : 'none'}`);

        document.documentElement.style.overflow = 'unset';
      }
    },
    onError: () => {
      setErrSystem(true);
    },
    onSettled: () => {
      setLoading(false);
    }
  });

  const handlePlayGame = useCallback((s: number) => {
    if (code) {
      setLoading(true);
      playGame({
        params: {
          qrCode: code,
          results: [
            ...tempResults.current,
            {
              playedAt: new Date().toISOString(),
              correctPoint: s
            }
          ]
        },
        isLogin: !!profile,
        hasProfile: !!profile && !!profile.profile
      });
    }
  }, [code, playGame, profile]);

  useQuery('checkSoccerQrCode', () => {
    if ((!!code && !timePG) || (!!code && new Date().getTime() - Number(timePG) > allowTime)) {
      return checkSoccerQrCodeService(code);
    } return undefined;
  }, {
    enabled: (!!code && !timePG) || (!!code && new Date().getTime() - Number(timePG) > allowTime),
    onSuccess: (data) => {
      if (!data?.valid) {
        setInvalid(true);
      }
    },
    onError: () => {
      setInvalid(true);
    }
  });

  /* Effects */
  useEffect(() => {
    const passed = getLocalStorage(LOCAL_STORAGE.PASSED_QRCODE);
    if (passed && passed === code) {
      setValid(true);
    }
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const videoLink = [
      'https://cdn.3forcom.org/static/ucl-hvn-2024/game/loop.mp4',
      '',
      ''
    ];

    const SHOOT_CONFIG = {
      mag: [0.3, 0.5, 0.7, 0.9, Infinity],
      dir: [
        (Math.sqrt(6) - Math.sqrt(2)) / 4,
        Math.sqrt(2) / 2,
        Math.sqrt(3) / 2,
        Infinity,
      ],
      minDist: 10,
      mappingWin: [
        [0x09, 0x09, 0x09, 0x0a, 0x0b, 0x0b, 0x0b],
        [0x09, 0x09, 0x09, 0x0a, 0x0b, 0x0b, 0x0b],
        [0x09, 0x09, 0x09, 0x0a, 0x0b, 0x0b, 0x0b],
        [0x10, 0x10, 0x10, 0x11, 0x12, 0x12, 0x12],
        [0x10, 0x10, 0x10, 0x11, 0x12, 0x12, 0x12],
      ],
      mappingLose: [
        [0x01, 0x01, 0x02, 0x03, 0x04, 0x05, 0x05],
        [0x0c, 0x0d, 0x06, 0x07, 0x08, 0x0e, 0x0f],
        [0x0c, 0x0d, 0x06, 0x07, 0x08, 0x0e, 0x0f],
        [0x0c, 0x0d, 0x13, 0x14, 0x15, 0x0e, 0x0f],
        [0x13, 0x13, 0x13, 0x14, 0x15, 0x15, 0x15],
      ],
      fadeLength: 500,
      bannerShow: 2000,
      bannerFade: 500,
    };
    let playIdx = 0;
    let initialX: any = null;
    let initialY: any = null;
    let initialTime: any = null;
    let currentX: any = null;
    let currentY: any = null;
    const fading = 0;
    let bannerTime = 0;

    const shoot = (dX: number, dY: number, dT: number, fail: boolean) => {
      const d = Math.sqrt(dX * dX + dY * dY);

      if (d < SHOOT_CONFIG.minDist || dY < 0) {
        return 0;
      }

      const V = d / dT;
      const theta = Math.atan2(dY, dX);
      const cos = Math.cos(theta);

      const alpha = theta >= 0 ? SHOOT_CONFIG.mag.findIndex((a) => V <= a) : 0;
      const beta = cos === 0
        ? 0
        : (Math.abs(cos) / cos)
        * SHOOT_CONFIG.dir.findIndex((b) => Math.abs(cos) <= b);

      const u = SHOOT_CONFIG.mag.length - alpha - 1;
      const v = SHOOT_CONFIG.dir.length + beta - 1;

      return fail ? SHOOT_CONFIG.mappingLose[u][v] : SHOOT_CONFIG.mappingWin[u][v];
    };

    const start = (e: any) => {
      // prevent swipe to navigate gesture
      e.preventDefault();
      initialX = e.touches ? e.touches[0].clientX : e.clientX;
      initialY = e.touches ? e.touches[0].clientY : e.clientY;
      initialTime = Date.now();
    };

    const move = (e: any) => {
      currentX = e.touches ? e.touches[0].clientX : e.clientX;
      currentY = e.touches ? e.touches[0].clientY : e.clientY;
    };

    const end = () => {
      if (initialX === null || initialY === null || currentX === null || currentY === null) {
        return;
      }

      const dX = currentX - initialX;
      const dY = initialY - currentY;
      const dT = Date.now() - initialTime;

      initialX = null;
      initialY = null;
      currentX = null;
      currentY = null;

      const sP = shoot(dX, dY, dT, false);
      const sF = shoot(dX, dY, dT, true);

      if (played.current) {
        return;
      }

      if (!sP || !sF) {
        return;
      }

      trackingEvent({
        action: 'click',
        category: 'game',
        label: 'kick-the-ball'
      });

      if (!played.current) {
        played.current = true;
      }

      if (playIdx > 0) {
        return;
      }
      // setSearchParams({
      //   code: String(code),
      //   tPlayingGame: String(new Date().getTime())
      // }, { replace: true });
      sessionStorage.setItem(LOCAL_STORAGE.TPLAYINGTIME, String(new Date().getTime()));
      setCornerNumber(sP);

      if (hasForceCheck.current) {
        videosRef.current[1].src = reward.current ? scenarios[sP] : scenarios[sF];
        videosRef.current[1].load();
      } else {
        videosRef.current[1].src = scenarios[sP];
        videosRef.current[1].load();
        videosRef.current[2].src = scenarios[sF];
        videosRef.current[2].load();
      }
      const countmore = (videosRef.current[0].duration
        - videosRef.current[0].currentTime < 1.0) ? 1 : 0;
      const countdown = videosRef.current[0].duration - videosRef.current[0].currentTime
        + 0.64 + countmore * videosRef.current[0].duration;

      waitingPlay.current = countdown;
      setCountdownS(3);

      setTimeout(() => {
        videosRef.current[0].loop = false;
        videosRef.current[0].addEventListener(
          'ended',
          () => {
            playIdx = (reward.current !== null || hasForceCheck.current === true) ? 1 : 2;
            videosRef.current[playIdx].play();
          },
          { once: true }
        );
      }, countmore * videosRef.current[0].duration * 1000);

      videosRef.current[1].addEventListener('ended', async () => {
        // if (reward.current) {

        // }
        // if (validPosition.includes(s)) {
        //   handlePlayGame(s);
        // } else {
        //   if (code) {
        //     tempResults.current = [...tempResults.current, {
        //       playedAt: new Date().toISOString(),
        //       correctPoint: null
        //     }];
        //   }
        //   setFailed(true);
        //   fading = Date.now() + SHOOT_CONFIG.fadeLength;
        //   setTimeout(() => {
        //     playIdx = 0;
        //     videosRef.current[0].loop = true;
        //     videosRef.current[0].play();
        //   }, SHOOT_CONFIG.fadeLength);
        //   setTimeout(() => {
        //     fading = 0;
        //   }, SHOOT_CONFIG.fadeLength * 2);
        // }
        // setSearchParams({
        //   code: String(code),
        // }, { replace: true });
        sessionStorage.removeItem(LOCAL_STORAGE.TPLAYINGTIME);
        navigate(`${ROUTE_PATH.GOT_GIFT}/${reward.current ? reward.current : 'none'}`);
        played.current = false;
      }, { once: true });

      videosRef.current[2].addEventListener('ended', async () => {
        sessionStorage.removeItem(LOCAL_STORAGE.TPLAYINGTIME);
        navigate(`${ROUTE_PATH.GOT_GIFT}/${reward.current ? reward.current : 'none'}`);
        played.current = false;
      }, { once: true });
    };

    canvas?.addEventListener('mousedown', start);
    canvas?.addEventListener('mousemove', move);
    canvas?.addEventListener('mouseup', end);
    canvas?.addEventListener('touchstart', start);
    canvas?.addEventListener('touchmove', move);
    canvas?.addEventListener('touchend', end);

    bannerRef.current.src = 'https://cdn.3forcom.org/static/ucl-hvn-2024/game/banner.png';

    videosRef.current = videoLink.map((link, idx) => {
      const video = document.createElement('video');
      video.playsInline = true;
      video.preload = 'auto';
      video.src = link;
      if (idx < 1) {
        video.load();
      }
      return video;
    });

    // videosRef.current.forEach((video, idx) => {
    //   video.addEventListener('ended', () => {
    //     if (idx > 1) {
    //       handlePlayGame();
    //     }
    //   });
    // });

    const gameLoop = () => {
      if (ctx && canvas) {
        const t = Date.now();
        ctx.globalAlpha = 1;
        ctx.clearRect(0, 0, canvas?.width, canvas?.height);
        ctx.drawImage(videosRef.current[playIdx], 0, 0, canvas?.width, canvas?.height);
        if (t < bannerTime) {
          const w = canvas?.width ?? 0;
          const h = canvas?.height ?? 0;
          const offset = Math.max(0, (h - w * height / width) / 2);
          ctx.globalAlpha = Math.min(1, (bannerTime - t) / SHOOT_CONFIG.bannerFade);
          ctx.drawImage(bannerRef.current, 0, offset, 1080, 480);
          ctx.globalAlpha = 1;
        }
        if (fading) {
          const blackOpacity = 1 - Math.abs(t - fading) / SHOOT_CONFIG.fadeLength;
          ctx.fillStyle = `rgba(0, 0, 0, ${blackOpacity})`;
          ctx.fillRect(0, 0, canvas?.width, canvas?.height);
        }
        requestAnimationFrame(gameLoop);
      }
    };

    const btnGroup = document.getElementById('btn-group');
    if (btnGroup) {
      btnGroup.addEventListener('click', () => {
        btnGroup.classList.add('hidden');
        trackingEvent({
          action: 'click',
          category: 'game',
          label: 'pop-up-close-guide',
        });
        bannerTime = Date.now() + SHOOT_CONFIG.bannerShow + SHOOT_CONFIG.bannerFade;
        videosRef.current[0].muted = true;
        videosRef.current[0].loop = true;
        videosRef.current[0].play();

        requestAnimationFrame(gameLoop);
      });
    }
    if (canvas) {
      document.documentElement.style.overflow = 'hidden';
    }
    const playAll = () => {
      setLoadingProgress((prev) => prev + 1);
    };
    bannerRef.current.addEventListener('load', playAll);
    videosRef.current[0].addEventListener('canplaythrough', playAll);

    return () => {
      bannerRef.current.removeEventListener('load', playAll);
      videosRef.current[0].removeEventListener('canplaythrough', playAll);
      canvas?.removeEventListener('mousedown', start);
      canvas?.removeEventListener('mousemove', move);
      canvas?.removeEventListener('mouseup', end);
      canvas?.removeEventListener('touchstart', start);
      canvas?.removeEventListener('touchmove', move);
      canvas?.removeEventListener('touchend', end);
    };
    // return null;
  }, [handlePlayGame]);

  const animationDelay = `${1000 * waitingPlay.current / 3}ms`;

  useEffect(() => {
    if (countdownS === 0) {
      return () => { };
    }
    const timer = setTimeout(() => {
      setCountdownS((prev) => (prev ? prev - 1 : undefined));
    }, 1000 * waitingPlay.current / 3);
    return () => clearTimeout(timer);
  }, [countdownS]);

  useEffect(() => {
    if (loadingProgress === 2) {
      setTimeout(() => {
        setLoadingProgress(3);
      }, 500);
    }
  }, [loadingProgress]);

  useEffect(() => {
    if (!profile && !isStartApp) {
      navigate(`${ROUTE_PATH.VALIDATE_QR_CODE}?qr=${code}`);
    }
  }, [code, navigate, profile]);

  useEffect(() => {
    if (code && cornerNumber && !hasForceCheck.current) {
      forceWin(code);
    }
  }, [code, cornerNumber]);

  useEffect(() => {
    if (!!timePG && code) {
      checkForceWin(code);
    }
  }, []);

  if (inApp) {
    return <InAppComponent inApp={inApp} />;
  }

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.GAME} />
      <MainLayout>
        {loading && <LoadingFullScreen />}
        <div className="p-soccerGame">
          <canvas
            id="game"
            width={1080}
            height={1920}
            style={{ width: `${width}px`, height: `${height}px`, objectFit: 'cover' }}
            ref={canvasRef}
          />
          {loadingProgress < 3 && (
            <div className="p-soccerGame_progress">
              <div className="p-soccerGame_progress_wrapper">
                <div className="p-soccerGame_progress_bar" style={{ '--var-progress': `${loadingProgress * 50}%` } as MyCustomCSS} />
                <div className="p-soccerGame_progress_percent">
                  {Math.round(loadingProgress * 50)}
                  %
                </div>
              </div>
            </div>
          )}
          {(countdownS === 0 && loadingForceWin) && (
            <div className="p-soccerGame_loadingShoot">
              <Loading.Spinner color="#1f1f1f" />
            </div>
          )}
          <div className="p-soccerGame_intro" id="btn-group" ref={closeRef}>
            <div
              className="p-soccerGame_intro_close"
              id="btn"
              style={{ display: loadingProgress > 2 ? 'block' : 'none' }}
            >
              <Icon iconName="close" size="36" />
            </div>
            {loadingProgress > 2 && (
              <div className="p-soccerGame_intro_oke">
                <ButtonCustom
                  onClick={() => {
                    trackingEvent({
                      action: 'click',
                      category: 'game',
                      label: 'start-play'
                    });
                  }}
                >
                  Bắt đầu
                </ButtonCustom>
              </div>
            )}
          </div>
          {countdownS && <div className="p-soccerGame_countdown" style={{ '--var-delay': animationDelay } as MyCustomAnimationCSS}>{countdownS}</div>}
        </div>
        <ModalGiftWon
          hasLogin={!!profile}
          isOpen={valid}
          handleLogin={() => navigate(`${ROUTE_PATH.LOGIN}?game=true`)}
          handleClose={() => navigate(ROUTE_PATH.HOME)}
        />
        <CustomModal
          isOpen={failed}
        >
          <Typography.Text
            textStyle="center"
            extendClasses="color-white p-soccerGame_failed"
            fontweight="900"
          >
            Bạn đã sút ra khỏi
            <br />
            khung thành, thử lại nhé!
          </Typography.Text>
          <div className="t-modalGiftWon_button">
            <ButtonCustom onClick={() => setFailed(false)}>
              Chơi lại
            </ButtonCustom>
          </div>
        </CustomModal>
        <ModalNotify
          isOpen={invalid}
          handleClose={() => {
            trackingEvent({
              action: 'click',
              category: 'game',
              label: 'game1-skip',
            });
            navigate(ROUTE_PATH.HOME);
          }}
        >
          <Typography.Text
            textStyle="center"
            extendClasses="color-white fs-20x26 t-modalNotify_message"
            fontweight="900"
          >
            Mã QR không hợp lệ
          </Typography.Text>
          <div className="p-validateQrCode_button">
            <ButtonCustom onClick={() => {
              trackingEvent({
                action: 'click',
                category: 'game',
                label: 'game1-input-valid-code',
              });
              navigate(ROUTE_PATH.CHALLENGE);
            }}
            >
              Quét mã khác
            </ButtonCustom>
          </div>
        </ModalNotify>
        <ModalSystemError
          isOpen={errSystem}
          handleClose={() => {
            setErrSystem(false);
            window.location.reload();
          }}
        />
      </MainLayout>
    </>
  );
};

export default SoccerGame;
