import React, { useEffect } from 'react';
import Modal from 'react-modal';

import Icon from 'components/atoms/Icon';
import useWindowDimensions from 'hooks/useWindowDemensions';
import mapModifiers from 'utils/functions';

export type VariantModal = 'default' | 'introduction' | 'contact' | 'successFpt' | 'introGrab' | 'fancard';

interface ModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  variant?: VariantModal;
  children?: React.ReactNode;
}

const CustomModal: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  variant,
  children,
}) => {
  const { height } = useWindowDimensions();
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = `${height}px`;
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  }, [height, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      className={mapModifiers('o-modal', variant)}
      ariaHideApp={false}
      portalClassName={mapModifiers('o-modal_portal', isOpen && 'open')}
      overlayClassName={mapModifiers('o-modal_overlay', variant)}
    >
      <div className="o-modal_main">
        <div className="o-modal_wrapper">
          <div className="o-modal_content">
            <div className="o-modal_body">
              {children}
            </div>
          </div>
          {handleClose && (
            <div className="o-modal_close" onClick={handleClose}>
              <Icon iconName="close" size="36" />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
