import './App.scss';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Navigate, Route, Routes
} from 'react-router-dom';
import { Typography } from 'tfc-components';

import AgeGateProtection from 'AgeGateProtection';
import MainLayout from 'components/organisms/MainLayout';
import ModalNotify from 'components/templates/ModalNotify';
import QrDesktop from 'components/templates/QrDesktop';
import useInitializeRender from 'hooks/useInitializeRender';
import ProtectedRoute from 'navigations/ProtectedRoute';
import routes from 'navigations/routes';
import AgeGateRejected from 'pages/AgeGateRejected';
import RedirectToPage from 'pages/RedirectToPage';
import { store } from 'store';
import { ROUTE_PATH } from 'utils/constants';

export const InAppComponent: React.FC<{ inApp: 'ios' | 'android' | null }> = ({ inApp }) => (
  <MainLayout>
    <ModalNotify isOpen>
      <div className="modal-inapp">
        <Typography.Text
          textStyle="center"
          extendClasses="color-white modal-inapp_title"
        >
          {inApp === 'ios' ? (
            <a href={window.location.href}>
              Vui lòng nhấn và giữ
              {' '}
              <span>liên kết này</span>
              {' '}
              trong 02 giây để chuyển đổi trình duyệt phù hợp và trải nghiệm
            </a>
          ) : (
            <a href={`intent://${window.location.host}${window.location.pathname}${window.location.search}#Intent;scheme=${window.location.protocol.replace(/\W/g, '')};action=android.intent.action.VIEW;end;`}>
              Vui lòng nhấn vào
              {' '}
              <span>liên kết này</span>
              {' '}
              để chuyển đổi trình duyệt phù hợp và trải nghiệm.
            </a>
          )}
        </Typography.Text>
      </div>
    </ModalNotify>
  </MainLayout>
);

const App: React.FC = () => {
  useInitializeRender();

  return (
    <Routes>
      <Route element={<QrDesktop />}>
        <Route element={<AgeGateProtection />}>
          {routes.map((route) => {
            if (route.children) {
              return (
                <Route element={route.element} key={`route-${route.id}}`}>
                  {route.children.map((child) => (
                    <Route
                      path={child.path}
                      element={child.protected ? (
                        <ProtectedRoute path={child.path || ''}>
                          {child.element}
                        </ProtectedRoute>
                      ) : child.element}
                      key={`route-${child.id}`}
                    />
                  ))}
                </Route>
              );
            }
            return (
              <Route
                path={route.path}
                element={route.protected ? (
                  <ProtectedRoute path={route.path || ''}>
                    {route.element}
                  </ProtectedRoute>
                ) : route.element}
                key={`route-${route.id}`}
              />
            );
          })}
          <Route path="*" element={<Navigate replace to={`${ROUTE_PATH.HOME}?page=notfound`} />} />
        </Route>
        <Route path={`${ROUTE_PATH.VALIDATE_QR_CODE}:code`} element={<RedirectToPage />} />
        <Route path={ROUTE_PATH.AGE_GATE_REJECTED} element={<AgeGateRejected />} />
      </Route>
    </Routes>
  );
};

const AppProvider: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppProvider;
