import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Typography } from 'tfc-components';

import heneiken from 'assets/images/heineken.png';
import heneikenPresent from 'assets/images/heineken_present.png';
import Icon from 'components/atoms/Icon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleContactModal } from 'store/system';
import { ROUTE_PATH } from 'utils/constants';
import mapModifiers from 'utils/functions';
import { trackingEvent } from 'utils/trackingGA';

interface HeaderProps {
  isBack?: boolean;
  isSkip?: boolean;
  isProfile?: boolean;
  isSupport?: boolean;
  isLogoPresent?: boolean;
  handleBack?: () => void;
  handleSkip?: () => void;
}

const Header: React.FC<HeaderProps> = ({
  isBack,
  isSkip,
  isProfile,
  isSupport,
  isLogoPresent,
  handleBack,
  handleSkip
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useAppSelector((state) => state.auth.profile);
  return (
    <div className={mapModifiers('o-header', isLogoPresent && 'present')}>
      <div className="o-header_left">
        {isBack && (
          <div
            className="o-header_back  animate animate-fadeInLeft"
            onClick={() => (handleBack ? handleBack() : navigate(-1))}
          >
            <div className="o-header_backIcon">
              <Icon
                iconName="back"
                size="23"
              />
            </div>
            <div className="o-header_backText">
              <Typography.Text
                extendClasses="fs-12x16 color-white"
              >
                Trở lại
              </Typography.Text>
            </div>
          </div>
        )}
      </div>
      <div className="o-header_logo" onClick={() => navigate(ROUTE_PATH.HOME)}>
        {isLogoPresent ? (
          <Image
            ratio={228 / 152}
            imgSrc={heneikenPresent}
            alt="logo"
          />
        ) : (
          <Image
            ratio={272 / 153}
            imgSrc={heneiken}
            alt="logo"
          />
        )}
      </div>
      {isProfile && (
        <div className="o-header_right animate animate-fadeIn">
          <div className="o-header_profile">
            <div
              className="o-header_profileIcon"
              onClick={() => {
                trackingEvent({
                  action: 'click',
                  category: 'menu',
                  label: 'account'
                });
                if (profile) {
                  navigate(ROUTE_PATH.PROFILE);
                } else {
                  navigate(ROUTE_PATH.LOGIN);
                }
              }}
            >
              <Icon
                iconName="profile"
                size="32"
              />
              <Typography.Text
                extendClasses="fs-12x16 color-white"
              >
                Tài khoản
              </Typography.Text>
            </div>
          </div>
        </div>
      )}
      {isSkip && (
        <Button extendClasses="o-header_skip">
          <Typography.Text
            extendClasses="fs-12x16"
            onClick={() => (handleSkip ? handleSkip() : navigate(ROUTE_PATH.HOME))}
          >
            Bỏ qua
          </Typography.Text>
        </Button>
      )}
      {isSupport && (
        <div className="o-header_support animate animate-fadeIn" onClick={() => dispatch(toggleContactModal())}>
          <Icon iconName="support" size="32" />
          <Typography.Text
            extendClasses="fs-12x16 color-white"
          >
            Hỗ trợ
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

export default Header;
