import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getProfileService } from 'services/auth';
import {
  UserProfile
} from 'services/auth/types';
import { removeAccessToken } from 'services/common/storage';

interface AuthState {
  profile?: UserProfile;
  loading?: boolean;
  isStartApp: boolean;
}

const initialState: AuthState = {
  isStartApp: true
};

export const getProfileAction = createAsyncThunk<UserProfile>(
  'profileReducer/getProfileService',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getProfileService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    resetAuth($state) {
      $state.profile = undefined;
      removeAccessToken();
    },
    startedApp($state) {
      $state.isStartApp = false;
    }
  },
  extraReducers(builder) {
    builder.addCase(getProfileAction.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getProfileAction.fulfilled, ($state, action) => {
      $state.profile = action.payload;
      $state.loading = false;
      if ($state.isStartApp) {
        $state.isStartApp = false;
      }
    });
    builder.addCase(getProfileAction.rejected, ($state) => {
      $state.loading = false;
      if ($state.isStartApp) {
        $state.isStartApp = false;
      }
    });
  },
});

export const {
  resetAuth,
  startedApp
} = authSlice.actions;

export default authSlice.reducer;
