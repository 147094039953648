import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';

import ogImage from 'assets/images/ucl-heineken_og.jpg';

interface Props {
  seoData?: SeoDataType;
  ogData?: OgData;
}

const HelmetComponent: React.FC<Props> = ({ seoData, ogData }) => (
  <Helmet>
    <title>
      {
        seoData?.title || 'Nâng Heineken vì Fan đích thực!'
      }
    </title>
    <meta
      name="description"
      content={
        seoData?.description || 'Mừng mùa giải UEFA Champions League, mừng tinh thần đam mê đá banh bất tận, Heineken trao Fan đích thực triệu quà đỉnh cao. Khám phá ngay!'
      }
    />
    <meta name="keywords" content={seoData?.keywords} />
    <meta property="og:url" content={window.location.href} />
    <meta
      property="og:title"
      content={ogData?.facebook?.title || seoData?.title || ''}
    />
    <meta
      property="og:description"
      content={ogData?.facebook?.description || seoData?.description || ''}
    />
    <meta
      property="og:image"
      content={window.location.origin + ogImage}
    />

    <meta
      name="twitter:title"
      content={
        ogData?.twitter?.title || seoData?.title || ''
      }
    />
    <meta
      name="twitter:description"
      content={
        ogData?.twitter?.description || seoData?.description || ''
      }
    />
    <meta
      name="twitter:image"
      content={window.location.origin + ogImage}
    />
  </Helmet>
);
HelmetComponent.defaultProps = {
  seoData: undefined,
  ogData: undefined,
};

export default HelmetComponent;
