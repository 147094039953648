import React from 'react';
import { Outlet } from 'react-router-dom';

import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import useDidMount from 'hooks/useDidMount';
import AgeGate from 'pages/AgeGate';
import { getLocalStorage } from 'services/common/storage';
import { useAppSelector } from 'store/hooks';
import { LOCAL_STORAGE } from 'utils/constants';

const AgeGateProtection: React.FC = () => {
  const [passed, setPassed] = React.useState<boolean>(false);
  const isStartApp = useAppSelector((state) => state.auth.isStartApp);
  useDidMount(() => {
    const isPassed = getLocalStorage(LOCAL_STORAGE.AGE_GATE);
    if (isPassed && isPassed === 'true') {
      setPassed(true);
    }
  });
  if (isStartApp) {
    return <LoadingFullScreen />;
  }
  if (passed) {
    return <Outlet />;
  }
  return (
    <AgeGate hanldeSuccess={() => setPassed(true)} />
  );
};

export default AgeGateProtection;
