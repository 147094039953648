import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'tfc-components';

import Camera from 'components/organisms/Camera';
import Header from 'components/organisms/Header';
import MainLayout from 'components/organisms/MainLayout';
import HelmetComponent from 'pages/helmet';
import { ROUTE_PATH } from 'utils/constants';
import SEO_DATA from 'utils/seoData';

const ScanQr: React.FC = () => {
  const navigate = useNavigate();
  const scanSuccess = (result: string) => {
    const code = result.split('/')[result.split('/').length - 1];
    navigate(`${ROUTE_PATH.VALIDATE_QR_CODE}?qr=${code}&utm_source=Packaging&utm_medium=QRCode&utm_campaign=Heineken-UCL`);
  };
  return (
    <>
      <HelmetComponent seoData={SEO_DATA.SCAN_QR} />
      <MainLayout>
        <Header isBack />
        <div className="p-scanQr">
          <Typography.Text fontweight="900" extendClasses="p-scanQr_title">
            Quét mã QR
          </Typography.Text>
          <div className="p-scanQr_camera">
            <Camera handleScanResult={scanSuccess} />
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default ScanQr;
