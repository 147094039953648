/* eslint-disable import/no-extraneous-dependencies */
import vi from 'date-fns/locale/vi';
import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import Icon from 'components/atoms/Icon';
import 'react-datepicker/dist/react-datepicker.css';
import mapModifiers, { formatDateDDMMYYYY } from 'utils/functions';

registerLocale('vi', vi as any);

interface DatePickerProps {
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
  defaultDate?: Date;
  handleSelectDate?: (date: Date) => void;
  handleClick?: () => void;
}
type Props = {
  value?: string;
  onClick?: () => void;
};
const ButtonRef: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    value,
    onClick
  },
  ref,
) => (
  <>
    <Icon iconName="calendar" size="24" />
    <div onClick={onClick} ref={ref}>
      <span>*</span>
      {value || 'Ngày sinh'}
    </div>
  </>
);

const CustomInput = forwardRef(ButtonRef);
const DatePickerCustom: React.FC<DatePickerProps> = (
  {
    defaultDate, handleSelectDate, maxDate, minDate, disabled, handleClick
  }
) => {
  const [value, setValue] = React.useState<Date>();
  return (
    <DatePicker
      disabled={disabled}
      maxDate={maxDate}
      minDate={minDate}
      wrapperClassName={mapModifiers('m-datePicker', disabled && 'disabled')}
      dateFormat="dd/MM/yyyy"
      selected={value || defaultDate}
      showMonthDropdown
      showYearDropdown
      scrollableYearDropdown
      onInputClick={handleClick}
      //   () => {
      //   handleClick();
      //   trackingEvent({
      //     action: 'click',
      //     category: 'info',
      //     label: 'birth'
      //   });
      // }}
      dropdownMode="select"
      locale="vi"
      onChange={(date: Date) => {
        setValue(date);
        if (handleSelectDate) handleSelectDate(date);
      }}
      customInput={(
        <CustomInput
          value={formatDateDDMMYYYY(value)}
        />
      )}
    />
  );
};

export default DatePickerCustom;
