const SEO_DATA: { [key: string]: SeoDataType } = {
  HOME: {
    title: 'Nâng Heineken vì Fan đích thực!',
    description: 'Mừng mùa giải UEFA Champions League, mừng tinh thần đam mê đá banh bất tận, Heineken trao Fan đích thực triệu quà đỉnh cao. Khám phá ngay!',
    keywords: 'Heineken, Ken, UEFA Champions League 2024, Cúp C1 2024, vô địch Châu Âu, đá banh, Fan đích thực, quà khủng, Ken League, giải thưởng'
  },
  AGE_GATE: {
    title: 'Nâng Heineken vì Fan đích thực!',
    description: 'Mừng mùa giải UEFA Champions League, mừng tinh thần đam mê đá banh bất tận, Heineken trao Fan đích thực triệu quà đỉnh cao. Khám phá ngay!',
    keywords: 'Heineken, Ken, UEFA Champions League 2024, Cúp C1 2024, vô địch Châu Âu, đá banh, Fan đích thực, quà khủng, Ken League, giải thưởng'
  },
  AGE_GATE_REJECTED: {
    title: 'Xác nhận độ tuổi',
    description: 'Bạn chưa đủ 18 tuổi để tham gia chương trình rồi. Hẹn gặp lại sau nhé.',
    keywords: 'Heineken, UEFA Champions League, Cúp C1 2024, Ken League, đá banh, Fan đích thực, quà khủng, Nâng Heineken vì Fan đích thực, đăng nhập, quét mã',
  },
  LOGIN: {
    title: 'Đăng nhập / Đăng ký',
    description: 'Đăng nhập và trổ tài ghi bàn quà khủng nào!',
    keywords: 'Heineken, UEFA Champions League, Cúp C1 2024, Ken League, đá banh, Fan đích thực, quà khủng,  đăng ký, đăng nhập, quét mã',
  },
  PROFILE: {
    title: 'Trang cá nhân của Fan',
    description: 'Nơi ghi lại hành trình ghi bàn và Fan card của chính bạn',
    keywords: 'Heineken, UEFA Champions League 2024, Ken League, đá banh, Fan đích thực, quà khủng, đăng ký, đăng nhập, cập nhật thông tin, trang cá nhân'
  },
  UPDATE_USER_INFO: {
    title: 'Cập nhật thông tin Fan',
    description: 'Cập nhật đầy đủ để nhận quà liền tay! Nhớ kiểm tra thông tin kỹ nhé vì bạn chỉ được cập nhật một lần thôi!',
    keywords: 'Heineken, UEFA Champions League 2024, Ken League, đá banh, Fan đích thực, quà khủng, đăng ký, đăng nhập, cập nhật thông tin, trang cá nhân',
  },
  MY_GIFT: {
    title: 'Quà của tôi',
    description: 'Bàn thắng trong tay, chỉ chờ đổi thành quà xịn!',
    keywords: 'Heineken, Ken, UEFA Champions League 2024, Cúp C1 2024, vô địch Châu Âu, đá banh, Fan đích thực, Ken League, quà của tôi, quà khủng',
  },
  GAME: {
    title: 'Trò chơi "Chân sút siêu đẳng"',
    description: 'Mời ngón tay vàng trong làng Fan đích thực xuất chiêu!',
    keywords: 'Heineken, Ken, UEFA Champions League 2024, Cúp C1 2024, vô địch Châu Âu, đá banh, Fan đích thực, Ken League, quà của tôi, quà khủng'
  },
  GOT_GIFT: {
    title: 'Kết quả ghi bàn',
    description: 'Cùng chiêm ngưỡng bảng vàng "tỉ số" của bạn nào!',
    keywords: 'Heineken, Ken, UEFA Champions League 2024, Cúp C1 2024, vô địch Châu Âu, đá banh, Fan đích thực, Ken League, quà của tôi, trúng thưởng'
  },
  GIFT: {
    title: 'Danh sách quà trao Fan đích thực',
    description: 'Mời bạn khám phá triệu quà đỉnh cao trao Fan đích thực',
    keywords: 'Heineken, Ken, UEFA Champions League 2024, Cúp C1 2024, vô địch Châu Âu, đá banh, Fan đích thực, Ken League, danh sách quà, trúng thưởng'
  },
  GIFT_DETAIL: {
    title: 'Chi tiết quà đỉnh',
    description: 'Xem thêm thông tin để biết quà đúng đỉnh!',
    keywords: 'Heineken, Ken, UEFA Champions League 2024, Cúp C1 2024, vô địch Châu Âu, đá banh, Fan đích thực, Ken League, danh sách quà, trúng thưởng'
  },
  CHALLENGE: {
    title: 'Quét QR, trổ tài Fan đích thực',
    description: 'Quét mã, ghi bàn, rinh ngàn quà khủng!',
    keywords: 'Heineken, Ken, UEFA Champions League 2024, Cúp C1 2024, vô địch Châu Âu, đá banh, Fan đích thực, Ken League, quét mã, ghi bàn'
  },
  SCAN_QR: {
    title: 'Quét QR trong thùng rinh quà cực khủng!',
    description: 'Úm ba la... quét ra quà khủng!',
    keywords: 'Heineken, UEFA Champions League, Ken League, đá banh, Fan đích thực, quà khủng, quét mã QR, mã dự thưởng, trúng thưởng, quà của tôi'
  },
  UPDATE_PHONE: {
    title: 'Nhập mã kích hoạt Gói SMAX trên ứng dụng FPT Play',
    description: 'Nhận ngay 1 tháng không lỡ cú sút nào với Gói SMAX trên ứng dụng FPT Play. Kích hoạt tại đây!',
    keywords: 'Heineken, UEFA Champions League, Ken League, đá banh, Fan đích thực, quét mã QR, trúng thưởng, quà của tôi, gói SMAX, FPT Play'
  }
};

export default SEO_DATA;
