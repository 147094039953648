import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Typography } from 'tfc-components';

import bgSoon from 'assets/images/home/soccer-game-soon.png';
import Icon from 'components/atoms/Icon';
import { ROUTE_PATH } from 'utils/constants';
import mapModifiers from 'utils/functions';
import { trackingEvent } from 'utils/trackingGA';

interface ChallengeCardProps {
  smaller?: boolean;
  opening?: boolean;
  background?: string;
  name?: React.ReactNode;
  index: number;
  children?: React.ReactNode;
  variant?: 'fancard';
  handleQR?: () => void;
  handleInput?: () => void;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({
  smaller,
  opening,
  background,
  name,
  index,
  children,
  variant,
  handleQR,
  handleInput,
}) => {
  const navigate = useNavigate();
  return (
    <div className={mapModifiers('o-challengeCard', smaller && 'small', opening && 'opening')}>
      <div className="o-challengeCard_label">
        <Icon iconName="star" size="24" />
        <Typography.Text>
          Thử thách
          {' '}
          {index}
        </Typography.Text>
      </div>
      {background && (
        <div className="o-challengeCard_image">
          <Image imgSrc={background} alt="challenge" ratio={variant === 'fancard' ? (289 / 367) : (289 / 359)} />
        </div>
      )}
      {!opening && (
        <div className="o-challengeCard_image">
          <Image imgSrc={bgSoon} alt="challenge" ratio={202 / 280} />
        </div>
      )}
      {opening ? (
        <div className="o-challengeCard_content">
          <Typography.Text extendClasses="fs-16x20 o-challengeCard_name" fontweight="900">{name}</Typography.Text>
          <div className="o-challengeCard_description">
            <Typography.Text extendClasses="fs-12x16 color-white" fontweight="400">{children}</Typography.Text>
          </div>
          {variant === 'fancard' ? (
            <button
              className="o-challengeCard_fancardButton"
              type="button"
              onClick={() => {
                trackingEvent({
                  action: 'click',
                  category: 'ken-game',
                  label: 'play-now'
                });
                navigate(ROUTE_PATH.GAMESHOW);
              }}
            >
              <Typography.Text fontweight="400">
                Chơi ngay
              </Typography.Text>
            </button>
          ) : (
            <div className="o-challengeCard_actions">
              <button
                className="o-challengeCard_button o-challengeCard_scan"
                type="button"
                onClick={() => {
                  if (handleQR) {
                    trackingEvent({
                      action: 'click',
                      category: 'game',
                      label: 'game1-scan-qr'
                    });
                    handleQR();
                  }
                }}
              >
                <Icon iconName="scan" size="18" />
                <Typography.Text>
                  Quét
                  <br />
                  mã QR
                </Typography.Text>
              </button>
              <button
                className="o-challengeCard_button o-challengeCard_input"
                type="button"
                onClick={() => {
                  if (handleInput) {
                    trackingEvent({
                      action: 'click',
                      category: 'game',
                      label: 'game1-input-code'
                    });
                    handleInput();
                  }
                }}
              >
                <Icon iconName="input" size="18" />
                <Typography.Text>
                  Nhập mã
                  <br />
                  dự thưởng
                </Typography.Text>
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="o-challengeCard_soon">
          <Icon iconName="lock" size="36" />
          <Typography.Text
            extendClasses="o-challengeCard_soon_title"
            fontweight="900"
          >
            Sắp bật mí!
          </Typography.Text>
          <Typography.Text extendClasses="o-challengeCard_soon_time">xx/xx/2024</Typography.Text>
        </div>
      )}
    </div>
  );
};

export default ChallengeCard;
