import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import useDidMount from './useDidMount';
import useGaTracker from './useGATracker';
import useGTMTracker from './useGTMTracker';

import { getAccessToken, getLocalStorage, setLocalStorage } from 'services/common/storage';
import { trackingURLService } from 'services/systems';
import { getProfileAction, startedApp } from 'store/auth';
import { useAppDispatch } from 'store/hooks';
import {
  getStaticAllAction, getSystemAction
} from 'store/system';
import { LOCAL_STORAGE } from 'utils/constants';
import { getSearchParams } from 'utils/functions';

const useInitializeRender = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { mutate: trackingMutate } = useMutation('tracking-url', trackingURLService);

  useGaTracker();
  useGTMTracker();

  useDidMount(() => {
    // analytics
    // eslint-disable-next-line max-len
    // eslint-disable-next-line no-underscore-dangle, no-multi-assign, @typescript-eslint/naming-convention
    const _mtm = (window as any)._mtm = (window as any)._mtm || [];
    _mtm.push({ 'mtm.startTime': (new Date().getTime()), event: 'mtm.Start' });
    const d = document; const g = d.createElement('script'); const
      s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = 'https://cdn.matomo.cloud/heinekenucl.matomo.cloud/container_wCPN3nFt.js'; s.parentNode?.insertBefore(g, s);

    // init
    const searchPrs = getSearchParams(searchParams.entries());
    if (Object.keys(searchPrs).length > 0) {
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        qr, utm_source, utm_medium, utm_campaign, ...others
      } = searchPrs;
      setLocalStorage(LOCAL_STORAGE.TRACKING_URL, JSON.stringify(searchPrs));
      trackingMutate({
        qrCode: qr || null,
        utmSource: utm_source || null,
        utmMedium: utm_medium || null,
        utmCampaign: utm_campaign || null,
        others: {
          ...others,
          rejectedAgeCheck: String(getLocalStorage(LOCAL_STORAGE.AGE_GATE) === 'false')
        }
      });
    }
    dispatch(getStaticAllAction());
    dispatch(getSystemAction());
    if (getAccessToken()) {
      dispatch(getProfileAction());
    } else {
      dispatch(startedApp());
    }
  });
};

export default useInitializeRender;
