import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link, Typography } from 'tfc-components';

import Icon, { IconName } from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';
import { EventLabel, trackingEvent } from 'utils/trackingGA';

type MenuType = {
  id: number;
  title: string;
  icon: IconName;
  iconActive: IconName;
  path: string;
  childPath?: string;
  trackingType: EventLabel;
};

const menuData: MenuType[] = [
  {
    id: 1,
    title: 'Trang chủ',
    icon: 'home',
    iconActive: 'homeActive',
    path: '/',
    trackingType: 'homepage',
  },
  {
    id: 2,
    title: 'Thử thách',
    icon: 'game',
    iconActive: 'gameActive',
    path: '/challenge',
    trackingType: 'game',
  },
  {
    id: 3,
    title: 'Quà tặng',
    icon: 'gift',
    iconActive: 'giftActive',
    path: '/gift',
    trackingType: 'gifts',
    childPath: '/gift-detail',
  },
  // {
  //   id: 3,
  //   title: 'Tin tức',
  //   icon: 'news',
  //   iconActive: 'newsActive',
  //   path: '/news',
  // },
];

const BottomNavigate: React.FC = () => {
  const location = useLocation();
  const childPath = `/${location?.pathname?.split('/')[1]}`;
  return (
    <div className="o-bottomNavigate">
      {menuData.map((item, idx) => {
        const isActive = ((location.pathname === item.path) || (childPath === item.childPath));
        return (
          <Link
            key={`"o-bottomNavigate_item${idx.toString()}`}
            href={item.path}
            extendClasses={mapModifiers(
              'o-bottomNavigate_item',
              isActive && 'active'
            )}
            onClick={() => {
              trackingEvent({
                action: 'click',
                category: 'menu',
                label: item.trackingType,
              });
            }}
          >
            <Icon iconName={isActive ? item.iconActive : item.icon} size={isActive ? '24' : '28'} />
            <Typography.Text fontweight="700" extendClasses="o-bottomNavigate_text">
              {item.title}
            </Typography.Text>
          </Link>
        );
      })}
    </div>
  );
};

export default BottomNavigate;
