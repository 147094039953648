import React from 'react';
import { Button, Loading, Typography } from 'tfc-components';

import mapModifiers from 'utils/functions';

interface ButtonCustomProps {
  children?: React.ReactNode;
  onClick?: () => void;
  type?: 'submit' | 'button';
  loading?: boolean;
  disabled?: boolean;
  variant?: 'outline' | 'outline-blue' | 'transparent' | 'only-icon' | 'white';
  modifier?: ('uppercase' | 'lowercase' | 'lg' | 'sm')[],
  className?: string;
  icon?: React.ReactNode,
  id?: string;
  colorLoading?: string;
}

const ButtonCustom: React.FC<ButtonCustomProps> = ({
  children, onClick,
  type,
  disabled,
  loading,
  variant,
  modifier,
  className,
  icon,
  id,
  colorLoading = '#fff'
}) => (
  <div className="a-button">
    <Button
      extendClasses={`${mapModifiers('a-button_main', variant, modifier)} ${className}`}
      type={type}
      id={id}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      icon={icon}
      loadingIndicator={loading ? (
        <div className="a-button_loadingIndicator">
          <Loading.CircleDashed color={colorLoading} width={24} />
        </div>
      ) : undefined}
    >
      <Typography.Text
        extendClasses="fs-16x20 color-castletonGreen"
        textStyle="uppercase"
      >
        {children}
      </Typography.Text>
    </Button>
    <svg width="100%" height="44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" className="a-button_border" height="43" rx="21.5" stroke={variant === 'outline' ? 'url(#paint0_linear_1_1285)' : 'url(#paint1_linear_1_1285)'} />
      <defs>
        {variant === 'outline' ? (
          <linearGradient id="paint0_linear_1_1285" x1="187.009" y1="19.25" x2="3.41656" y2="18.5407" gradientUnits="userSpaceOnUse">
            <stop stopColor="#ffffff" />
            <stop offset="1" stopColor="#ffffff" />
          </linearGradient>
        ) : (
          <linearGradient id="paint1_linear_1_1285" x1="187.009" y1="19.25" x2="3.41656" y2="18.5407" gradientUnits="userSpaceOnUse">
            <stop stopColor="#27FFC6" />
            <stop offset="1" stopColor="#8DFF36" />
          </linearGradient>
        )}
      </defs>
    </svg>

  </div>
);

export default ButtonCustom;
