/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Link, Typography } from 'tfc-components';

import bgPhase2 from 'assets/images/bg-home-phase2.jpg';
import bgPhase1 from 'assets/images/bg-home.jpg';
import ButtonCustom from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
// import Carousel from 'components/organisms/Carousel';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import Footer from 'components/organisms/Footer';
import Header from 'components/organisms/Header';
import MainLayout from 'components/organisms/MainLayout';
import ModalNotify from 'components/templates/ModalNotify';
import useWindowDimensions from 'hooks/useWindowDemensions';
import HelmetComponent from 'pages/helmet';
import { useAppSelector } from 'store/hooks';
import { ROUTE_PATH } from 'utils/constants';
import mapModifiers, { formatDateHHmmDDMM } from 'utils/functions';
import SEO_DATA from 'utils/seoData';
import { trackingEvent } from 'utils/trackingGA';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const luckyDrawClosed = useAppSelector((state) => state.systems.system?.luckyDrawConfig.closed);
  const systemLoading = useAppSelector((state) => state.systems.systemLoading);
  const system = useAppSelector((state) => state.systems.system);

  const [ended, setEnded] = React.useState(false);
  // const [currentSlide, setCurrentSlide] = React.useState(0);
  // const settingBanner = {
  //   dots: false,
  //   centerMode: false,
  //   infinite: true,
  //   slidesToScroll: 1,
  //   speed: 500,
  //   autoplay: true,
  //   autoplaySpeed: 10000,
  //   cssEase: 'ease-in-out',
  //   initialSlide: 0,
  //   swipeToSlide: true,
  //   variableWidth: true,
  //   focusOnSelect: true,
  //   arrows: false
  // };

  return (
    <>
      <HelmetComponent seoData={SEO_DATA.HOME} />
      <MainLayout noBg>
        {systemLoading && <LoadingFullScreen />}
        <div className="p-home">
          <Header isProfile isSupport isLogoPresent />
          <div className="p-home_carousel">
            {/* <Carousel settings={{
              ...settingBanner,
              beforeChange(_, nextSlide) {
                setCurrentSlide(nextSlide);
              },
            }}
            >
              <div style={{ width: `${width}px`, height: `${height}px` }}>
                <Image imgSrc={bgPhase2} alt="24h truy tìm fan đích thực" ratio={750 / 1532} />
              </div>
              <div style={{ width: `${width}px`, height: `${height}px` }}>
                <Image imgSrc={bgPhase1} alt="Nâng Heineken vì fan đích thực" ratio={750 / 1532} />
              </div>
            </Carousel> */}
            <div style={{ width: `${width}px`, height: `${height}px` }}>
              <Image imgSrc={luckyDrawClosed ? bgPhase1 : bgPhase2} alt="24h truy tìm fan đích thực" ratio={width / height} />
            </div>
          </div>
          {!luckyDrawClosed && (
            <div className={mapModifiers('p-home_register', 'show')}>
              <ButtonCustom
                onClick={() => {
                  trackingEvent({
                    action: 'click',
                    category: 'home',
                    label: 'ken-league-game-page'
                  });
                  navigate(ROUTE_PATH.GAMESHOW);
                }}
              >
                Tham gia ngay
              </ButtonCustom>
            </div>
          )}
          <div className="p-home_games">
            <Link
              href={system?.eventEnded ? undefined : ROUTE_PATH.CHALLENGE}
              onClick={() => {
                if (system?.eventEnded) {
                  setEnded(true);
                }
                trackingEvent({
                  action: 'click',
                  category: 'home',
                  label: 'btn-game'
                });
              }}
            >
              <div className="p-home_game first animate animate-slideInUp">
                <div className="p-home_game_content">
                  <div className="p-home_game_text">
                    <Typography.Text extendClasses="fs-16x30" fontweight="400" textStyle="uppercase">
                      Trổ tài Fan
                    </Typography.Text>
                    <Typography.Text extendClasses="fs-16x30" fontweight="400" textStyle="uppercase">
                      đích thực
                    </Typography.Text>
                  </div>
                  <div className="p-home_game_icon">
                    <Icon iconName="arrowRight" size="24" />
                  </div>
                </div>
              </div>
            </Link>
            {/* <Link
              href={`${ROUTE_PATH.GIFT}?lucky=true`}
              onClick={() => {
                trackingEvent({
                  action: 'click',
                  category: 'home',
                  label: 'ken-league-gift'
                });
              }}
            >
              <div className="p-home_game second animate animate-fadeInRight">
                <div className="p-home_game_content">
                  <div className="p-home_game_text">
                    <Typography.Text extendClasses="fs-16x30" fontweight="400" textStyle="uppercase">
                      Quà đỉnh ken league
                    </Typography.Text>
                  </div>
                  <div className="p-home_game_icon">
                    <Icon iconName="arrowRight" size="24" />
                  </div>
                </div>
              </div>
            </Link> */}
            <Link
              href={ROUTE_PATH.GIFT}
              onClick={() => {
                trackingEvent({
                  action: 'click',
                  category: 'home',
                  label: 'btn-gift'
                });
              }}
            >
              <div className="p-home_game third animate animate-fadeInRight">
                <div className="p-home_game_content">
                  <div className="p-home_game_text">
                    <Typography.Text extendClasses="fs-16x30" fontweight="400" textStyle="uppercase">
                      Quà đỉnh trao Fan
                    </Typography.Text>
                  </div>
                  <div className="p-home_game_icon">
                    <Icon iconName="arrowRight" size="24" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="p-home_footer">
            <Footer hasBackground />
          </div>
        </div>
        {/* <ModalNotify
          isOpen={openModal}
          handleClose={() => setOpenModal(false)}
        >
          <Typography.Text
            textStyle="center"
            extendClasses="color-white fs-20x26 t-modalNotify_message"
            fontweight="900"
          >
            Bạn đã hết lượt quay
          </Typography.Text>
        </ModalNotify> */}
        <ModalNotify isOpen={ended} handleClose={() => setEnded(false)}>
          <Typography.Text
            textStyle="center"
            fontweight="900"
            extendClasses="color-white t-qrDesktop_modal_text"
          >
            CHƯƠNG TRÌNH KHUYẾN MÃI
            <br />
            QUÉT MÃ QR ĐÃ KẾT THÚC
            <br />
            VÀO
            {' '}
            {formatDateHHmmDDMM(system?.eventEndAt || '')}
          </Typography.Text>
        </ModalNotify>
      </MainLayout>
    </>
  );
};

export default Home;
