import { useQuery } from 'react-query';

import { getDistrictsService, getProvincesByVietNameseService } from 'services/location';
import { IDistrict, IProvince } from 'services/location/types';

const useAddressOptions = (provinceCode?: string) => {
  const { data: provinces, isLoading: isLoadingProvinces } = useQuery<IProvince[]>(['getProvinces'], getProvincesByVietNameseService);
  const { data: districts, isLoading: isLoadingDistricts } = useQuery<IDistrict[]>(['getDistricts', provinceCode], () => (provinceCode ? getDistrictsService(provinceCode) : Promise.resolve([])));
  return {
    provinces: provinces?.map((province) => (
      { label: province.name, value: province.code })) as OptionType[],
    districts: districts?.map((district) => (
      { label: district.name, value: district.code })) as OptionType[],
    isLoadingProvinces,
    isLoadingDistricts,
  };
};

export default useAddressOptions;
