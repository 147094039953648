import React from 'react';
import { Outlet } from 'react-router-dom';

import BottomNavigate from 'components/organisms/BottomNavigate';
import MainLayout from 'components/organisms/MainLayout';

const NavigateLayout: React.FC = () => (
  <MainLayout>
    <div className="t-navigateLayout_content">
      <Outlet />
    </div>
    <div className="t-navigateLayout_bottom">
      <BottomNavigate />
    </div>
  </MainLayout>
);

export default NavigateLayout;
