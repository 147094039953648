// eslint-disable-next-line import/no-extraneous-dependencies
import JsQR from 'jsqr';
import React, { useEffect, useRef } from 'react';

interface CameraProps {
  isStopCamera?: boolean;
  handleScanResult: (result: string) => void;
}

const Camera: React.FC<CameraProps> = ({
  isStopCamera,
  handleScanResult
}) => {
  const cameraRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mediaStreamDataRef = React.useRef<MediaStream | null>(null);

  const stopCamera = () => {
    mediaStreamDataRef.current?.getTracks().forEach((t) => t.stop());
    if (cameraRef.current && cameraRef.current.srcObject) {
      (cameraRef.current.srcObject as any)?.getTracks()?.forEach((track: any) => {
        track.stop();
      });
      cameraRef.current.load();
    }
  };

  useEffect(() => {
    const startCamera = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: 'environment',
            width: 1280,
            height: 720,
          },
        });

        mediaStreamDataRef.current = mediaStream;

        if (!cameraRef.current) {
          return;
        }

        cameraRef.current.srcObject = mediaStream;
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    };

    startCamera();

    return () => {
      stopCamera();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isStopCamera) stopCamera();
  }, [isStopCamera]);

  useEffect(() => {
    const tick = () => {
      if (canvasRef?.current && cameraRef?.current) {
        const video = cameraRef.current;
        const canvasEl = canvasRef.current;
        const ctx = canvasEl.getContext('2d');
        if (ctx && video) {
          ctx.drawImage(video, 0, 0, canvasEl.width, canvasEl.height);
          const imageData = ctx.getImageData(0, 0, canvasEl.width, canvasEl.height);
          if (imageData) {
            const result = JsQR(imageData.data, imageData.width, imageData.height, {
              inversionAttempts: 'dontInvert',
            });
            if (result) {
              handleScanResult(result?.data);
            }
          }
        }
      }
      requestAnimationFrame(tick);
    };

    requestAnimationFrame(tick);
  }, []);

  return (
    <div className="o-camera">
      {/* <div className="o-camera_wrapper">
        <div className="o-camera_line" />
        <div className="o-camera_line" />
        <div className="o-camera_line" />
        <div className="o-camera_line" />
        <div className="o-camera_line" />
        <div className="o-camera_line" />
        <div className="o-camera_line" />
      </div> */}
      <video
        id="camera"
        ref={cameraRef}
        className="camera"
        controls={false}
        disableRemotePlayback
        playsInline
        autoPlay
        muted
        width={708}
      />
      <canvas ref={canvasRef} width="398" height="398" />
    </div>
  );
};

export default Camera;
