import React from 'react';

import useWindowDimensions from 'hooks/useWindowDemensions';
import mapModifiers from 'utils/functions';

interface MainLayoutProps {
  noBg?: boolean;
  children?: React.ReactNode;
  bgColor?: 'darkGreen';
}

const MainLayout: React.FC<MainLayoutProps> = ({
  noBg, children, bgColor
}) => {
  const { height } = useWindowDimensions();
  return (
    <main className={mapModifiers('o-mainLayout', noBg && 'noBg', bgColor)} style={{ height: `${height}px`, maxHeight: `${height}px` }}>
      <div className="o-mainLayout_content">{children}</div>
    </main>
  );
};

export default MainLayout;
