import { useEffect, useState } from 'react';

import { ROUTE_PATH } from 'utils/constants';

interface InnertWindow {
  width: number;
  height: number;
}

const getWindowDimensions = (): InnertWindow => {
  const { innerWidth, innerHeight } = window;

  return {
    width: innerWidth,
    height: innerHeight,
  };
};

const useWindowDimensions = (): InnertWindow => {
  // const WIDTH_CONST = 375;
  // const HEIGHT_CONST = 555;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = (): void => {
      setTimeout(() => setWindowDimensions(getWindowDimensions()), 0);
    };
    window.addEventListener('resize', handleResize);
    if (window.visualViewport && (![ROUTE_PATH.LOGIN,
    ROUTE_PATH.UPDATE_USER_INFO, ROUTE_PATH.REGISTER].includes(window.location.pathname)
      && !window.location.pathname.includes(ROUTE_PATH.GOT_GIFT))) {
      window.visualViewport.addEventListener('resize', handleResize);
    }

    return (): void => {
      window.removeEventListener('resize', handleResize);
      if (window.visualViewport && (![ROUTE_PATH.LOGIN,
      ROUTE_PATH.UPDATE_USER_INFO, ROUTE_PATH.REGISTER].includes(window.location.pathname)
        && !window.location.pathname.includes(ROUTE_PATH.GOT_GIFT))) {
        window.visualViewport.addEventListener('resize', handleResize);
      }
    };
  }, []);

  // useEffect(() => {
  //   let count = 0;
  //   const interval = setInterval(() => {
  //     if (count > 100) {
  //       clearInterval(interval);
  //     }
  //     count += 1;
  //     // console.log('before', windowDimensions.height);

  //     if (windowDimensions.height > windowDimensions.width
  //       && windowDimensions.height < HEIGHT_CONST
  //       && windowDimensions.width > WIDTH_CONST) {
  //       setWindowDimensions(getWindowDimensions());
  //       // console.log('after', windowDimensions.height);
  //     } else {
  //       clearInterval(interval);
  //     }
  //   }, 300);
  //   return () => clearInterval(interval);
  // }, [windowDimensions]);

  return windowDimensions;
};

export default useWindowDimensions;
