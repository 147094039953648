import {
  OpenGiftData, PlayResultData, PlayResultParams, QrCodeCheckedResult, QrSource
} from './types';

import { AssignAccountData } from 'services/auth/types';
import axiosInstance from 'services/common/instance';

export const checkSoccerQrCodeService = async (
  qrCode: string,
  qrSource?: QrSource,
  startGame?: boolean
): Promise<QrCodeCheckedResult> => {
  const res = await axiosInstance.post('soccers/check', { qrCode, qrSource, startGame });
  return res.data.data;
};

export const savePlaySoccerResultService = async (
  data: PlayResultParams
): Promise<PlayResultData> => {
  const res = await axiosInstance.post('soccers/play-result', data);
  return res.data.data;
};

export const soccerAssignAccountService = async (qrCode: string): Promise<AssignAccountData> => {
  const res = await axiosInstance.post('soccers/assign-account', { qrCode });
  return res.data.data;
};

export const openGiftService = async (qrCode: string): Promise<OpenGiftData> => {
  const res = await axiosInstance.post('soccers/open-gift', { qrCode });
  return res.data.data;
};

export const forceWinService = async (qrCode: string): Promise<OpenGiftData> => {
  const res = await axiosInstance.post('soccers/play-result-other', { qrCode });
  return res.data.data;
};

export const checkForceWinService = async (qrCode: string): Promise<OpenGiftData> => {
  const res = await axiosInstance.post('soccers/check-shoot', { qrCode });
  return res.data.data;
};
