import { RedeemGiftParams, RewardData } from './types';

import axiosInstance from 'services/common/instance';

export const getListRewardService = async (): Promise<RewardData[]> => {
  const res = await axiosInstance.get('accounts/auth/rewards');
  return res.data.data;
};

export const getDetailRewardService = async (id: string): Promise<RewardData> => {
  const res = await axiosInstance.get(`accounts/auth/rewards/${id}`);
  return res.data.data;
};

export const redeemGiftService = async (data: RedeemGiftParams): Promise<void> => {
  await axiosInstance.post('accounts/auth/redeem', data);
};
