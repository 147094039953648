import React, { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading, Typography } from 'tfc-components';

import ButtonCustom from 'components/atoms/Button';
import MainLayout from 'components/organisms/MainLayout';
import ModalGiftWon from 'components/templates/ModalGiftWon';
import ModalNotify from 'components/templates/ModalNotify';
import ModalSystemError from 'components/templates/ModalSystemError';
import Home from 'pages/Home';
import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import { checkSoccerQrCodeService, openGiftService } from 'services/soccers';
import { QrSource } from 'services/soccers/types';
import { trackingURLService } from 'services/systems';
import { useAppSelector } from 'store/hooks';
import { COLORS, LOCAL_STORAGE, ROUTE_PATH } from 'utils/constants';
import { getSearchParams } from 'utils/functions';
import { trackingEvent } from 'utils/trackingGA';

const ValidateQrCode: React.FC = () => {
  /* Hooks */
  const navigate = useNavigate();
  const { profile, isStartApp } = useAppSelector((state) => state.auth);
  const system = useAppSelector((state) => state.systems.system);

  /* States */
  const [loading, setLoading] = React.useState(true);
  const [invaid, setInvalid] = React.useState(0);
  const [valid, setValid] = React.useState(false);
  const [errSystem, setErrSystem] = React.useState(false);

  /* Search Params */
  const [searchParams] = useSearchParams();
  const code = searchParams.get('qr');
  const manual = searchParams.get('qrSource');

  /* Mutations */
  const { mutate: trackingMutate } = useMutation('tracking-url', trackingURLService);

  const { mutate: openGift, isLoading: openGiftLoading } = useMutation('openGift', openGiftService, {
    onSuccess: (data) => {
      if (data.valid) {
        navigate(`${ROUTE_PATH.GOT_GIFT}/${data.rewardId ? data.rewardId : 'none'}`);
      } else {
        setInvalid(1);
      }
      localStorage.removeItem(LOCAL_STORAGE.QRCODE);
    },
    onError: () => {
      setErrSystem(true);
    }
  });

  const { mutate: validateMutate } = useMutation('validateQrCode', (
    data: { code: string, qrSource?: QrSource, isLogin: boolean, hasProfile: boolean }
  ) => checkSoccerQrCodeService(data.code, data.qrSource, true), {
    onSettled: () => {
      setLoading(false);
    },
    onSuccess: (data, variables) => {
      setLocalStorage(LOCAL_STORAGE.QRCODE, variables.code);
      if (data.valid) {
        if (data.hasAccount) {
          if (variables.hasProfile) {
            openGift(variables.code);
          } else if (variables.isLogin) {
            navigate(ROUTE_PATH.MY_GIFT);
          } else {
            navigate(ROUTE_PATH.LOGIN);
          }
        } else if (data.played) {
          if (data.won) {
            setValid(true);
          } else {
            setInvalid(1);
          }
        } else if (variables.isLogin) {
          navigate(`${ROUTE_PATH.GAME}?code=${variables.code}`);
        } else {
          navigate(`${ROUTE_PATH.LOGIN}?code=${variables.code}`);
        }
      } else if (data.played) setInvalid(2);
      else setInvalid(1);
    },
    onError: () => {
      setErrSystem(true);
    }
  });
  const handleValidateCallback = useCallback(() => {
    const regex = new RegExp(process.env.REACT_APP_QRCODE_REGEX as string);

    if (code && !isStartApp) {
      if (regex.test(code)) {
        validateMutate({
          code, qrSource: manual ? 'manual' : 'scan', isLogin: !!profile, hasProfile: !!profile && !!profile.profile
        });
        const searchPrs = getSearchParams(searchParams.entries());
        if (Object.keys(searchPrs).length > 0) {
          const {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            qr, utm_source, utm_medium, utm_campaign, ...others
          } = searchPrs;
          trackingMutate({
            qrCode: qr || null,
            utmSource: utm_source || null,
            utmMedium: utm_medium || null,
            utmCampaign: utm_campaign || null,
            others: {
              ...others,
              rejectedAgeCheck: String(getLocalStorage(LOCAL_STORAGE.AGE_GATE) === 'false')
            }
          });
          setLocalStorage(LOCAL_STORAGE.TRACKING_URL, JSON.stringify({
            qrCode: qr || null,
            utmSource: utm_source || null,
            utmMedium: utm_medium || null,
            utmCampaign: utm_campaign || null,
            others
          }));
        }
      } else {
        navigate(`${ROUTE_PATH.HOME}?page=notfound`);
      }
    }
  }, [profile, isStartApp]);
  /* Effects */
  useEffect(() => {
    if (!system?.eventEnded) {
      handleValidateCallback();
    }
  }, [handleValidateCallback, system]);

  if (!code) return <Home />;

  return (
    <MainLayout>
      <div className="p-validateQrCode">
        {loading || openGiftLoading ? (
          <Loading.CircleDashed
            color={COLORS.white}
            width={48}
          />
        ) : null}
        <ModalGiftWon
          hasLogin={!!profile}
          isOpen={valid}
          handleLogin={() => navigate(ROUTE_PATH.LOGIN)}
          handleClose={() => navigate(ROUTE_PATH.HOME)}
        />
        <ModalNotify
          isOpen={invaid > 0}
          handleClose={() => {
            trackingEvent({
              action: 'click',
              category: 'game',
              label: 'game1-skip',
            });
            navigate(ROUTE_PATH.HOME);
          }}
        >
          <Typography.Text
            textStyle="center"
            extendClasses="color-white fs-20x26 t-modalNotify_message"
            fontweight="900"
          >
            {invaid === 1 ? (
              <>
                MÃ QR NÀY KHÔNG HỢP LỆ.
                <br />
                VUI LÒNG KIỂM TRA LẠI NHÉ
              </>
            ) : (
              <>
                MÃ QR NÀY ĐÃ ĐƯỢC QUÉT BỞI
                <br />
                SỐ ĐIỆN THOẠI KHÁC.
                <br />
                VUI LÒNG KIỂM TRA LẠI NHÉ
              </>
            )}
          </Typography.Text>
          <div className="p-validateQrCode_button">
            <ButtonCustom onClick={() => {
              trackingEvent({
                action: 'click',
                category: 'game',
                label: 'game1-input-valid-code',
              });
              navigate(ROUTE_PATH.CHALLENGE);
            }}
            >
              Quét mã khác
            </ButtonCustom>
          </div>
        </ModalNotify>
        <ModalSystemError
          isOpen={errSystem}
          handleClose={() => {
            setErrSystem(false);
            window.location.reload();
          }}
        />
      </div>
    </MainLayout>
  );
};

export default ValidateQrCode;
