import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getPageStaticAllService } from 'services/pages';
import { StaticAllData } from 'services/pages/types';
import { getGeneralService } from 'services/systems';
import { GeneralDataTypes } from 'services/systems/types';

interface SystemState {
  staticAll?: StaticAllData[];
  system?: GeneralDataTypes;
  systemLoading: boolean;
  contactModalOpen: boolean;
}

const initialState: SystemState = {
  staticAll: undefined,
  system: undefined,
  contactModalOpen: false,
  systemLoading: false,
};

export const getStaticAllAction = createAsyncThunk<StaticAllData[]>(
  'systemReducer/getStaticAll',
  async (_, { rejectWithValue }) => {
    try {
      return await getPageStaticAllService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSystemAction = createAsyncThunk<GeneralDataTypes>(
  'systemReducer/getSystem',
  async (_, { rejectWithValue }) => {
    try {
      return await getGeneralService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {
    toggleContactModal: ($state) => {
      $state.contactModalOpen = !$state.contactModalOpen;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSystemAction.pending, ($state) => {
      $state.systemLoading = true;
    });
    builder.addCase(getSystemAction.fulfilled, ($state, action) => {
      $state.system = action.payload;
      $state.systemLoading = false;
    });
    builder.addCase(getSystemAction.rejected, ($state) => {
      $state.systemLoading = false;
    });
    builder.addCase(getStaticAllAction.fulfilled, ($state, action) => {
      $state.staticAll = action.payload;
    });
  },
});

export const { toggleContactModal } = systemSlice.actions;

export default systemSlice.reducer;
