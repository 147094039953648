/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, {
  useState, useRef, useEffect, useCallback,
  useMemo
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography } from 'tfc-components';

import goodLuck from 'assets/images/good-luck.png';
import btnSpin from 'assets/images/spin-btn.png';
import loseSound from 'assets/sounds/lose.mp3';
import tickSound from 'assets/sounds/tick.mp3';
import winSound from 'assets/sounds/win.mp3';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import Header from 'components/organisms/Header';
import ModalNotify from 'components/templates/ModalNotify';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { getLocalStorage } from 'services/common/storage';
import { checkDrawService, drawService, getGiftsForLuckyDraw } from 'services/luckyDraw';
import { getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LOCAL_STORAGE, ROUTE_PATH } from 'utils/constants';
import { draw } from 'utils/luckyWheel';
import { trackingEvent } from 'utils/trackingGA';

export type GiftItems = {
  id: number,
  code?: string,
  name: string | null,
  imgSrc: string,
};

interface LuckyWheelProps {
  diameter: number;
  data?: GiftItems[]; // data
  images: Array<CanvasImageSource>; // icons
  spinTo: number;
  value: number;
  offset?: number;
}

const duration = 5000;

const LuckyWheel: React.FC<LuckyWheelProps> = ({
  diameter,
  data,
  images,
  spinTo,
  value,
  offset = 0
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let cleanedUp = false;
    const ctx = canvasRef?.current?.getContext('2d');
    const render = () => {
      if (cleanedUp) {
        return;
      }
      const t = spinTo - Date.now();
      if (ctx) {
        draw(ctx, diameter, (data || []), images, value + offset, t);
        requestAnimationFrame(render);
      }
    };

    requestAnimationFrame(render);

    return () => {
      cleanedUp = true;
    };
  }, [diameter, data, spinTo, value, offset, images]);

  return (
    <canvas
      ref={canvasRef}
      width={diameter * 2}
      height={diameter * 2}
      style={{ width: `${diameter}px`, height: `${diameter}px` }}
    />
  );
};

const LuckyWheelPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { profile, isStartApp } = useAppSelector((state) => state.auth);
  const luckyDrawClosed = useAppSelector((state) => state.systems.system?.luckyDrawConfig.closed);
  const systemLoading = useAppSelector((state) => state.systems.systemLoading);
  const { width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const drawing = searchParams.get('drawing');
  const winSfx = useRef<HTMLAudioElement>(null);
  const loseSfx = useRef<HTMLAudioElement>(null);
  const tickSfx = useRef<HTMLAudioElement>(null);
  const [t, setT] = useState(0);
  const [v, setV] = useState(0);
  const [spining, setSpining] = useState(false);
  const [modal, setModal] = useState<{
    isOpen: boolean,
    title: string,
    isGoodLuck: boolean,
  }>({
    isOpen: false,
    title: '',
    isGoodLuck: false
  });
  const [openModal, setOpenModal] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const rewardId = useRef<number | null>(null);

  const {
    data: giftsRes,
  } = useQuery(
    'getGiftsForLuckyDraw',
    () => getGiftsForLuckyDraw(),
    {
      onSettled: () => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  );

  const { mutate: checkDrawed } = useMutation('checkForLucky', checkDrawService, {
    onSuccess: (data) => {
      if (data.played) {
        if (data.rewardId === null) {
          setModal({
            isOpen: true,
            title: 'KHÔNG SAO NHA<br />THUA KEO NÀY<br />TA BÀY KEO KHÁC',
            isGoodLuck: true,
          });
        } else {
          dispatch(getProfileAction());
          navigate(`${ROUTE_PATH.GOT_GIFT}/${data.rewardId}`);
        }
      }
    },
    onError: () => {
      setOpenModal(true);
    },
  });

  const giftsData = useMemo(() => {
    if (!giftsRes) {
      return [];
    }
    return giftsRes.gifts.map((item, idx) => ({
      id: item?.giftData?.id || idx,
      code: item?.giftData?.code,
      name: item?.giftData?.name || '',
      imgSrc: item.giftData?.thumbnail || ''
    }));
  }, [giftsRes]);

  const images = useMemo(() => giftsData.map((item) => {
    const icon = new Image();
    icon.src = item.imgSrc || goodLuck;
    icon.width = 60;
    icon.height = 60;
    return icon;
  }), [giftsData]);

  const spin = useCallback(async () => {
    let pos: number;
    let closed = false;
    setSpining(true);
    trackingEvent({
      action: 'click',
      category: 'ken-game',
      label: 'play-lucky-draw'
    });
    try {
      const trackingStorage = getLocalStorage(LOCAL_STORAGE.TRACKING_URL);
      const parser = JSON.parse(trackingStorage || '{}');
      setSearchParams({ drawing: 'true' }, { replace: true });
      const dataDraw = await drawService({ utmSource: parser.utm_source || null, utmMedium: parser.utm_medium || null, utmCampaign: parser.utm_campaign || null });
      const result = giftsData.findIndex((item) => item.code === dataDraw.giftCode);
      rewardId.current = dataDraw.rewardId;
      pos = result;
      setV(result);
    } catch (error: any) {
      if (error.length > 0 && error.find((item: any) => item.code === 'missingProfile')) {
        navigate(`${ROUTE_PATH.REGISTER}?missingProfile=true`);
      }
      if (error.length > 0 && error.find((item: any) => item.code === 'closed')) {
        closed = true;
      }
      const result = giftsData.map((item, idx) => ({ code: item.code, idx })).filter((item) => !item.code)[Math.floor(Math.random() * 4)];
      pos = result.idx;
      rewardId.current = null;
      setV(result.idx);
    }
    const time = Date.now() + duration;

    setT(time);
    setV(pos);

    const ticks = 0.5 * 0.25 * (duration / 1000) ** 2 * giftsData.length;
    const interval = Array.from({ length: Math.floor(ticks) }).map((_, i) => Math.sqrt((i + 0.5) / ticks) * duration);
    interval.reverse();

    let lastPlay = Infinity;
    const timer = setInterval(() => {
      const t2 = time - Date.now();
      let i;

      while (interval.length && interval[0] > t2) {
        i = interval.shift();
      }

      if (i === undefined) {
        return;
      }

      if (lastPlay - i > 30) {
        lastPlay = i;
        if (tickSfx.current) {
          tickSfx.current.currentTime = 0;
          tickSfx.current.play();
        }
      }
    }, 15);

    const goToReward = () => {
      if (pos === -1) {
        if (loseSfx.current) {
          loseSfx.current.play();
        }
        setModal({
          isOpen: true,
          title: closed ? 'CHƯƠNG TRÌNH VÒNG QUAY MAY MẮN<br />ĐÃ KẾT THÚC' : 'KHÔNG SAO NHA<br />THUA KEO NÀY<br />TA BÀY KEO KHÁC',
          isGoodLuck: !closed,
        });
        return;
      }
      if (giftsData[pos].name) {
        if (winSfx.current) {
          winSfx.current.play();
        }
        setTimeout(() => {
          dispatch(getProfileAction());
          setSearchParams({}, { replace: true });
          navigate(`${ROUTE_PATH.GOT_GIFT}/${rewardId.current}`);
        }, 2000);
      } else {
        if (loseSfx.current) {
          loseSfx.current.play();
        }
        setModal({
          isOpen: true,
          title: closed ? 'CHƯƠNG TRÌNH<br />VÒNG QUAY MAY MẮN<br />ĐÃ KẾT THÚC' : 'KHÔNG SAO NHA<br />THUA KEO NÀY<br />TA BÀY KEO KHÁC',
          isGoodLuck: !closed,
        });
      }
    };

    setTimeout(() => {
      clearInterval(timer);
      setSpining(false);
      goToReward();
    }, duration);
  }, [dispatch, giftsData, navigate, setSearchParams]);

  useEffect(() => {
    if (profile && profile.account.luckyDrawCount > 0) {
      setOpenModal(true);
    } else if (!profile && !isStartApp) {
      navigate(ROUTE_PATH.REGISTER);
    } else if (!profile?.profile && !isStartApp) {
      navigate(`${ROUTE_PATH.REGISTER}?missingProfile=true`);
    }
  }, [isStartApp, navigate, profile]);

  useEffect(() => {
    if (luckyDrawClosed) {
      navigate(ROUTE_PATH.HOME + window.location.search);
    }
  }, [luckyDrawClosed, navigate]);

  useEffect(() => {
    if (drawing) {
      checkDrawed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-luckyWheel">
      <Header isBack />
      {(loading || systemLoading) && <LoadingFullScreen />}
      <audio
        id="winSound"
        ref={winSfx}
        src={winSound}
      />
      <audio id="loseSound" ref={loseSfx} src={loseSound} />
      <audio id="tickSound" ref={tickSfx} src={tickSound} />
      <div className="u-mt-16">
        <div className="p-luckyWheel_title">
          <Typography.Text
            extendClasses="color-white"
            fontweight="900"
          >
            VÒNG XOAY MAY MẮN
          </Typography.Text>
        </div>
        <Typography.Text
          textStyle="center"
          extendClasses="fs-16x20 p-luckyWheel_subTitle"
        >
          Mời fan đích thực ghi bàn trúng thưởng
        </Typography.Text>
      </div>
      <div className="p-luckyWheel_wrapper">
        <LuckyWheel
          diameter={width - (width * 0.0737)}
          data={giftsData}
          images={images}
          spinTo={t}
          value={v}
        />
        <button
          type="button"
          className="p-luckyWheel_btn"
          onClick={spin}
          disabled={spining}
        >
          <img src={btnSpin} alt="spin-btn" />
        </button>
      </div>
      <ModalNotify
        isOpen={modal.isOpen}
        handleClose={() => {
          if (!modal.isGoodLuck) {
            window.location.reload();
          } else {
            dispatch(getProfileAction());
            navigate(ROUTE_PATH.HOME);
            setModal({ isOpen: false, title: '', isGoodLuck: false });
          }
        }}
      >
        <Typography.Text
          textStyle="center"
          extendClasses="color-white fs-20x26 p-luckyWheel_modalTitle"
          fontweight="900"
        >
          <span dangerouslySetInnerHTML={{ __html: modal.title }} />
        </Typography.Text>
        {modal.isGoodLuck && (
          <div className="u-mt-16 p-luckyWheel_modalContent">
            <Typography.Text
              textStyle="center"
              fontweight="400"
              extendClasses="color-white fs-16x20 p-luckyWheel_modalText"
            >
              Tuy chưa trúng thưởng lần này nhưng
              <br />
              Heineken luôn ghi nhận nhiệt huyết từ
              <br />
              fan đích thực nè
            </Typography.Text>
            <div className="u-mt-16">
              <Typography.Text
                fontweight="400"
                textStyle="center"
                extendClasses="color-white fs-16x20 p-luckyWheel_modalText"
              >
                Cùng đón xem Ken League ngày
                <br />
                <span>24/05/2024</span>
                {' '}
                và theo dõi các hoạt động
                <br />
                sắp tới từ Heineken nha
              </Typography.Text>
            </div>
          </div>
        )}
      </ModalNotify>
      <ModalNotify
        isOpen={openModal}
        handleClose={() => {
          setOpenModal(false);
          navigate(ROUTE_PATH.HOME);
        }}
      >
        <Typography.Text
          textStyle="center"
          extendClasses="color-white fs-20x26 t-modalNotify_message"
          fontweight="900"
        >
          Bạn đã hết lượt quay
        </Typography.Text>
      </ModalNotify>
    </div>
  );
};

export default LuckyWheelPage;
