import React, { forwardRef } from 'react';
import ReactSelect from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';

import Icon from 'components/atoms/Icon';
import { COLORS } from 'utils/constants';
import mapModifiers from 'utils/functions';

interface PulldownProps {
  name: string;
  options: OptionType[];
  placeholder?: string;
  error?: boolean;
  value?: OptionType;
  multiple?: boolean;
  loading?: boolean;
  isDisabled?: boolean;
  required?: boolean;
  isBottom?: boolean;
  onScrollLastest?: () => void;
  handleChange?: (option: OptionType) => void;
  onMenuOpen?: () => void;
}

const PulldownRef: React.ForwardRefRenderFunction<Select, PulldownProps> = (
  {
    name,
    options,
    placeholder,
    error,
    multiple,
    loading,
    isDisabled,
    isBottom,
    value,
    required,
    onScrollLastest,
    handleChange,
    onMenuOpen
  },
  ref,
) => (
  <ReactSelect
    isDisabled={isDisabled}
    ref={ref}
    name={name}
    className={mapModifiers('m-pulldown', error && 'error', isDisabled && 'disabled')}
    classNamePrefix="m-pulldown"
    options={options}
    blurInputOnSelect
    isSearchable={false}
    menuPlacement={isBottom ? 'top' : 'auto'}
    placeholder={required ? (
      <>
        <span>*</span>
        {placeholder}
      </>
    ) : placeholder}
    isMulti={multiple}
    value={value}
    onChange={(v: any) => handleChange && handleChange(v)}
    onMenuOpen={onMenuOpen}
    theme={(theme) => ({
      ...theme,
      borderRadius: 4,
      colors: {
        ...theme.colors,
        primary: COLORS.deepGreen,
      },
      spacing: {
        ...theme.spacing,
        controlHeight: 48,
      },
    })}
    isLoading={loading}
    styles={{
      control: (base: any) => ({
        ...base,
        border: 'none',
        background: 'transparent',
      }),
    }}
    components={{
      // eslint-disable-next-line react/no-unstable-nested-components
      DropdownIndicator: () => (<Icon iconName="arrowDown" size="24" />),
    }}
    onMenuScrollToBottom={onScrollLastest}
  />
);

const Pulldown = forwardRef(PulldownRef);

export default Pulldown;
