import React from 'react';
import ReactSlick, { Settings } from 'react-slick';

export interface CarouselProps {
  settings?: Settings;
  asNavFor?: ReactSlick;
  ref?: React.RefObject<ReactSlick>;
  children: React.ReactNode;
  centerMode?: boolean;
}

const Carousel = React.forwardRef<ReactSlick, CarouselProps>(
  ({
    settings, children, asNavFor,
  }, ref) => (
    <div className="o-carousel">
      <ReactSlick
        centerPadding="0"
        {...settings}
        {...(asNavFor && { asNavFor })}
        ref={ref}
      >
        {React.Children.map(children, (item) => (
          <div className="o-carousel_item">{item}</div>
        ))}
      </ReactSlick>
    </div>
  ),
);

Carousel.defaultProps = {
  settings: {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    cssEase: 'ease-in-out',
    initialSlide: 0,
    swipeToSlide: true,
  },
};

export default Carousel;
